<template>
  <div class="navbar-container d-flex content align-items-center">

    <div class="isViewerBlocked" v-if="isViewer && this.viewersBlocked">
      Ծառայությունը դադարեցված է ՏԿԵ նախարարության հետ ծառայությունների մատուցման պայմանագրի բացակայության հիմքով։
    </div>

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
            class="nav-link"
            @click="toggleVerticalMenuActive"
        >
          <feather-icon
              icon="MenuIcon"
              size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper d-flex header-tools align-items-center flex-grow-1 d-lg-flex">
      <!--      Server Status Info-->

      <div v-if="!$device.isMobile" :class="serverStatusColor"></div>

      <template v-if="isOperator">
        <!-- Mechanic Lists-->

        <div class="d-flex" :class="$device.isMobile ? '' : 'ml-1'">
          <date-range-picker
              v-if="user.showCalendar"
              ref="picker"
              :opens="'center'"
              v-model="dateRange"
              :locale-data="localeData"
              :ranges="dateRanges"
              :always-show-calendars="false"
              @update="updateDateRange"
          >
            <template v-slot:input="picker" style="min-width: 350px;">
              <fa-icon class="header-icon calendarIcon" :icon="['far', 'calendar-alt']"/>
            </template>
          </date-range-picker>


          <div>
            <b-dropdown
                size="sm"
                :text="mechanicName"
                variant="flat-primary"
                id="mechanic_selector"
            >
              <template slot="button-content">
                <fa-icon class="faNavbarIcon" icon="user-cog"/>
              </template>
              <template v-for="mechanic in mechanics">
                <b-dropdown-item
                    :class="(activeMechanic && mechanic.id === activeMechanic.id) ? 'dropdownBold active' : 'dropdownBold'"
                    @click="selectMechanic(mechanic)">
                  {{ mechanic.name }}
                </b-dropdown-item>
              </template>
            </b-dropdown>
          </div>

          <!--Print Menus-->
          <b-dropdown
              size="sm"
              text="Գրանցամատյան"
              variant="flat-primary"
              id="docs_selector"
              v-if="user.showPrint && !$device.isMobile"
          >
            <template slot="button-content">
              <fa-icon class="faNavbarIcon" icon="print"/>
            </template>
            <b-dropdown-item class="dropdownBold" @click="openClassificatorDoc">
              Ձև 1․ Արատորոշման քարտ
            </b-dropdown-item>
            <b-dropdown-item class="dropdownBold" @click="openFuelDoc">
              Ձև 2․ Վառելիքային հաշվետվություն
            </b-dropdown-item>
            <b-dropdown-item class="dropdownBold" @click="openFinancialDoc">
              Ձև 3. Ֆինանսական
            </b-dropdown-item>
            <b-dropdown-item class="dropdownBold" @click="openInfoDoc">
              Ձև 4. Տեղեկատվություն
            </b-dropdown-item>

            <b-dropdown-item class="dropdownBold" @click="openIndividualDoc">
              Ձև 5․ Ֆիզիկական անձիք
            </b-dropdown-item>
            <b-dropdown-item class="dropdownBold" @click="openCompanyDoc">
              Ձև 6․ Իրավաբանական անձիք
            </b-dropdown-item>
            <b-dropdown-item class="dropdownBold" @click="openEntrepreneurDoc">
              Ձև 7․ Անհատ ձեռներեցներ
            </b-dropdown-item>
            <b-dropdown-item class="dropdownBold" @click="openAmountsDoc">
              Ձև 8․ Ընդհանուր կատարողական
            </b-dropdown-item>
            <b-dropdown-item class="dropdownBold" @click="openCountDoc">
              Ձև 9․ Թվաքանակը
            </b-dropdown-item>
            <b-dropdown-item class="dropdownBold" @click="openTaxInvoice">
              Ձև 10. Հարկային հաշվետվություն
            </b-dropdown-item>
            <b-dropdown-item class="dropdownBold" @click="openOperatorsDoc">
              Ձև 11. Օպերատորների հաշվետվություն
            </b-dropdown-item>

          </b-dropdown>

        </div>



        <!-- Search Menu-->
        <fa-icon v-if="company && !company.searchBlocked" @click="openSearchModal" class="faNavbarIcon" :icon="$device.isMobile ? 'search' : 'search-plus'"/>

<!--        <fa-icon @click="openSearchPaymentModal" class="faNavbarIcon" icon="search-dollar"/>-->


      </template>

      <template v-if="isAdmin || isOwner">
        <date-range-picker
            v-if="$route.name === 'tech-service-all' || $route.name === 'company-tech-service' || $route.name === 'tech-service-view' || $route.name === 'control' || $route.name === 'control-company' || $route.name === 'tech-service-company-view'"
            class="ml-1"
            ref="picker"
            :opens="'center'"
            v-model="dateRange"
            :locale-data="localeData"
            :ranges="dateRangesAdmin"
            :always-show-calendars="false"
            @update="updateDateRangeAdmin"
        >
          <template v-slot:input="picker" style="min-width: 350px;">
            <fa-icon class="header-icon calendarIcon" :icon="['far', 'calendar-alt']"/>
          </template>
        </date-range-picker>


        <!--Print Menus-->
        <b-dropdown
            v-if="(isAdmin || isOwner) && ($route.name === 'tech-service-all' || $route.name === 'company-tech-service')"
            size="sm"
            text="Գրանցամատյան"
            variant="flat-primary"
            id="docs_selector"
        >
          <template slot="button-content">
            <fa-icon class="faNavbarIcon" icon="print"/>
          </template>
          <b-dropdown-item class="dropdownBold" @click="openClassificatorDoc">
            Ձև 1․ Արատորոշման քարտ
          </b-dropdown-item>
          <b-dropdown-item class="dropdownBold" @click="openFuelDoc">
            Ձև 2․ Վառելիքային հաշվետվություն
          </b-dropdown-item>
          <b-dropdown-item class="dropdownBold" @click="openFinancialDoc">
            Ձև 3. Ֆինանսական
          </b-dropdown-item>
          <b-dropdown-item class="dropdownBold" @click="openInfoDoc">
            Ձև 4. Տեղեկատվություն
          </b-dropdown-item>
          <b-dropdown-item class="dropdownBold" @click="openIndividualDoc">
            Ձև 5․ Ֆիզիկական անձիք
          </b-dropdown-item>
          <b-dropdown-item class="dropdownBold" @click="openCompanyDoc">
            Ձև 6․ Իրավաբանական անձիք
          </b-dropdown-item>
          <b-dropdown-item class="dropdownBold" @click="openEntrepreneurDoc">
            Ձև 7․ Անհատ ձեռներեցներ
          </b-dropdown-item>
          <b-dropdown-item class="dropdownBold" @click="openAmountsDoc">
            Ձև 8․ Ընդհանուր կատարողական
          </b-dropdown-item>
          <b-dropdown-item class="dropdownBold" @click="openCountDoc">
            Ձև 9․ Թվաքանակը
          </b-dropdown-item>
          <b-dropdown-item class="dropdownBold" @click="openTaxInvoice">
            Ձև 10. Հարկային հաշվետվություն
          </b-dropdown-item>
          <b-dropdown-item class="dropdownBold" @click="openOperatorsDoc">
            Ձև 11. Օպերատորների հաշվետվություն
          </b-dropdown-item>
          <b-dropdown-item class="dropdownBold" @click="openReportViewDoc">
            Ձև 12. Ըստ ամիսների և ըստ կիսամյակի
          </b-dropdown-item>
        </b-dropdown>

        <fa-icon v-if="isAdmin && ['khamazaspyan@gmail.com', 'ruben.ordanyan@gmail.com'].includes(user.email) && checkups.length > 0" @click="downloadAdminExcel" style="font-size: 1.6rem;" class="header-icon calendarIcon" :icon="['fas', 'download']"/>

      </template>

      <template v-if="isAdmin || isViewer">
        <date-range-picker
            v-if="$route.name === 'analysis' || $route.name === 'analysis-view'"
            class="ml-1"
            ref="picker"
            :opens="'center'"
            v-model="dateRangeAnalysis"
            :locale-data="localeData"
            :ranges="dateRangesAdmin"
            :always-show-calendars="false"
        >
          <template v-slot:input="picker" style="min-width: 350px;">
            <fa-icon class="header-icon calendarIcon" :icon="['far', 'calendar-alt']"/>
          </template>
        </date-range-picker>
      </template>

      <template v-if="isViewer">

        <div class="ml-1" v-if="$route.name === 'tech-service-view' || $route.name === 'control-view' || $route.name === 'co-payments'">

          <fa-icon @click="openDateRangeModal" class="header-icon calendarIcon viewerCalendarIcon" :icon="['far', 'calendar-alt']"/>

          <!--Print Menus-->
          <b-dropdown
              v-if="(isViewer) && ($route.name === 'tech-service-view')"
              size="sm"
              text="Գրանցամատյան"
              variant="flat-primary"
              id="docs_selector"
          >
            <template slot="button-content">
              <fa-icon class="faNavbarIcon" icon="print"/>
            </template>

            <b-dropdown-item class="dropdownBold" @click="openFuelDoc">
              Ձև 1․ Վառելիքային հաշվետվություն
            </b-dropdown-item>
            <b-dropdown-item class="dropdownBold" @click="openInfoDoc">
              Ձև 2. Տեղեկատվություն
            </b-dropdown-item>

            <b-dropdown-item class="dropdownBold" @click="openFinancialDoc">
              Ձև 3. Ֆինանսական
            </b-dropdown-item>

            <b-dropdown-item class="dropdownBold" @click="openReportViewDoc">
              Ձև 4. Ըստ ամիսների և ըստ կիսամյակի
            </b-dropdown-item>

          </b-dropdown>

<!--          <fa-icon v-if="checkups.length > 0" @click="downloadExcel" style="font-size: 1.6rem;" class="header-icon viewerCalendarIcon calendarIcon" :icon="['fas', 'download']"/>-->

        </div>

        <fa-icon v-if="hasAccessToTS" @click="openViewSearchModal" class="faNavbarIcon viewSearchIcon" icon="search"/>

<!--        <fa-icon v-if="['grigoryansasun68@gmail.com'].includes(user.email) && checkups.length > 0" @click="downloadAdminExcel" style="font-size: 1.6rem;" class="header-icon calendarIcon" :icon="['fas', 'download']"/>-->
      </template>

      <dark-Toggler class="d-lg-block"/>

<!--      <b-button @click="openFMModal" variant="danger" v-if="company && (company._id === '62331381f4d9fa05f5ccaec2' || company._id === '607724ce795c0b1f500f4ed4')">Մարտի 20-28</b-button>-->

      <fa-icon v-if="hasAccessToTSAdmin" @click="openViewSearchModal" class="faNavbarIcon viewSearchIcon" icon="search"/>

<!--      <div @click="toggleSetting('viewersBlocked')" v-if="isAdmin && ['khamazaspyan@gmail.com', 'ruben.ordanyan@gmail.com']" :class="(viewersBlocked) ? 'block-status yellow-status statusTRUE' : 'block-status yellow-status'"></div>-->
<!--      <div @click="toggleSetting('paymentsEnabled')" v-if="isAdmin && ['khamazaspyan@gmail.com', 'ruben.ordanyan@gmail.com']" :class="(paymentsEnabled) ? 'block-status red-status statusTRUE' : 'block-status red-status'"></div>-->
<!--      <div @click="toggleSetting('mobileRedirect')" v-if="isAdmin && ['khamazaspyan@gmail.com', 'ruben.ordanyan@gmail.com']" :class="(mobileRedirectEnabled) ? 'block-status blue-status statusTRUE' : 'block-status blue-status'">R</div>-->

<!--      <div @click="toggleSyncedCheckups" v-if="isAdmin && ['khamazaspyan@gmail.com', 'ruben.ordanyan@gmail.com']" :class="(syncedEnabled) ? 'block-status orange-status statusTRUE' : 'block-status orange-status'">✔</div>-->


      <div v-if="isOperator && !serverStatus" id="todayCheckupsResult" class="todayCheckupsResult">

        <div id="onlineCount" class="online">
          <fa-icon icon="dot-circle" />
          <span class="count">{{today_count}}</span>
        </div>

        <div id="offlineCount" class="offline">
          <fa-icon icon="dot-circle" />
          <span class="count">{{today_offline_count}}</span>
        </div>

      </div>

    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">

      <template v-if="isOperator">
        <template v-if="company && company.warned">
          <div class="warnings-statuses">
            <div class="warning-status-item" :class="warningStatusColor(24)"></div>
            <div class="warning-status-item" :class="warningStatusColor(48)"></div>
            <div class="warning-status-item" :class="warningStatusColor(72)"></div>
          </div>
        </template>
        <template v-if="company && company.blocked">
          <div class="warnings-statuses">
            <div class="warning-status-item danger"></div>
            <div class="warning-status-item danger"></div>
            <div class="warning-status-item danger"></div>
          </div>
        </template>
      </template>

      <notification-dropdown/>

      <b-nav-item-dropdown
          right
          toggle-class="d-flex align-items-center dropdown-user-link"
          class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name dropdownBold font-weight-bolder mb-0">
              {{ user.name }}
            </p>
          </div>
          <b-avatar
              size="40"
              variant="light-primary"
              badge
              :src="require('@/assets/images/avatars/default.png')"
              class="badge-minimal"
              badge-variant="success"
          />
        </template>

        <b-dropdown-item link-class="d-flex align-items-center">
          <span @click="openPasswordModal">Փոխել գաղտնաբառը</span>
        </b-dropdown-item>

        <b-dropdown-item v-if="isAdmin" link-class="d-flex align-items-center">
          <span @click="openEditHistoryModal">Խմբագրման հայտեր</span>
        </b-dropdown-item>

        <template v-if="isOwner">
          <template v-if="companies.length > 1">
            <b-dropdown-item v-for="company in companies" link-class="d-flex align-items-center">
              <span @click="openCompanyModal(company)">Ռեկվիզիտներ({{company.name}})</span>
            </b-dropdown-item>
          </template>

          <b-dropdown-item v-else link-class="d-flex align-items-center">
            <span @click="openCompanyModal(companies[0])">Ռեկվիզիտներ</span>
          </b-dropdown-item>
        </template>





        <b-dropdown-divider/>

        <b-dropdown-item @click="logOut" link-class="d-flex align-items-center">
          <feather-icon
              size="16"
              icon="LogOutIcon"
              class="mr-50"
          />
          <span>Ելք</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>

    <b-modal
        id="modal-change-password-form"
        :no-close-on-backdrop="true"
        cancel-variant="outline-secondary"
        ok-title="Փոխել գաղտնաբառը"
        cancel-title="Կասեցնել"
        centered
        size="sm"
        title="Փոխել գաղտնաբառը"
        :is-valid="false"
        @ok="changePassword"
        :ok-disabled="validatePasswordForm"
    >
      <b-form>
        <b-form-group>
          <b-form-input
              v-model="passwordForm.oldPassword"
              type="password"
              placeholder="Հին գաղտնաբառ"
          />
        </b-form-group>
        <b-form-group>
          <b-form-input
              v-model="passwordForm.password"
              type="password"
              placeholder="Նոր գաղտնաբառ"
          />
        </b-form-group>
        <b-form-group>
          <b-form-input
              v-model="passwordForm.repeatPassword"
              type="password"
              placeholder="Կրկնել գաղտնաբառը"
          />
        </b-form-group>
      </b-form>
    </b-modal>

    <b-modal
        id="modal-change-company-form"
        :no-close-on-backdrop="true"
        cancel-variant="outline-secondary"
        ok-title="Խմբագրել"
        cancel-title="Կասեցնել"
        centered
        size="sm"
        title="Կազմակերպության ռեկվիզիտներ"
        :is-valid="false"
        @ok="changeCompany"
    >
      <b-form>
        <b-form-group>
          <b-form-input
              disabled
              v-model="companyForm.name"
              type="text"
              placeholder="Կազմակերպության անվանում"
          />
        </b-form-group>

        <b-form-group>
          <b-form-input
              v-model="companyForm.boss"
              type="text"
              placeholder="Տնօրեն"
          />
        </b-form-group>

        <b-form-group>
          <b-form-input
              v-model="companyForm.phone"
              type="text"
              placeholder="Հեռախոսահամար"
          />
        </b-form-group>

        <b-form-group>
          <b-form-input
              v-model="companyForm.bank"
              type="text"
              placeholder="Բանկային տվյալներ"
          />
        </b-form-group>

        <b-form-group>
          <b-form-input
              v-model="companyForm.tax"
              type="text"
              placeholder="ՀՎՀՀ"
          />
        </b-form-group>

        <b-form-group>
          <b-form-checkbox
              v-model="companyForm.station_1"
              name="check-button"
              class="custom-control-success"
              switch
              inline
          >
            Մարդատար
          </b-form-checkbox>
        </b-form-group>

        <b-form-group>
          <b-form-checkbox
              v-model="companyForm.station_2"
              name="check-button"
              class="custom-control-success"
              switch
              inline
          >
            Բեռնատար
          </b-form-checkbox>
        </b-form-group>

      </b-form>
    </b-modal>

    <b-modal
        id="modal-tax-invoice"
        :no-close-on-backdrop="true"
        cancel-variant="outline-secondary"
        ok-title="Արտահանել"
        cancel-title="Կասեցնել"
        centered
        size="sm"
        title="Հարկային հաշվետվություն"
        :is-valid="false"
        @ok="exportTaxInvoice"
    >

      <b-form>

        <b-form-group>
          <v-select
              @input="updateTaxRange"
              placeholder="Ժամանակահատված"
              id="vue-select"
              v-model="taxRanges"
              :reduce="prefix => prefix.value"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="rangesSelect"
              class="mb-1"
          />
        </b-form-group>

        <div class="taxRangeDates">
          <b-form-group class="taxRangeDateItem">
            <b-form-input
                @input="getCheckupsForTax"
                v-model="taxForm.startDay"
                id="startDay"
                type="date"
                placeholder="Սկիզբ"
            />
          </b-form-group>
          <b-form-group class="taxRangeDateItem">
            <b-form-input
                @input="getCheckupsForTax"
                v-model="taxForm.endDay"
                id="endDay"
                type="date"
                placeholder="Ավարտ"
            />
          </b-form-group>
        </div>


      </b-form>

    </b-modal>

    <b-modal
        id="modal-date-ranges"
        :no-close-on-backdrop="true"
        cancel-variant="outline-secondary"
        ok-title="Հասատել"
        centered
        size="sm"
        title="Ժամանակահատված"
        :is-valid="false"
        @ok="updateDateRangeViewer"
    >

      <b-form>

        <b-form-group>
          <v-select
              @input="updateCheckupRange"
              placeholder="Ժամանակահատված"
              id="vue-select"
              v-model="checkupRanges"
              :reduce="prefix => prefix.value"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="checkupRangesSelect"
              class="mb-1"
          />
        </b-form-group>

        <div class="taxRangeDates">
          <b-form-group class="taxRangeDateItem">
            <b-form-input
                v-model="rangesForm.startDay"
                id="startDay"
                type="date"
                placeholder="Սկիզբ"
            />
          </b-form-group>
          <b-form-group class="taxRangeDateItem">
            <b-form-input
                v-model="rangesForm.endDay"
                id="endDay"
                type="date"
                placeholder="Ավարտ"
            />
          </b-form-group>
        </div>


      </b-form>

    </b-modal>

    <b-modal
        ref="view-modal-search"
        id="view-modal-search"
        cancel-variant="outline-secondary"
        ok-title="Ընտրել"
        centered
        size="xl"
        title="Որոնել"
        :is-valid="false"
        :hide-footer="true"
        @hide="hideSearchModal"
    >

      <div class="search-modal-header">
        <div class="search-modal-header">
          <div class="search-header-column">
            <MazInput
                :dark="isDark"
                autofocus
                @input="formSubmited = false"
                @change="searchCheckups"
                type="text"
                @keydown="disableSymbol($event)"
                v-model="viewForm.value"
                placeholder="Փնտրել..."
                autocomplete="new-search"
                class="w-full mb-1 text-uppercase"
                old-left-icon-name="search">
            </MazInput>
          </div>
          <div class="search-header-column">
            <b-button
                @click="searchCheckups"
                variant="outline-primary"
                class="btn-icon searchSubmitButton"
            >
              <feather-icon icon="SearchIcon"/>
            </b-button>
          </div>
        </div>

      </div>

      <div class="search-modal-body">
        <div v-if="search_result.length === 0 && viewForm.value !== ''">
          <b-alert
              v-if="formSubmited"
              variant="danger"
              show
          >
            <div class="alert-body">
              <span>Շտեմարանում {{ viewForm.value.toUpperCase() }}-ի վերաբերյալ տեղեկատվություն առկա չէ։</span>
            </div>
          </b-alert>

        </div>
        <b-table
            v-else
            striped
            hover
            responsive
            class="position-relative"
            :items="search_result"
            :fields="fields"
        >
          <template #cell(car_model)="data">
            {{ data.item.car }} {{ data.item.car_model }}
          </template>
          <template #cell(taxes)="data">
            <template v-if="data.item.taxes && data.item.taxes.length > 0 && data.item.taxes.decision.inspection">
              <div  @click="openTaxesInfo(data.item.taxes)" class="can-make-checkup can-make-checkup-1">
                <fa-icon icon="check-circle" />
              </div>

            </template>
            <template v-else>
              <div @click="openTaxesInfo(data.item.taxes)" class="can-make-checkup can-make-checkup-0">
                <fa-icon icon="info-circle" />
              </div>
            </template>
            <template v-if="!data.item.dahk_status">
              <div @click="openDahkInfo(data.item.dahk_info)" class="mt-1 animate-flicker can-make-checkup can-make-checkup-0">
                ԴԱՀԿ հետախուզում
              </div>
            </template>
          </template>
        </b-table>
      </div>

    </b-modal>

    <b-modal
        id="view-modal-taxes-info"
        ok-only
        :hide-footer="true"
        modal-class="modal-primary"
        centered
        title="Գույքահարկի պարտավորություններ"
    >
      <b-card-text>
        <div class="taxes-info">
          <div v-for="(service, index) in taxesInfo.services"  :key="index" class="tax-info">
            <template>
              <div class="persons">
                <div v-for="(person, personIndex) in service.persons" :key="personIndex" class="person">
                  <div class="personInfo" :class="(taxesInfo.decision.inspection) ? 'success' : ''">
                    <template v-if="person.first_name">
                      <span>{{ person.first_name }} {{ person.last_name}}</span>
                      <span v-if="service.service.vehicle_number"> - {{ service.service.vehicle_number }}</span>
                      <span v-if="service.service.cert_num">({{ service.service.cert_num }})</span>
                    </template>
                    <template v-else>
                      <span>{{ person.organization_name }}</span>
                      <span v-if="service.service.vehicle_number"> - {{ service.service.vehicle_number }}</span>
                      <span v-if="service.service.cert_num">({{ service.service.cert_num }})</span>
                    </template>

                  </div>
                  <table class="taxes">
                    <thead>
                    <tr class="tax tax-header">
                      <td class="column">Տարեթիվ</td>
                      <td class="column">Գույքահարկ</td>
                      <td class="column">Պարտք</td>
                      <td class="column">Տույժ/Տուգանք</td>
                      <td class="column">Հավելավճար</td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(tax, year) in person.taxes" :key="year" class="tax">
                      <td class="column">{{ year }}</td>
                      <td class="column">{{ tax.tax.amount }}</td>
                      <td class="column">{{ tax.tax.debt }}</td>
                      <td class="column">{{ (tax.penalty) ? tax.penalty.debt : 0 }}</td>
                      <td class="column">{{ tax.overpay }}</td>
                    </tr>
                    </tbody>

                  </table>
                </div>
              </div>
            </template>
          </div>
        </div>
      </b-card-text>
    </b-modal>

    <b-modal
        id="modal-edit-history"
        :no-close-on-backdrop="true"
        centered
        size="xl"
        class="modal-responsive"
        title="Խմբագրման հայտեր"
        :is-valid="false"
    >

      <b-form>
        <b-form-group>

          <b-table
              striped
              hover
              responsive
              class="position-relative"
              :per-page="perPage"
              :current-page="currentPage"
              :items="editHistory"
              :fields="fieldsHistory"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
          >
            <template #cell(createdAt)="data">
              {{ data.value | formatDate }}
            </template>
            <template #cell(title)="data">
              {{ data.value.replace(' - խմբագրման հայտ', '') }}
            </template>
            <template #cell(status)="data">
              <b-badge class="w-100" :variant="data.value ? 'success' : 'warning'">
                {{ data.value ? 'Այո': 'Ոչ' }}
              </b-badge>
            </template>
          </b-table>

          <b-card-body class="d-flex justify-content-between flex-wrap pt-0 mt-2">

            <!-- page length -->
            <b-form-group
                :label="'Գտնվել է ' + editHistory.length + ' տող'"
                label-cols="3"
                label-align="left"
                label-size="sm"
                label-for="sortBySelect"
                class="text-nowrap mb-md-0 mr-1"
            >
            </b-form-group>

            <!-- pagination -->
            <div>
              <b-pagination
                  v-model="currentPage"
                  :total-rows="editHistory.length"
                  :per-page="perPage"
                  first-number
                  last-number
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mb-0"
              >
                <template #prev-text>
                  <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </b-card-body>


        </b-form-group>
      </b-form>


      <template #modal-footer="{ ok, cancel, hide }">
        <span variant="info">&nbsp;</span>
      </template>


    </b-modal>



  </div>
</template>

<script>
import {
  BLink,
  BNavbarNav,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
  BBadge,
  BDropdown,
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BFormCheckbox,
  BCardBody,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BPagination,
  BTable,
  BAlert,
  BFormTextarea,
  BCardText,
  VBTooltip
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import authService from '@/services/authService'
import moduleAuth from '@/store/auth/moduleAuth'
import analysisModule from '@/store/analysis'
import store from '@/store'
import techServiceModule from '@/store/tech-service/techServiceModule'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import NotificationDropdown from './NotificationDropdown'
import vSelect from 'vue-select'
import axios from '@axios'
import TableToExcel from '@linways/table-to-excel'
import html2pdf from 'html2pdf.js'
import jsPDF from 'jspdf'
import companyModule from '@/store/companies/companyModule'
let todayStart = new Date()
todayStart.setHours(0, 0, 0, 0)
import { saveAs } from 'file-saver';
import useAppConfig from "@core/app-config/useAppConfig";
import {computed} from "@vue/composition-api/dist/vue-composition-api";

let todayEnd = new Date()
todayEnd.setHours(23, 59, 59, 59)

const convert = require('xml-js')

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    BBadge,
    BDropdown,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BFormCheckbox,
    BCardBody,
    BFormSelect,
    BInputGroup,
    BInputGroupAppend,
    BPagination,
    BTable,
    BAlert,
    BFormTextarea,
    BCardText,
    VBTooltip,
    DateRangePicker,
    // Navbar Components
    DarkToggler,
    NotificationDropdown,
    vSelect
  },
  setup() {
    const { skin } = useAppConfig()

    const isDark = computed(() => skin.value === 'dark')

    return {
      skin,
      isDark
    }
  },
  data() {
    return {
      perPage: 15,
      pageOptions: [this.perPage],
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      fieldsHistory: [
        {
          key: 'createdAt',
          label: 'Ամսաթիվ',
        },
        {
          key: 'from.name',
          label: 'Մոդերատոր',
        },
        {
          key: 'companyName',
          label: 'Կազմակերպություն',
        },
        {
          key: 'title',
          label: 'Համարանիշ',
        },
        {
          key: 'message',
          label: 'Խմբագրման հարցում',
          class: 'maxWidth250',
        },
        {
          key: 'status',
          label: 'Դիտվել է',
        },
      ],
      viewersBlocked: false,
      paymentsEnabled: false,
      mobileRedirectEnabled: false,
      taxesInfo: [],
      editHistory: [],
      fields: [
        {
          key: 'car_model',
          label: 'Մեքենա',
        },
        {
          key: 'taxes',
          label: 'Գույքահարկ',
        },
        {
          key: 'CarRegNo',
          label: 'Գր․ համարանիշ',
        },
        {
          key: 'VIN',
          label: 'VIN',
        }
      ],
      viewForm: {
        value: null,
      },
      formSubmited: false,
      companyForm: {

      },
      AUTH_STORE_MODULE_NAME: 'auth',
      TECH_SERVICE_STORE_MODULE_NAME: 'techServiceModule',
      COMPANIES_STORE_MODULE_NAME: 'companiesModule',
      ANALYSIS_MODULE_NAME: 'analysisModule',
      localeData: {
        applyLabel: 'Հաստատել',
        cancelLabel: 'Չեղարկել',
        ranges: [],
        firstDay: 1,
        format: 'dd/mm/yyyy',
        customRangeLabel: 'Այլ',
        daysOfWeek: ['Կիր', 'Երկ', 'Երք', 'Չոր', 'Հնգ', 'Ուրբ', 'Շբթ'],
        monthNames: ['Հուն', 'Փետ', 'Մար', 'Ապր', 'Մայ', 'Հուն', 'Հուլ', 'Օգ', 'Սեպ', 'Հոկ', 'Նոյ', 'Դեկ'],
        taxData: []
      },
      passwordForm: {
        oldPassword: '',
        password: '',
        repeatPassword: ''
      },
      taxRanges: todayStart + ' - ' + todayEnd,
      checkupRanges: todayStart + ' - ' + todayEnd,
      taxForm: {
        startDay: this.$options.filters.formatDayInput(todayStart),
        endDay: this.$options.filters.formatDayInput(todayEnd),
      },
      rangesForm: {
        startDay: this.$options.filters.formatDayInput(todayStart),
        endDay: this.$options.filters.formatDayInput(todayEnd),
      },
      json_fields: {
        'ՏՄ տնօրինող': 'ownerStatus.Owner',
        'Մարզ': 'region',
        'Համայնք': 'county',
        'Փողոց': 'street',
        'Հասցե': 'address',
        'Մակնիշ': 'car',
        'Մոդել': 'car_model',
        'Գրանցման համարանիշ': 'CarRegNo',
        'Կտրոնի համար': {
          field: 'CheckNo',
          callback: (value) => {
            return `/${value.toString()}`
          }
        },
        'Տեխզննման օր/ժամ': {
          field: 'createdAt',
          callback: (value) => {
            return this.$options.filters.formatDate(value)
          }
        },
      },
    }
  },
  computed: {

    requiredSubscribtion () {
      if (this.company && this.company.subscription && this.company.subscriptionTotal > 0 && !this.company.subscriptionStatus) {
        document.body.classList.add('addSubscribtion')
        return true;
      }

      return false;
    },

    syncedEnabled: {
      get() {
        return this.$store.state[this.TECH_SERVICE_STORE_MODULE_NAME].syncedEnabled
      },
      set(value) {
        this.$store.commit(`${this.TECH_SERVICE_STORE_MODULE_NAME}/SET_SYNCED_STATUS`, value);
      }
    },

    hasAccessToTS() {
      let page = this.$store.state.pages.filter(e => e.route === 'tech-service-view');
      return page.length > 0
    },

    hasAccessToTSAdmin() {
      let page = this.$store.state.pages.filter(e => e.route === 'tech-service-all');
      return page.length > 0
    },

    search_result() {
      return this.$store.state[this.TECH_SERVICE_STORE_MODULE_NAME].search_result
    },

    warningStatusColor() {
      return hours => {
        const warmDate = new Date(this.company.warmDate)
        const now = new Date()

        const diffTime = Math.abs(now - warmDate);
        const diffHours = Math.ceil(diffTime / (1000 * 60 * 60));

        return (diffHours > 72) ? 'danger' : (diffHours > hours - 24) ? 'warning' : ''
      }
    },

    today_count () {
      return this.$store.state.today_count
    },

    today_offline_count () {
      return this.$store.state.today_offline_count
    },

    export_checkups () {
      return this.$store.state[this.TECH_SERVICE_STORE_MODULE_NAME].export_checkups
    },

    checkups() {
      return this.$store.state[this.TECH_SERVICE_STORE_MODULE_NAME].checkups
    },

    company() {
      return this.$store.state[this.TECH_SERVICE_STORE_MODULE_NAME].company
    },

    companies() {
      return this.$store.state[this.COMPANIES_STORE_MODULE_NAME].companies
    },

    rangesSelect() {
      const data = []
      for (let key in this.dateRanges) {
        let temp = {}
        temp.label = key
        let start = this.dateRanges[key][0]
        start.setHours(0, 0, 0, 0)
        let end = this.dateRanges[key][1]
        end.setHours(23, 59, 59, 59)
        temp.value = start + ' - ' + end
        data.push(temp)
      }
      return data
    },

    checkupRangesSelect() {
      const data = []
      for (let key in this.dateRanges) {
        if (key !== 'Բոլորը') {
          let temp = {}
          temp.label = key
          let start = this.dateRanges[key][0]
          start.setHours(0, 0, 0, 0)
          let end = this.dateRanges[key][1]
          end.setHours(23, 59, 59, 59)
          temp.value = start + ' - ' + end
          data.push(temp)
        }

      }
      return data
    },

    firstCheckupDate() {
      return this.$store.state[this.TECH_SERVICE_STORE_MODULE_NAME].firstCheckupDate
    },

    dateRanges() {
      // Today
      let today = new Date()

      let allStart = new Date(this.firstCheckupDate)

      let todayStart = new Date()
      let todayEnd = new Date()

      // Yesterday
      let yesterdayStart = new Date()
      yesterdayStart.setDate(today.getDate() - 1)
      let yesterdayEnd = new Date()
      yesterdayEnd.setDate(today.getDate() - 1)

      // This week
      let thisWeekStart = new Date(new Date().setDate(new Date().getDate() + 1 - new Date().getDay()))
      let thisWeekEnd = new Date(new Date().setDate(new Date().getDate() + 1 - new Date().getDay() + 6))

      // Last Week
      let lastWeekStart = new Date(new Date().setDate(new Date().getDate() - 6 - new Date().getDay()))
      let lastWeekEnd = new Date(new Date().setDate(new Date().getDate() - new Date().getDay()))

      // This Month
      let thisMonthStart = new Date(new Date().getFullYear(), new Date().getMonth(), 1)
      let thisMonthEnd = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)

      // Last Month
      let lastMonthStart = new Date(today.getFullYear(), today.getMonth() - 1, 1)
      let lastMonthEnd = new Date(today.getFullYear(), today.getMonth(), 0)

      // This Quarter
      let currentMonth = today.getMonth()
      let currentYear = today.getFullYear()
      let thisQuarterStart
      let thisQuarterEnd
      let lastQuarterStart
      let lastQuarterEnd
      if (currentMonth >= 0 && currentMonth < 3) {
        thisQuarterStart = new Date(today.getFullYear(), 0, 1)
        thisQuarterEnd = new Date(new Date().getFullYear(), 2, 31)

        lastQuarterStart = new Date(today.getFullYear() - 1, 9, 1)
        lastQuarterEnd = new Date(today.getFullYear() - 1, 11, 31)
      } else if (currentMonth >= 3 && currentMonth < 6) {
        thisQuarterStart = new Date(today.getFullYear(), 3, 1)
        thisQuarterEnd = new Date(new Date().getFullYear(), 5, 30)

        lastQuarterStart = new Date(today.getFullYear(), 0, 1)
        lastQuarterEnd = new Date(new Date().getFullYear(), 2, 31)
      } else if (currentMonth >= 6 && currentMonth < 9) {
        thisQuarterStart = new Date(today.getFullYear(), 6, 1)
        thisQuarterEnd = new Date(new Date().getFullYear(), 8, 30)

        lastQuarterStart = new Date(today.getFullYear(), 3, 1)
        lastQuarterEnd = new Date(new Date().getFullYear(), 5, 30)
      } else if (currentMonth >= 9 && currentMonth < 12) {
        thisQuarterStart = new Date(today.getFullYear(), 9, 1)
        thisQuarterEnd = new Date(new Date().getFullYear(), 11, 31)

        lastQuarterStart = new Date(today.getFullYear(), 3, 1)
        lastQuarterEnd = new Date(new Date().getFullYear(), 5, 30)
      }

      let thisSemesterStart
      let thisSemesterEnd
      let lastSemesterStart
      let lastSemesterEnd
      if (currentMonth >= 0 && currentMonth < 5) {
        thisSemesterStart = new Date(today.getFullYear(), 0, 1)
        thisSemesterEnd = new Date(new Date().getFullYear(), 5, 30)

        lastSemesterStart = new Date(today.getFullYear() - 1, 6, 1)
        lastSemesterEnd = new Date(today.getFullYear() - 1, 11, 31)

      } else {
        thisSemesterStart = new Date(today.getFullYear(), 6, 1)
        thisSemesterEnd = new Date(new Date().getFullYear(), 11, 31)

        lastSemesterStart = new Date(today.getFullYear(), 0, 1)
        lastSemesterEnd = new Date(today.getFullYear(), 5, 30)
      }

      // This Year
      let thisYearStart = new Date(new Date().getFullYear(), 0, 1)
      let thisYearEnd = new Date(new Date().getFullYear(), 11, 31)

      // Last Year
      let lastYearStart = new Date(new Date().getFullYear() - 1, 0, 1)
      let lastYearEnd = new Date(new Date().getFullYear() - 1, 11, 31)

      return {
        'Այսօր': [todayStart, todayEnd],
        'Երեկ': [yesterdayStart, yesterdayEnd],
        'Այս շաբաթ': [thisWeekStart, thisWeekEnd],
        'Անցած շաբաթ': [lastWeekStart, lastWeekEnd],
        'Այս ամիս': [thisMonthStart, thisMonthEnd],
        'Անցած ամիս': [lastMonthStart, lastMonthEnd],
        'Այս եռամսյակ': [thisQuarterStart, thisQuarterEnd],
        'Անցած եռամսյակ': [lastQuarterStart, lastQuarterEnd],
        'Այս կիսամյակ': [thisSemesterStart, thisSemesterEnd],
        'Անցած կիսամյակ': [lastSemesterStart, lastSemesterEnd],
        'Այս տարի': [thisYearStart, thisYearEnd],
        'Անցած տարի': [lastYearStart, lastYearEnd],
        'Բոլորը': [allStart, todayEnd],
      }
    },
    dateRangesAdmin() {
      const ranges = { ...this.dateRanges }
      if (this.isViewer) {
        delete ranges['Բոլորը']
      }

      return ranges
    },
    user() {
      return authService.user()
    },
    mechanics() {
      return this.$store.state.mechanics
    },
    activeMechanic() {
      return this.$store.state.activeMechanic
    },
    mechanicName() {
      return (this.activeMechanic) ? 'Հսկիչ։ ' + this.activeMechanic.name : 'Հսկիչ։'
    },
    serverStatus() {
      return this.$store.state.serverStatus
    },
    serverStatusColor() {
      return (this.serverStatus) ? 'statusIcon success' : 'statusIcon danger'
    },
    serverStatusIcon() {
      return (this.serverStatus) ? 'CheckCircleIcon' : 'AlertTriangleIcon'
    },
    serverStatusName() {
      return (this.serverStatus) ? 'Կապն առկա է' : 'Կապ չկա'
    },
    isOperator() {
      return (atob(this.$store.state.user_type) === 'TECHNICAL_SERVICE_OPERATOR')
    },
    isAdmin() {
      return (atob(this.$store.state.user_type) === 'ADMIN')
    },
    isOwner() {
      return (atob(this.$store.state.user_type) === 'TECHNICAL_SERVICE_OWNER' || atob(this.$store.state.user_type) === 'TECHNICAL_SERVICE_VIEWER')
    },
    isViewer() {
      return (atob(this.$store.state.user_type) === 'VIEWER')
    },
    gridApi() {
      return this.$store.state[this.TECH_SERVICE_STORE_MODULE_NAME].gridApi
    },
    gridColumnApi() {
      return this.$store.state[this.TECH_SERVICE_STORE_MODULE_NAME].gridColumnApi
    },
    add() {
      return this.$store.state[this.TECH_SERVICE_STORE_MODULE_NAME].add
    },

    edit() {
      return this.$store.state[this.TECH_SERVICE_STORE_MODULE_NAME].edit
    },

    pagination() {
      return this.$store.state[this.TECH_SERVICE_STORE_MODULE_NAME].pagination
    },

    filters() {
      return this.$store.state[this.TECH_SERVICE_STORE_MODULE_NAME].filters
    },

    dateRange: {
      get() {
        return this.$store.state[this.TECH_SERVICE_STORE_MODULE_NAME].dateRange
      },
      set(value) {
        this.$store.commit(this.TECH_SERVICE_STORE_MODULE_NAME + '/UPDATE_DATE_RANGES', value)
      }
    },

    dateRangeAnalysis: {
      get() {
        return this.$store.state[this.ANALYSIS_MODULE_NAME].dateRange
      },
      set(value) {
        this.$store.commit(this.ANALYSIS_MODULE_NAME + '/UPDATE_DATE_RANGES', value)
      }
    },

    validatePasswordForm() {
      return this.passwordForm.oldPassword === '' ||
          this.passwordForm.password === '' ||
          this.passwordForm.repeatPassword === '' ||
          this.passwordForm.password !== this.passwordForm.repeatPassword ||
          this.passwordForm.password === this.passwordForm.oldPassword
    },

    docs() {
      return this.$store.state[this.TECH_SERVICE_STORE_MODULE_NAME].docs
    }
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {
      },
    },
  },
  created() {
    if (!store.hasModule(this.AUTH_STORE_MODULE_NAME)) store.registerModule(this.AUTH_STORE_MODULE_NAME, moduleAuth)
    if (!store.hasModule(this.TECH_SERVICE_STORE_MODULE_NAME)) store.registerModule(this.TECH_SERVICE_STORE_MODULE_NAME, techServiceModule)
    if (!store.hasModule(this.COMPANIES_STORE_MODULE_NAME)) store.registerModule(this.COMPANIES_STORE_MODULE_NAME, companyModule)
    if (this.isAdmin || this.isViewer) {
      if (!store.hasModule(this.ANALYSIS_MODULE_NAME)) store.registerModule(this.ANALYSIS_MODULE_NAME, analysisModule)
    }
    if (this.isOperator) {
      this.getMechanics()
    }
    this.getFirstCheckup()
    this.getNotifications()
    this.getCompanies()
    this.getStatuses()
  },
  methods: {

    openFMModal () {
      this.$bvModal.show('fm-modal')
    },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },

    toggleSetting(setting) {
      this.$swal({
        title: 'Հաստատե՞լ գործողությունը',
        text: '',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Այո',
        cancelButtonText: 'Ոչ',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
          .then(result => {
            if (result.value) {
              if (setting === 'viewersBlocked') {
                let value = (this.viewersBlocked === true) ? false : true;
                this.viewersBlocked = value;
                this.$store.dispatch('updateSetting', {name: 'viewers_blocked', value: value})
              } else if (setting === 'paymentsEnabled') {
                let value = (this.paymentsEnabled === true) ? false : true;
                this.paymentsEnabled = value;
                this.$store.dispatch('updateSetting', {name: 'payments_enabled', value: value})
              } else if (setting === 'mobileRedirect') {
                let value = (this.mobileRedirectEnabled === true) ? false : true;
                this.mobileRedirectEnabled = value;
                this.$store.dispatch('updateSetting', {name: 'mobile_redirect_enabled', value: value})
              }
            }
          })
    },

    toggleSyncedCheckups() {
      this.syncedEnabled = !this.syncedEnabled;
      this.updateDateRangeAdmin();
    },

    getStatuses() {
      this.$store.dispatch('getStatuses')
        .then(response => {
          response.data.map(item => {
            if (item.name === "viewers_blocked") {
              this.viewersBlocked = item.value;
              if (this.viewersBlocked) {
                document.body.classList.add('viweBlock')
              }
            }
            if (item.name === "payments_enabled") {
              this.paymentsEnabled = item.value;
            }

            if (item.name === 'mobile_redirect_enabled') {
              this.mobileRedirectEnabled = item.value;
            }
          })
        })
    },

    openDahkInfo(data) {
      this.$swal({
        id: 'dahk-swal',
        title: '',
        text: data.block_reason,
        icon: 'info',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },

    openTaxesInfo(taxes) {
      this.taxesInfo = taxes
      this.$bvModal.show('view-modal-taxes-info')
    },

    disableSymbol(e) {
      if (!(/^[*a-zA-Z0-9\b\cC\cX\cA\cV\cZ]*$/.test(e.key))) {
        e.preventDefault()
      }
    },

    searchCheckups() {
      if (this.viewForm.value !== '') {
        const data = 'CarRegNo=' + this.viewForm.value
        this.$store.dispatch(this.TECH_SERVICE_STORE_MODULE_NAME + '/searchViewCheckups', data)
            .then(() => {
              this.formSubmited = true
            })
      } else {
        this.$store.commit(this.TECH_SERVICE_STORE_MODULE_NAME + '/SET_SEARCH_RESULT', [])
      }
    },

    hideSearchModal() {
      this.viewForm = {
        value: ''
      }
      this.$store.commit(this.TECH_SERVICE_STORE_MODULE_NAME + '/SET_SEARCH_DATE_RESULT', [])
    },

    getCompanies() {
      this.$store.dispatch(this.COMPANIES_STORE_MODULE_NAME + '/getCompanies')
    },

    getNotifications() {
      if (this.user) {
        this.$store.dispatch('getNotifications')
      }
    },

    getFirstCheckup() {
      this.$store.dispatch(this.TECH_SERVICE_STORE_MODULE_NAME + '/getFirstCheckup')
    },

    logOut() {
      this.$store.dispatch(this.AUTH_STORE_MODULE_NAME + '/signOut')
    },
    getMechanics() {
      this.$store.dispatch('getMechanics')
    },
    selectMechanic(mechanic) {
      this.$store.commit('SET_ACTIVE_MECHANIC', {
        id: mechanic.id,
        name: mechanic.name
      })
    },
    openSearchModal() {
      if (!this.company.blocked) {
        this.isFormOpen()
            .then(() => {
              this.isDocOpen('classificator')
                  .then(() => {
                    if (!this.$device.isMobile) {
                      this.$store.commit(this.TECH_SERVICE_STORE_MODULE_NAME + '/SET_SEARCH_RESULT', [])
                      this.$bvModal.show('modal-search')
                    } else {
                      this.$store.commit(this.TECH_SERVICE_STORE_MODULE_NAME + '/SET_SEARCH_RESULT', [])
                      this.$bvModal.show('modal-search-mobile')
                    }
                  })
            })
      }
      else {
        this.$swal({
          title: this.company.blockComment,
          text: '',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      }

    },

    openViewSearchModal() {
      this.$bvModal.show('view-modal-search')
    },

    openSearchPaymentModal() {
      if (!this.company.blocked) {
        this.isFormOpen()
            .then(() => {
              this.isDocOpen('classificator')
                  .then(() => {
                    this.$store.commit(this.TECH_SERVICE_STORE_MODULE_NAME + '/SET_SEARCH_PAYMENT_RESULT', null)
                    this.$bvModal.show('modal-payment-search')
                  })
            })
      }
      else {
        this.$swal({
          title: this.company.blockComment,
          text: '',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      }

    },

    openPasswordModal() {
      this.$bvModal.show('modal-change-password-form')
    },

    openEditHistoryModal() {
      this.$vs.loading()
      this.$store.dispatch('getEditHistories')
          .then(response => {
            this.$vs.loading.close()
            this.editHistory = response.data.data;
            this.$bvModal.show('modal-edit-history')
          })
          .catch(() => {
            this.$vs.loading.close()
          })
    },

    openCompanyModal(company) {
      this.companyForm = {
        id: company._id,
        name: company.name,
        phone: company.phone,
        bank: company.bank,
        tax: company.tax,
        boss: company.boss,
        station_1: company.station_1,
        station_2: company.station_2,
      }
      this.$bvModal.show('modal-change-company-form')

    },

    openClassificatorDoc() {
      this.isFormOpen()
          .then(() => {
            let selectedNodes = this.gridApi.getSelectedNodes()
            if (typeof selectedNodes !== 'undefined') {
              let selectedData = selectedNodes.map(node => node.data)
              if (selectedData.length === 0) {
                this.$swal({
                  title: '',
                  text: 'Նշեք տեխզննման տողը',
                  icon: 'warning',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
              } else {
                this.$store.commit(this.TECH_SERVICE_STORE_MODULE_NAME + '/SET_SELECTED_CHECKUP', selectedData[0])
                this.$store.commit(this.TECH_SERVICE_STORE_MODULE_NAME + '/OPEN_DOC', 'classificator')
              }
            } else {
              this.$store.commit(this.TECH_SERVICE_STORE_MODULE_NAME + '/CLOSE_ALL_DOCS')
              this.$swal({
                title: '',
                text: 'Նշեք տեխզննման տողը',
                icon: 'warning',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            }
          })
    },

    openFinancialDoc() {
      this.isFormOpen()
          .then(() => {
            this.isDocOpen('classificator')
                .then(() => {
                  this.$store.commit(this.TECH_SERVICE_STORE_MODULE_NAME + '/OPEN_DOC', 'financial')
                })
          })
    },

    openInfoDoc() {
      this.isFormOpen()
          .then(() => {
            this.isDocOpen('info')
                .then(() => {
                  this.$store.commit(this.TECH_SERVICE_STORE_MODULE_NAME + '/OPEN_DOC', 'info')
                })
          })
    },

    openReportViewDoc() {
      this.isFormOpen()
          .then(() => {
            this.isDocOpen('reportView')
                .then(() => {
                  this.$store.commit(this.TECH_SERVICE_STORE_MODULE_NAME + '/OPEN_DOC', 'reportView')
                })
          })
    },

    openCountDoc() {
      this.isFormOpen()
          .then(() => {
            this.isDocOpen('classificator')
                .then(() => {
                  this.$store.commit(this.TECH_SERVICE_STORE_MODULE_NAME + '/OPEN_DOC', 'count')
                })
          })
    },

    openOperatorsDoc() {
      this.isFormOpen()
          .then(() => {
            this.isDocOpen('classificator')
                .then(() => {
                  this.$store.commit(this.TECH_SERVICE_STORE_MODULE_NAME + '/OPEN_DOC', 'operators')
                })
          })
    },

    openIndividualDoc() {
      this.isFormOpen()
          .then(() => {
            this.isDocOpen('classificator')
                .then(() => {
                  this.$store.commit(this.TECH_SERVICE_STORE_MODULE_NAME + '/OPEN_DOC', 'individual')
                })
          })
    },

    openCompanyDoc() {
      this.isFormOpen()
          .then(() => {
            this.isDocOpen('classificator')
                .then(() => {
                  this.$store.commit(this.TECH_SERVICE_STORE_MODULE_NAME + '/OPEN_DOC', 'company')
                })
          })
    },

    openEntrepreneurDoc() {
      this.isFormOpen()
          .then(() => {
            this.isDocOpen('classificator')
                .then(() => {
                  this.$store.commit(this.TECH_SERVICE_STORE_MODULE_NAME + '/OPEN_DOC', 'entrepreneur')
                })
          })
    },

    openAmountsDoc() {
      this.isFormOpen()
          .then(() => {
            this.isDocOpen('classificator')
                .then(() => {
                  this.$store.commit(this.TECH_SERVICE_STORE_MODULE_NAME + '/OPEN_DOC', 'amounts')
                })
          })
    },

    openFuelDoc() {
      this.isFormOpen()
          .then(() => {
            this.isDocOpen('classificator')
                .then(() => {
                  this.$store.commit(this.TECH_SERVICE_STORE_MODULE_NAME + '/OPEN_DOC', 'fuel')
                })
          })
    },

    openTaxInvoice() {
      this.$bvModal.show('modal-tax-invoice')
      this.getCheckupsForTax()
    },

    isFormOpen() {
      return new Promise((resolve, reject) => {
        if (this.add || this.edit) {
          this.$swal({
            title: 'Չեղարկե՞լ գրանցումը',
            text: '',
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: 'Այո',
            cancelButtonText: 'Ոչ',
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-danger ml-1',
            },
            buttonsStyling: false,
          })
              .then(result => {
                if (result.value) {
                  this.$store.commit(this.TECH_SERVICE_STORE_MODULE_NAME + '/CHANGE_ADD_STATE', false)
                  this.$store.commit(this.TECH_SERVICE_STORE_MODULE_NAME + '/CLOSE_EDIT_FORM')
                  this.$store.commit(this.TECH_SERVICE_STORE_MODULE_NAME + '/CLOSE_COPY_FORM')
                  resolve(true)
                } else {
                  reject({ status: false })
                }
              })
        } else {
          resolve(true)
        }
      })
    },

    updateDateRange() {
      this.dateRange.startDate.setHours(0, 0, 0, 0)
      this.dateRange.endDate.setHours(23, 59, 59, 59)
      this.isDocOpen('classificator')
          .then(() => {
            this.$store.commit(this.TECH_SERVICE_STORE_MODULE_NAME + '/UPDATE_DATE_RANGES', this.dateRange)
            this.$vs.loading()
            const filters = JSON.parse(JSON.stringify(this.filters))
            const { fields } = filters
            delete filters.fields
            filters.columnFilter = JSON.stringify(fields)
            const filterData = {
              current_page: this.pagination.current_page,
              ...filters
            }
            let action = '/getCheckups'
            if (this.$route.name.includes('control')) {
              action = '/getControls'
            }
            this.$store.dispatch(this.TECH_SERVICE_STORE_MODULE_NAME + action, this.serialize(filterData))
                .then(() => {
                  this.$vs.loading.close()
                })
                .catch((error) => {
                  this.$root.$emit('getError', error)
                  this.$vs.loading.close()
                })
          })
    },

    updateDateRangeAdmin() {
      this.dateRange.startDate.setHours(0, 0, 0, 0)
      this.dateRange.endDate.setHours(23, 59, 59, 59)
      this.$store.commit(this.TECH_SERVICE_STORE_MODULE_NAME + '/UPDATE_DATE_RANGES', this.dateRange)
      this.$vs.loading()
      const filters = JSON.parse(JSON.stringify(this.filters))
      const { fields } = filters
      delete filters.fields
      filters.columnFilter = JSON.stringify(fields)
      const filterData = {
        current_page: this.pagination.current_page,
        ...filters
      }
      if (this.syncedEnabled) {
        filterData.onepayExists = true;
      }
      let action
      if (this.$route.name.includes('control')) {
        action = '/getControls'
      }
      else if (this.isAdmin) {
        action = '/getAllCheckups'
      } else if (this.isOwner) {
        action = '/getCompanyCheckups'
      } else if (this.isViewer) {
        action = '/getViewerCheckups'
      }
      this.$store.dispatch(this.TECH_SERVICE_STORE_MODULE_NAME + action, this.serialize(filterData))
          .then(() => {
            this.$vs.loading.close()
          })
          .catch((error) => {
            this.$root.$emit('getError', error)
            this.$vs.loading.close()
          })
    },

    updateDateRangeViewer() {

      let dateTimeStart = new Date(this.rangesForm.startDay)
      let dateTimeEnd = new Date(this.rangesForm.endDay)
      dateTimeStart.setHours(0, 0, 0, 0)
      dateTimeEnd.setHours(23, 59, 59, 59)

      this.$store.commit(this.TECH_SERVICE_STORE_MODULE_NAME + '/UPDATE_DATE_RANGES', {
        startDate: dateTimeStart,
        endDate: dateTimeEnd
      })

      this.$vs.loading()
      const filters = JSON.parse(JSON.stringify(this.filters))
      const { fields } = filters
      delete filters.fields
      filters.columnFilter = JSON.stringify(fields)
      const filterData = {
        current_page: this.pagination.current_page,
        ...filters
      }
      let action
      if (this.$route.name.includes('control')) {
        action = '/getControls'
      }
      else if (this.isAdmin) {
        action = '/getAllCheckups'
      } else if (this.isOwner) {
        action = '/getCompanyCheckups'
      } else if (this.isViewer) {
        action = '/getViewerCheckups'
      }
      this.$store.dispatch(this.TECH_SERVICE_STORE_MODULE_NAME + action, this.serialize(filterData))
          .then(() => {
            this.$vs.loading.close()
          })
          .catch((error) => {
            this.$root.$emit('getError', error)
            this.$vs.loading.close()
          })
    },

    serialize(obj) {
      let str = []
      for (let p in obj) {
        if (obj.hasOwnProperty(p)) {
          if (typeof obj[p] !== 'undefined' && obj[p] !== null && obj[p] !== '') {
            str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]))
          }
        }
      }
      return str.join('&')
    },

    changePassword(e) {
      e.preventDefault()
      this.$store.dispatch(this.AUTH_STORE_MODULE_NAME + '/changePassword', this.passwordForm)
          .then(() => {
            this.$bvModal.hide('modal-change-password-form')
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Գաղտնաբառը հաջողությամբ փոփոխված է։',
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            })
          })
          .catch((error) => {
            this.$root.$emit('getError', error)
          })
    },

    changeCompany() {
      this.$store.dispatch(this.COMPANIES_STORE_MODULE_NAME + '/editCompanyData', this.companyForm)
    },

    isDocOpen(doc) {
      return new Promise((resolve, reject) => {
        if (this.docs[doc]) {
          this.$swal({
            title: 'Փակե՞լ արատորոշման քարտը',
            text: '',
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: 'Այո',
            cancelButtonText: 'Ոչ',
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-danger ml-1',
            },
            buttonsStyling: false,
          })
              .then(result => {
                if (result.value) {
                  this.$store.commit(this.TECH_SERVICE_STORE_MODULE_NAME + '/CLOSE_DOC', doc)
                  resolve(true)
                } else {
                  reject({ status: false })
                }
              })
        } else {
          resolve(true)
        }
      })
    },

    updateTaxRange() {
      let dates = this.taxRanges
      dates = dates.split(' - ')
      let startDate = dates[0]
      let endDate = dates[1]
      this.taxForm = {
        startDay: this.$options.filters.formatDayInput(startDate),
        endDay: this.$options.filters.formatDayInput(endDate),
      }

      this.getCheckupsForTax()
    },

    updateCheckupRange() {
      let dates = this.checkupRanges
      dates = dates.split(' - ')
      let startDate = dates[0]
      let endDate = dates[1]
      this.rangesForm = {
        startDay: this.$options.filters.formatDayInput(startDate),
        endDay: this.$options.filters.formatDayInput(endDate),
      }

      this.getCheckupsForTax()
    },

    getCheckupsForTax() {
      let dateTimeStart = new Date(this.taxForm.startDay)
      let dateTimeEnd = new Date(this.taxForm.endDay)
      dateTimeStart.setHours(0, 0, 0, 0)
      dateTimeEnd.setHours(23, 59, 59, 59)

      let payload = `startDate=${dateTimeStart}&endDate=${dateTimeEnd}`
      this.$store.dispatch(this.TECH_SERVICE_STORE_MODULE_NAME + '/getTaxCheckups', payload)
          .then(response => {
            this.taxData = response.data.data
          })
    },

    async exportTaxInvoice() {

      const taxConvertedData = []
      let index = 1
      for await (let item of this.taxData) {
        let temp = {
          '_attributes': {
            'Index': index,
          },
          CheckupDate: this.$options.filters.formatDayInput(item.createdAt),
          Model: item.car + ' ' + item.car_model,
          PoliceRegNumber: item.CarRegNo,
          ReleaseDate: item.MadeAt,
          TaxpayerName: item.ownerStatus.Owner,
          PaymentChequeNumber: item.CheckNo,
          PaymentChequeDate: this.$options.filters.formatDayInput(item.createdAt),
          PaidSum: item.Sum
        }
        taxConvertedData.push(temp)
        index++
      }

      const jsonData = {
        SignedData: {
          '_attributes': {
            'Version': '1.0',
          },
          Data: {
            Declaration: {
              '_attributes': {
                'Version': '1.0',
              },
              Header: {
                Sender: {
                  Tin: this.company.tax
                },
                Type: 'D',
                'Period year="2021" type="monthly" value="04"': '',
              },
              Body: {
                Section0: {
                  Record: {
                    TechCheckupStationName: this.company.name
                  }
                }
              },
              Section1: {
                Record: taxConvertedData
              }
            }
          }
        }
      }
      const options = {
        compact: true,
        attributeValueFn: function (value) {
          return value.replace(/</g, '&lt;')
              .replace(/>/g, '&gt;')
        }
      }

      const result = convert.js2xml(jsonData, options)
      const xml = '<?xml version="1.0" encoding="utf-8"?>' + result

      const file = new Blob([xml],
          {
            type: 'application/xml;charset=UTF-8'
          })
      const reader = new FileReader()
      reader.onload = () => {
        const popup = window.open()
        const link = document.createElement('a')
        link.setAttribute('href', reader.result)
        link.setAttribute('download', this.company.name + '.xml')
        popup.document.body.appendChild(link)
        link.click()
        popup.close()
      }
      reader.readAsDataURL(file)
    },

    openDateRangeModal() {
      this.$bvModal.show('modal-date-ranges')
    },

    async exportCheckups(){
      let dateTimeStart = new Date(this.rangesForm.startDay)
      let dateTimeEnd = new Date(this.rangesForm.endDay)
      dateTimeStart.setHours(0, 0, 0, 0)
      dateTimeEnd.setHours(23, 59, 59, 59)

      let payload = `startDate=${dateTimeStart}&endDate=${dateTimeEnd}`
      const response = await axios.get(`get-checkups-for-export?${payload}`);
      return response.data.data;
    },
    startDownload(){
      this.$vs.loading()
    },
    finishDownload(){
      this.$vs.loading.close()
    },

    downloadExcel () {
      this.$vs.loading()
      let dateTimeStart = new Date(this.rangesForm.startDay)
      let dateTimeEnd = new Date(this.rangesForm.endDay)
      dateTimeStart.setHours(0, 0, 0, 0)
      dateTimeEnd.setHours(23, 59, 59, 59)

      this.$store.commit(this.TECH_SERVICE_STORE_MODULE_NAME + '/UPDATE_DATE_RANGES', {
        startDate: dateTimeStart,
        endDate: dateTimeEnd
      })
      this.$vs.loading()
      const filters = JSON.parse(JSON.stringify(this.filters))
      const { fields } = filters
      delete filters.fields
      filters.columnFilter = JSON.stringify(fields)
      const filterData = {
        current_page: this.pagination.current_page,
        ...filters
      }

      this.$store.dispatch(this.TECH_SERVICE_STORE_MODULE_NAME + '/getCheckupsForExport', this.serialize(filterData))
      .then((doc) => {
        saveAs(doc, 'generatedDocument.pdf')
        console.log(response)
        this.$vs.loading.close()
       // this.exportExcel()
        //this.exportPDF()
      })
      .catch((error) => {
        this.$vs.loading.close()
        this.$root.$emit('getError', error)
      })
    },

    downloadAdminExcel () {

      this.$vs.loading()
      this.dateRange.startDate.setHours(0, 0, 0, 0)
      this.dateRange.endDate.setHours(23, 59, 59, 59)
      this.$store.commit(this.TECH_SERVICE_STORE_MODULE_NAME + '/UPDATE_DATE_RANGES', this.dateRange)
      this.$vs.loading()
      const filters = JSON.parse(JSON.stringify(this.filters))
      const { fields } = filters
      delete filters.fields
      filters.columnFilter = JSON.stringify(fields)
      const filterData = {
        current_page: this.pagination.current_page,
        ...filters
      }
      this.$store.dispatch(this.TECH_SERVICE_STORE_MODULE_NAME + '/getCheckupsForExportAdmin', this.serialize(filterData))
      .then(() => {
        this.$vs.loading.close()
        this.exportExcel()
      })
      .catch((error) => {
        this.$vs.loading.close()
        this.$root.$emit('getError', error)
      })
    },


    exportExcel() {
      TableToExcel.convert(document.getElementById("exportTable"), {
        name: 'Տեխզննում.xlsx',
        sheet: {
          name: "Տեխզննում"
        }
      });
    },

    exportPDF() {

      const col = ["№", "ՏՄ տնօրինող", "Մակնիշ", "Մոդել", "Մոդել", "Կտրոնի համար", "Տեխզննման օր"];

      const table = document.createElement('table')
      table.setAttribute('id', 'exportTable')

      const head = document.createElement('thead')
      const headTr = document.createElement('tr')
      col.forEach((item) => {
        let td = document.createElement('th')
        td.innerText = item
        headTr.append(td)
      })
      head.append(headTr)
      table.append(head)

      const tbody = document.createElement('tbody')
      this.export_checkups.forEach((element, index) => {

        let tr = document.createElement('tr')

        let td0 = document.createElement('td')
        td0.innerText = index + 1
        tr.append(td0)

        let td1 = document.createElement('td')
        td1.innerText = element.ownerStatus.Owner
        tr.append(td1)

        let td2 = document.createElement('td')
        td2.innerText = element.car
        tr.append(td2)

        let td3 = document.createElement('td')
        td3.innerText = element.car_model
        tr.append(td3)

        let td4 = document.createElement('td')
        td4.innerText = element.CarRegNo
        tr.append(td4)

        let td5 = document.createElement('td')
        td5.innerText = element.CheckNo
        tr.append(td5)

        let td6 = document.createElement('td')
        td6.innerText = this.$options.filters.formatDay(element.createdAt)
        tr.append(td6)

        tbody.append(tr)
      });
      table.append(tbody)
      this.$vs.loading()
      html2pdf(table, {
        mode: ['css', 'legacy'],
        margin: 0.3,
        filename: 'document.pdf',
        image: { type: 'jpeg', quality: 1 },
        html2canvas:  { scale: 2, dpi: 96, letterRendering: true, useCORS: true },
        jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
      })
      .then(() => {
        this.$vs.loading.close()
      })
      .catch(() => {
        alert('Error:')
        this.$vs.loading.close()
      })
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.dropdownBold {
  font-weight: bold;
  color: #000;

  a {
    font-weight: bold;
    color: #000;
  }
}


.active {
  .dropdown-item {
    background-color: #252c38;
    color: #fff;
  }
}

.daterangepicker {
  .ranges {
    li {
      font-weight: bold;
      color: #000;
    }
  }
}

.header-tools {


  .vue-daterange-picker {
    width: auto;
    margin-bottom: 0;
    min-width: 0px;

    .form-control {
      border: 0;
      padding: 0;
      margin: 0;
      line-height: 0;
      height: auto;
    }
  }

  .dropdown-toggle {
    padding: 0;

    &::after {
      display: none;
    }

    &:hover, &:active, &:focus {
      background: none !important;
    }
  }
}

.header-icon {
  color: #000;
  font-size: 2rem;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  margin: 0 8px;

  &.searchIcon {
    font-size: 2.5rem;
    position: relative;
    top: -3px;
  }

  &.calendarIcon {
    font-size: 1.7rem;
  }
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgb(93, 186, 47);
  }

  100% {
    box-shadow: 0 0 0 7px rgba(239, 239, 239, 0.01);
  }
}

@keyframes pulse-animation-warning {
  0% {
    box-shadow: 0 0 0 0px rgb(255, 159, 67);
  }

  100% {
    box-shadow: 0 0 0 7px rgba(239, 239, 239, 0.01);
  }
}

@keyframes pulse-animation-danger {
  0% {
    box-shadow: 0 0 0 0px rgb(201, 48, 44);
  }

  100% {
    box-shadow: 0 0 0 7px rgba(239, 239, 239, 0.01);
  }
}

.statusIcon {
  height: 30px;
  position: relative;
  float: left;
  left: 3px;
  top: 2px;
  background-image: linear-gradient(#B6B6B6 70%, rgba(255, 255, 255, 0) 0%);
  background-position: left;
  background-size: 2px 6px;
  background-repeat: repeat-y;
  width: 5px;
  margin-right: 5px;

  &.success {
    &:before {
      animation: pulse-animation 2s infinite;
      background-color: #5dba2f;
    }
  }

  &.danger {
    &:before {
      animation: pulse-animation-danger 2s infinite;
      background-color: #c9302c;
    }
  }

  &:before {
    content: "";

    height: 10px;
    width: 10px;
    border-radius: 50% !important;
    display: block;
    position: absolute;
    left: -4px;
    top: 0;
  }

  //&:after {
  //  content: "";
  //  height: 6px;
  //  width: 6px;
  //  border-radius: 50%;
  //  display: block;
  //  background-color: #D5D5D5;
  //  position: absolute;
  //  left: -2px;
  //  bottom: 0px;
  //}
}


.toolbarSearchButton {
  position: relative;

  svg {
    position: absolute;
    top: 6px;
  }

  span {
    margin-left: 1.4rem;
  }
}

#modal-search, #modal-payment-search, #view-modal-search {

  .search-modal-header {
    border-bottom: 1px solid #d8d6de;

    .searchSubmitButton {
      padding: 1rem;
      border-color: #d8d6de !important;
      width: 100%;
    }
  }

  .search-modal-body {
    min-height: 400px;
  }
}

.vue-daterange-picker .show-ranges.hide-calendars {
  width: 250px !important;
  min-width: 250px !important;
}

.daterangepicker {
  &::before, &::after {
    display: none;
  }
}


.dark-layout {
  .dropdownBold {
    color: #fff;

    a {
      color: #fff;
    }
  }

  .header-icon, .faNavbarIcon {
    color: #b4b7bd;
  }

  .daterangepicker {
    .ranges {
      li {
        color: #fff;
      }
    }
  }
}

.taxRangeDates {
  display: flex;
  justify-content: space-between;

  .taxRangeDateItem {
    width: 49%;
  }


}

#exportTable {
  width: 100%;
  td, th {
    border-top: 1px solid #000;
    border-left: 1px solid #000;
    font-size: 10px;
    color: #000;
    text-align: center;
    padding: 6px 0;

    &:last-child {
      border-right: 1px solid #000;
    }
  }
  tr:last-child {
    td {
      border-bottom: 1px solid #000;
    }
  }
}
.viewerCalendarIcon {
  position: relative;
  top: 2px;
}
.warnings-statuses {
  display: flex;
  margin-ritgh: 5px;
  .warning-status-item {
    width: 15px;
    height: 15px;
    background: #fff;
    border-radius: 50%;
    margin: 0 5px;
    border: 1px solid #ccc;
    &.warning {
      background: #ff9f43;
      border: 0;
      animation: pulse-animation-warning 2s infinite;
    }
    &.danger {
      background: #ea5455;
      border: 0;
      animation: pulse-animation-danger 2s infinite;
    }
  }
}
.viewSearchIcon {
  margin-top: 3px!important;
}
.search-modal-header {
  display: flex;

  .search-header-column {
    margin-right: 10px;

    &:nth-child(1) {
      min-width: 265px;
    }
  }
}
.filters {
  opacity: 1;

  .daterangepicker {
    position: fixed;
    bottom: 67px;
    left: 21% !important;
  }

  .vue-daterange-picker .show-ranges.hide-calendars {
    width: 250px !important;
    min-width: 250px !important;
  }
}
.modal-title {
  color: #000;
  font-weight: bold;
}
.search-info {
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    color: #03a9f4;
    margin-right: 0.5rem;
    font-size: 19px;
  }
  span {
    color: #000;
  }
}
.tooltipButton {
  background: none !important;
  border: none !important;
  padding: 0 !important;
  margin: 0 !important;
  height: auto !important;
  top: -2px;
  font-size: 13px;
  left: 2px;
}

[dir] .tooltip.b-tooltip-dark .tooltip-inner {
  font-weight: bold;
  font-size: 14px;
}
.payment-services {
  display: flex;
  .service {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 10px;
    cursor: pointer;
    svg {
      font-size: 35px;
    }
    .name {
      font-size: 10px;
    }
  }
}
#receipt-modal {

  .modal-header {
    display: none;
  }
  .receipt-close {
    cursor: pointer;
    font-size: 36px;
    position: absolute;
    right: 20px;
    color: #000;
  }
  opacity: 1;
  .receipt-header {
    display: flex;
    flex-direction: column;
    border-bottom: 2px solid #000;
    padding-bottom: 5px;
    .bank-logo {
      margin: 20px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 150px;
      }
    }
  }
  .receipt-body {
    padding: 20px 0;
    font-weight: bold;
  }
  .receipt-station {
    border-top: 1px dashed #000;
    padding: 15px 0;
    font-weight: bold;
  }
  .receipt-service {
    border-top: 1px dashed #000;
    padding: 10px 0;
    font-weight: bold;
  }
  .receipt-total {
    border-top: 2px solid #000;
    padding-top: 5px;
  }
  .receipt-footer {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    .print {
      cursor: pointer;
      font-size: 14px;
      color: #000;
    }
  }
  .receipt-column {
    color: #000;
    font-size: 11px;
    display: flex;
    justify-content: space-between;
    .info {
      opacity: 1;
    }
  }
}
.can-make-checkup {
  width: 100%;
  border: 1px solid #eee;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 0;
  color: #fff;
  &.can-make-checkup-1 {
    background: var(--success);
  }
  &.can-make-checkup-0 {
    background: var(--danger);
    cursor: pointer;
  }
}
.taxes-info {
  .tax-info {
    margin-top: 10px;
    .persons {
      .person {
        .personInfo {
          color: var(--danger);
          font-weight: bold;
          text-align: center;
          &.success {
            color: var(--success);
          }
        }
        .taxes {
          width: 100%;
          margin-top: 10px;
          .tax {
            &.tax-header {
              opacity: 1;
            }
            .column {
              border: 1px solid #ccc;
              color: #000;
              font-weight: bold;
              text-align: center;
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}
.search-modal-body {
  th, td {
    text-align: center;
  }
}
#modal-taxes-info {
  .modal-header {
    .modal-title {
      text-align: center;
      width: 100%;
    }
  }
}
.swal2-content {
  font-size: 1rem!important;
}
.block-status {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  cursor: pointer;
  margin: 0 5px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  &.yellow-status {
    background: #ff8e00;
  }
  &.red-status {
    background: #ea5455;
  }
  &.blue-status {
    background: #3480ff;
    color: #fff;
    font-weight: bold;
  }
  &.orange-status {
    background: #f5a910;
    color: #fff;
    font-weight: bold;
  }
  &.statusTRUE {
    &:after {
      content: "✅";
      color: #fff;
      position: absolute;
      font-size: 11px;
      top: 3px;
      left: 5px;
    }
  }
}
.viweBlock {
  opacity: 1;
}
.isViewerBlocked {
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  left: 0;
  top: 0;
  z-index: 9999999999;
  color: #000;
  font-size: 17px;
  font-weight: bold;    text-align: center;
  padding: 0 30%;
}
body.viweBlock {
  #tech-service-sidebar {
    display: none;
  }
}
@media (max-width: 768px) {
  .calendarIcon {
    position: relative;
    top: -3px;
  }
}
.maxWidth250 {
  max-width: 250px;
  white-space: normal!important;
}
#modal-edit-history {
  .table-responsive {
    max-height: calc(var(--vh, 1vh) * 100 - 20rem);
    min-height: calc(var(--vh, 1vh) * 100 - 20rem);
  }
}
</style>
