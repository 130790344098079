<template>
  <b-card-code v-if="$checkPermission($options.name, 'view')" id="companies" no-body>

    <div class="page-header">
      <b-row>
        <b-col v-if="$checkPermission($options.name, 'insert')" lg="2">
          <b-button @click="add" v-b-modal.modal-form variant="primary">Ավելացնել</b-button>
        </b-col>
      </b-row>
    </div>

    <b-table
        striped
        hover
        responsive
        class="position-relative"
        :per-page="perPage"
        :current-page="currentPage"
        :items="items"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
    >
      <template #cell(address)="data">
        {{ addressName(data.item) }}
      </template>

      <template #cell(subscriptionColumn)="data">
        <fa-icon @click="openSubscriptionModal(data.item)"
                 icon="dollar-sign"
                 :class="'table-action-icon item-status status-' + data.item.subscriptionEnabled"/>
        <fa-icon icon="check-circle"
                 :class="'table-action-icon item-status status-' + data.item.subscriptionStatus"/>
      </template>

      <template #cell(contractColumn)="data">
        <fa-icon @click="openContractModal(data.item)"
                 icon="file-alt"
                 :class="'table-action-icon item-status status-' + data.item.contractEnabled"/>
        <fa-icon icon="check-circle" @click="enableContract(data.item)"
                 :class="'table-action-icon item-status status-' + data.item.contractStatus"/>
      </template>

      <template #cell(status)="data">
<!--        <fa-icon v-if="$checkPermission($options.name, 'update')" @click="blockSearch(data.item)"-->
<!--                 icon="search-minus"-->
<!--                 :class="'table-action-icon item-status status-' + data.item.searchBlocked"/>-->
<!--        <fa-icon v-if="$checkPermission($options.name, 'update')" @click="addWarning(data.item)"-->
<!--                 icon="exclamation-triangle"-->
<!--                 :class="'table-action-icon item-status status-' + data.item.warned"/>-->
<!--&lt;!&ndash;        <fa-icon v-if="$checkPermission($options.name, 'update')" @click="banCompany(data.item)" icon="ban"&ndash;&gt;-->
<!--&lt;!&ndash;                 :class="'table-action-icon item-status status-' + data.item.blocked"/>&ndash;&gt;-->

<!--        <fa-icon v-if="$checkPermission($options.name, 'update')" @click="banSearch(data.item)" icon="ban"-->
<!--                 :class="'table-action-icon item-status status-' + data.item.searchBanned"/>-->

<!--        <fa-icon v-if="$checkPermission($options.name, 'update')" @click="alertCompany(data.item)" icon="exclamation-triangle"-->
<!--                 :class="'table-action-icon item-status status-' + data.item.alert"/>-->

        <fa-icon v-if="$checkPermission($options.name, 'update')" @click="toggleVazq(data.item)" icon="sort-numeric-down"
                 :class="'table-action-icon item-status status-' + data.item.vazq"/>

        <fa-icon v-if="$checkPermission($options.name, 'update')" @click="toggleAttachment(data.item)" icon="file-alt"
                 :class="'table-action-icon item-status status-' + data.item.attachment"/>

        <fa-icon v-if="$checkPermission($options.name, 'update')" @click="toggleCamera(data.item)" icon="camera"
                 :class="'table-action-icon item-status status-' + data.item.camera"/>
      </template>

      <template #cell(actions)="data">
        <fa-icon v-if="$checkPermission($options.name, 'update')" @click="changeStatus(data.item)"
                 icon="home"
                 :class="'table-action-icon item-status status-' + data.item.status"/>
        <fa-icon v-if="$checkPermission($options.name, 'update')" @click="edit(data.item)" icon="edit"
                 class="table-action-icon accent"/>
        <fa-icon v-if="$checkPermission($options.name, 'delete')" @click="deleteItem(data.item)" icon="trash-alt"
                 class="table-action-icon danger"/>
      </template>

      <template #cell(company_details)="data">
        <fa-icon v-if="data.item.new" icon="folder-plus"
                 class="table-action-icon item-status status-true"/>
        <fa-icon :class="company_details_icon(data.item)" icon="check-circle" />
      </template>
    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

      <!-- page length -->
      <b-form-group
          :label="'Գտնվել է ' + totalRows + ' տող'"
          label-cols="3"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1"
      >
      </b-form-group>

      <!-- pagination -->
      <div>
        <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
        >
          <template #prev-text>
            <feather-icon
                icon="ChevronLeftIcon"
                size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
                icon="ChevronRightIcon"
                size="18"
            />
          </template>
        </b-pagination>
      </div>
    </b-card-body>

    <!-- modal login-->
    <b-modal
        id="modal-form"
        :no-close-on-backdrop="true"
        cancel-variant="outline-secondary"
        :ok-title="modal_title"
        cancel-title="Կասեցնել"
        centered
        size="lg"
        :title="modal_title"
        :is-valid="false"
        @ok="confirmForm"
        :ok-disabled="validateForm"
    >


      <b-form>

        <b-row>

          <b-col lg="4">
            <div class="column-title">Կազմակերպություն</div>

            <b-form-group>
              <b-form-input
                  v-model="form.name"
                  id="name"
                  type="text"
                  placeholder="Կազմակերպության անվանում"
              />
            </b-form-group>
            <b-form-group>
              <b-form-input
                  v-model="form.boss"
                  id="boss"
                  type="text"
                  placeholder="Տնօրեն"
              />
            </b-form-group>
            <b-form-group class="licenseContainer">
              <b-form-input
                  v-model="form.license"
                  id="license"
                  type="text"
                  placeholder="Լիցենզիա"
              />
              <fa-icon class="licenseIcon" v-if="form.licenseFile" @click="openLicenseFile" icon="paperclip" />
            </b-form-group>
            <b-form-group>
              <b-form-input
                  v-model="form.standCount"
                  id="standCount"
                  type="text"
                  placeholder="Ստենդերի քանակ"
              />
            </b-form-group>
            <b-form-group>
              <b-form-input
                  v-model="form.phone"
                  id="phoneNumber"
                  type="text"
                  placeholder="Հեռախոսահամար"
              />
            </b-form-group>
            <b-form-group>
              <b-form-checkbox
                  v-model="form.station_1"
                  name="check-button"
                  class="custom-control-success"
                  switch
                  inline
              >
                Մարդատար
              </b-form-checkbox>
            </b-form-group>

          </b-col>

          <b-col lg="4">
            <div class="column-title">Ռեկվիզիտներ</div>

            <b-form-group>
              <b-form-input
                  v-model="form.reqNo"
                  id="reqNo"
                  type="text"
                  placeholder="reqNo"
              />
            </b-form-group>
            <b-form-group>
              <b-form-input
                  v-model="form.PRReqNo"
                  id="PRReqNo"
                  type="text"
                  placeholder="PRReqNo"
              />
            </b-form-group>
            <b-form-group>
              <b-form-input
                  v-model="form.tax"
                  id="tax"
                  type="text"
                  placeholder="ՀՎՀՀ"
              />
            </b-form-group>
            <b-form-group>
              <b-form-input
                  v-model="form.taxID"
                  id="taxID"
                  type="text"
                  placeholder="taxID"
              />
            </b-form-group>
            <b-form-group>
              <b-form-input
                  v-model="form.bank"
                  id="bank"
                  type="text"
                  placeholder="Բանկային հաշվեհամար"
              />
            </b-form-group>

            <b-form-group>
              <b-form-checkbox
                  v-model="form.station_2"
                  name="check-button"
                  class="custom-control-success"
                  switch
                  inline
              >
                Բեռնատար
              </b-form-checkbox>
            </b-form-group>



          </b-col>

          <b-col lg="4">

            <div class="column-title">Հասցե</div>

            <b-form-group>
              <v-select
                  @input="changeFormRegion"
                  placeholder="Մարզ"
                  id="vue-select-region"
                  :reduce="prefix => prefix.id"
                  v-model="locationData.region"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="regions"
              />
            </b-form-group>

            <b-form-group v-if="locationData.region !== null">
              <v-select
                  placeholder="Համայնք"
                  id="vue-select-county"
                  :reduce="prefix => prefix.id"
                  v-model="locationData.county"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="counties"
              />
            </b-form-group>

            <b-form-group v-if="locationData.county !== '' && locationData.county !== null">
              <b-form-input
                  placeholder="Փողոց"
                  id="vue-select-street"
                  v-model="locationData.street"
              />
            </b-form-group>

            <b-form-group v-if="locationData.street !== '' && locationData.street !== null">
              <b-form-input
                  placeholder="Փողոց"
                  id="vue-select-address"
                  v-model="locationData.address"
              />
            </b-form-group>

            <template v-if="companyCoords.length === 2">

              <b-form-group>
                <b-form-input
                    placeholder="Երկարություն"
                    id="vue-select-latitude"
                    v-model="companyCoords[0]"
                />
              </b-form-group>

              <b-form-group>
                <b-form-input
                    placeholder="Լայնություն"
                    id="vue-select-longitude"
                    v-model="companyCoords[1]"
                />
              </b-form-group>

            </template>

          </b-col>
        </b-row>

        <b-row>
          <b-col lg="12">
            <div id="map">
              <yandex-map @click="changeMarkerPosition" zoom="13" class="company-map" :coords="companyCoords">
                <ymap-marker
                    marker-id="123"
                    :coords="companyCoords"
                />
              </yandex-map>
            </div>

          </b-col>
        </b-row>


      </b-form>
    </b-modal>

    <b-modal
        id="modal-warning"
        :no-close-on-backdrop="true"
        cancel-variant="outline-secondary"
        ok-title="Զգուշացնել"
        cancel-title="Կասեցնել"
        centered
        size="sm"
        title="Զգուշացում"
        :is-valid="false"
        @ok="confirmWarning"
        :ok-disabled="validateWarningForm"
    >
      <b-form>
        <b-form-group>
          <b-form-textarea
              v-model="warningText"
              id="name"
              type="text"
              placeholder="Ծանուցում"
          />
        </b-form-group>
      </b-form>
    </b-modal>

    <b-modal
        id="modal-ban"
        :no-close-on-backdrop="true"
        cancel-variant="outline-secondary"
        ok-title="Բլոկավորել"
        cancel-title="Կասեցնել"
        centered
        size="sm"
        title="Բլոկավորում"
        :is-valid="false"
        @ok="confirmBan"
        :ok-disabled="validateBanForm"
    >
      <b-form>
        <b-form-group>
          <b-form-textarea
              v-model="banText"
              id="ban-name"
              type="text"
              placeholder="Ծանուցում"
          />
        </b-form-group>
      </b-form>
    </b-modal>

    <b-modal
        id="subscription-form"
        :no-close-on-backdrop="true"
        cancel-variant="outline-secondary"
        ok-title="Հաստատել"
        cancel-title="Կասեցնել"
        centered
        size="sm"
        title="Տարեկան բաժանորդագրություն"
        :is-valid="false"
        @ok="confirmSubscription"
    >
      <b-form>
        <b-form-group>
          <b-form-group>
            <b-form-checkbox
                v-model="subscriptionForm.subscriptionEnabled"
                name="check-button"
                class="custom-control-success"
                switch
                inline
            >
              Տարեկան բաժանորդագրություն
            </b-form-checkbox>
          </b-form-group>

          <b-form-group>
            <b-form-input
                :disabled="!subscriptionForm.subscriptionEnabled"
                placeholder="Տարեկան վճար"
                id="vue-select-total"
                v-model="subscriptionForm.subscriptionTotal"
                type="number"
            />
          </b-form-group>

          <b-form-group>
            <b-form-input
                :disabled="!subscriptionForm.subscriptionEnabled"
                placeholder="օր"
                id="vue-select-total"
                v-model="subscriptionForm.subscriptionDate"
                type="date"
            />
          </b-form-group>

        </b-form-group>
      </b-form>
    </b-modal>

    <b-modal
        id="contract-form"
        :no-close-on-backdrop="true"
        cancel-variant="outline-secondary"
        ok-title="Հաստատել"
        cancel-title="Կասեցնել"
        centered
        size="sm"
        title="Տարեկան բաժանորդագրություն"
        :is-valid="false"
        @ok="confirmContract"
    >
      <b-form>
        <b-form-group>
          <b-form-group>
            <b-form-checkbox
                v-model="contractForm.contractEnabled"
                name="check-button"
                class="custom-control-success"
                switch
                inline
            >
              Պայմանագրի թարմացում
            </b-form-checkbox>
          </b-form-group>

          <b-form-group>
            <input
                class="form-control"
                :disabled="!contractForm.contractEnabled"
                placeholder="Ֆայլ"
                @change="updateContractFile"
                type="file"
            />
          </b-form-group>

          <b-form-group>
            <b-form-input
                :disabled="!contractForm.contractEnabled"
                placeholder="օր"
                id="vue-select-total"
                v-model="contractForm.contractDate"
                type="date"
            />
          </b-form-group>

        </b-form-group>
      </b-form>
    </b-modal>

  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import companyModule from '@/store/companies/companyModule'
import locations from '@/store/locations/locations'
import vSelect from 'vue-select'
import { yandexMap, ymapMarker } from 'vue-yandex-maps'

const settings = {
  apiKey: '',
  lang: 'ru_RU',
  coordorder: '40.1535005,44.4185275',
  version: '2.1'
}

import {
  BAvatar,
  BBadge,
  BButton,
  BCardBody,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BPagination,
  BRow,
  BTable,
  BFormTextarea,
    BFormCheckbox,
} from 'bootstrap-vue'
import store from '@/store'

export default {
  name: 'companies',
  components: {
    BCardCode,
    BTable,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    BForm,
    BRow,
    BCol,
    BFormTextarea,
    vSelect,
    yandexMap,
    ymapMarker,
    BFormCheckbox
  },
  data() {
    return {
      COMPANIES_STORE_MODULE_NAME: 'companiesModule',
      LOCATIONS_STORE_MODULE_NAME: 'locationsModule',
      perPage: 20,
      pageOptions: [this.perPage],
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      warningText: '',
      banText: '',
      locationData: {
        region: null,
        county: null,
        street: '',
        address: ''
      },
      fields: [
        {
          key: '_id',
          label: 'ID',
        },
        // {
        //   key: 'subscriptionColumn',
        //   label: '$',
        // },
        // {
        //   key: 'contractColumn',
        //   label: '📁',
        // },
        {
          key: 'status',
          label: 'Կարգավիճակ',
        },
        {
          key: 'name',
          label: 'Անվանում',
        },
        {
          key: 'address',
          label: 'Հասցե',
        },
        {
          key: 'actions',
          label: 'Գործողություններ',
          class: 'text-center width-10-per px-0',
        },
        {
          key: 'company_details',
          label: 'Ռեկվիզիտներ',
          class: 'text-center',
        },
        {
          key: 'reqNo'
        },
        {
          key: 'PRReqNo'
        },
        {
          key: 'boss',
          label: 'Տնօրեն'
        },
        {
          key: 'ՀՎՀՀ'
        },
        {
          key: 'taxID'
        },
        {
          key: 'standCount'
        },
        {
          key: 'license',
          label: 'Լիցենզիա'
        }
      ],
      addForm: false,
      editForm: false,
      formFields: {
        name: '',
        address: null,
        reqNo: '',
        PRReqNo: '',
        boss: '',
        tax: '',
        taxID: '',
        standCount: '',
        license: '',
        longitude: '',
        latitude: '',
        phone: '',
        bank: '',
        station_1: false,
        station_2: false,
        subscription: false,
        subscriptionTotal: null,
      },
      form: {},
      selectedItem: null,
      coords: [40.1535005, 44.4185275],
      companyCoords: [],
      subscriptionStatus: false,
      subscriptionForm: {
        id: null,
        subscriptionEnabled: false,
        subscriptionDate: null,
        subscriptionTotal: null,
      },
      contractForm: {
        id: null,
        contractEnabled: false,
        contractDate: null,
        file: null,
      },
    }
  },
  computed: {

    company_details_icon () {
      return (company) => {
        let status = (company.tax && company.bank && company.tax && company.boss) ? 'true' : 'false'
        return `table-action-icon item-status status-${status}`
      }
    },

    items() {
      return this.$store.state[this.COMPANIES_STORE_MODULE_NAME].companies
    },

    all_addresses() {
      return this.$store.state[this.LOCATIONS_STORE_MODULE_NAME].all_addresses
    },

    validateWarningForm() {
      return this.warningText === ''
    },

    validateBanForm() {
      return this.banText === ''
    },

    addresses() {
      return this.$store.state[this.LOCATIONS_STORE_MODULE_NAME].addresses.map(e => ({
        ...e,
        label: e.name,
        id: e._id
      }))
    },

    counties() {
      return this.$store.state[this.LOCATIONS_STORE_MODULE_NAME].counties.map(e => ({
        label: e.name,
        id: e.id, ...e
      }))
    },

    all_counties() {
      return this.$store.state[this.LOCATIONS_STORE_MODULE_NAME].all_counties
    },

    all_streets() {
      return this.$store.state[this.LOCATIONS_STORE_MODULE_NAME].all_streets
    },

    streets() {
      return this.$store.state[this.LOCATIONS_STORE_MODULE_NAME].streets.map(e => ({
        ...e,
        label: e.name,
        id: e._id,
      }))
    },

    regions() {
      return this.$store.state[this.LOCATIONS_STORE_MODULE_NAME].regions.map(e => ({
        label: e.name,
        id: e.id, ...e
      }))
    },

    all_regions() {
      return this.$store.state[this.LOCATIONS_STORE_MODULE_NAME].regions
    },

    totalRows() {
      return this.items.length
    },

    modal_title() {
      return (this.addForm) ? 'Ավելացնել' : 'Խմբագրել'
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
          .filter(f => f.sortable)
          .map(f => ({
            text: f.label,
            value: f.key
          }))
    },

    validateForm() {
      return this.form.name === '' ||
          this.form.boss === '' ||
          this.form.tax === '' ||
          this.locationData.county === null ||
          (this.form.subscription && !this.form.subscriptionTotal)
    },

    addressName() {
      return (item) => {
        return item.region + ' ' + item.county + ' ' + item.street + ' ' + item.address
      }
    }
  },
  mounted() {
    // Set the initial number of items

  },
  async created() {
    if (!store.hasModule(this.COMPANIES_STORE_MODULE_NAME)) store.registerModule(this.COMPANIES_STORE_MODULE_NAME, companyModule)
    if (!store.hasModule(this.LOCATIONS_STORE_MODULE_NAME)) store.registerModule(this.LOCATIONS_STORE_MODULE_NAME, locations)
    this.getCompanies()
    this.getRegions()
    this.getCounties()
  },
  methods: {

    openSubscriptionModal(company) {
      this.subscriptionForm = {
        id: company._id,
        subscriptionEnabled: company.subscriptionEnabled ?? false,
        subscriptionDate: company.subscriptionDate ?? null,
        subscriptionTotal: company.subscriptionTotal ?? null
      };
      this.$bvModal.show('subscription-form')
    },

    openContractModal(company) {
      this.contractForm = {
        id: company._id,
        contractEnabled: company.contractEnabled ?? false,
        contractDate: company.contractDate ?? null,
        contractFile: company.contractFile ?? null
      };
      this.$bvModal.show('contract-form')
    },

    confirmSubscription() {
      let form = {
        _id: this.subscriptionForm.id,
        subscriptionEnabled: this.subscriptionForm.subscriptionEnabled,
        subscriptionDate: this.subscriptionForm.subscriptionDate,
        subscriptionTotal: this.subscriptionForm.subscriptionTotal,
      }
      this.$store.dispatch(this.COMPANIES_STORE_MODULE_NAME + '/editCompany', form)
          .then(() => {
            this.subscriptionForm = {
              id: null,
              subscriptionEnabled: false,
              subscriptionDate: null,
              subscriptionTotal: null,
            };
          })
          .catch((error) => {
            this.$root.$emit('getError', error)
          })
    },

    updateContractFile(event) {
      this.contractForm.file = event.target.files[0]
    },

    confirmContract() {
      let form = {
        _id: this.contractForm.id,
        contractEnabled: this.contractForm.contractEnabled,
        contractDate: this.contractForm.contractDate,
      }
      if (this.contractForm.contractEnabled) {
        let formData = new FormData();
        formData.append('file', this.contractForm.file)
        this.$store.dispatch(this.COMPANIES_STORE_MODULE_NAME + '/editCompanyContract', {
          id: this.contractForm.id,
          form: formData
        }).then(response => {
          form.contractFile = response.data.file;
          this.$store.dispatch(this.COMPANIES_STORE_MODULE_NAME + '/editCompany', form)
              .then(() => {
                this.contractForm = {
                  id: null,
                  contractEnabled: false,
                  contractDate: null,
                  file: null,
                };
              }).catch(error => {
            this.$root.$emit('getError', error)
          })
        }).catch(error => {
          this.$root.$emit('getError', error)
        })
      } else {
        this.$store.dispatch(this.COMPANIES_STORE_MODULE_NAME + '/editCompany', form)
            .then(() => {
              this.contractForm = {
                id: null,
                contractEnabled: false,
                contractDate: null,
                file: null,
              };
            }).catch(error => {
          this.$root.$emit('getError', error)
        })
      }
    },

    getCompanies() {
      this.$store.dispatch(this.COMPANIES_STORE_MODULE_NAME + '/getCompanies')
    },
    getRegions() {
      this.$store.dispatch(this.LOCATIONS_STORE_MODULE_NAME + '/getRegions')
    },
    getCounties() {
      this.$store.dispatch(this.LOCATIONS_STORE_MODULE_NAME + '/getCounties')
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    add() {
      this.editForm = false
      this.addForm = true
      this.form = this.$store.getters['getForm'](true)(this.formFields)(null)
      this.companyCoords = this.coords
    },
    edit(item) {
      let regionItem
      let countyItem
      let regionIndex = this.regions.findIndex(r => r.name === item.region)
      if (regionIndex >= 0) {
        this.locationData.region = this.regions[regionIndex].id
        regionItem = this.regions[regionIndex]
      }

      let countyIndex = this.counties.findIndex(c => c.name === item.county)
      if (countyIndex >= 0) {
        this.locationData.county = this.counties[countyIndex].id
        countyItem = this.counties[countyIndex]
      }

      this.locationData.street = item.street
      this.locationData.address = item.address
      this.addForm = false
      this.editForm = true
      this.form = this.$store.getters['getForm'](false)(this.formFields)(item)
      if (this.form.latitude && this.form.longitude) {
        this.companyCoords = [this.form.latitude, this.form.longitude]
      }
      else {
        if (countyItem && countyItem.latitude && countyItem.longitude) {
          this.companyCoords = [countyItem.latitude, countyItem.longitude]
        }
        else {
          this.companyCoords = [regionItem.latitude, regionItem.longitude]
        }
      }
      this.subscriptionStatus = item.subscriptionStatus ?? false;
      this.$bvModal.show('modal-form')
    },
    deleteItem(item) {
      this.$swal({
        title: 'Հաստատե՞լ գործողությունը',
        text: 'Դուք ցանկանում եք հեռացնել կազմակերպությունը',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Հաստատել',
        cancelButtonText: 'Մերժել',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
          .then(result => {
            if (result.value) {
              this.$store.dispatch(this.COMPANIES_STORE_MODULE_NAME + '/deleteCompany', item._id)
                  .then(() => {
                    this.$swal({
                      icon: 'success',
                      title: 'Հեռացվել է!',
                      text: 'Կազմակերպությունը հաջողությամբ հեռացվեց',
                      customClass: {
                        confirmButton: 'btn btn-success',
                      },
                    })
                  })
                  .catch((error) => {
                    this.$root.$emit('getError', error)
                  })
            }
          })
    },
    changeStatus(item) {
      const status = !item.status
      const action = (status) ? 'ակտիվացնել' : 'պասիվացնել'
      this.$swal({
        title: 'Հաստատե՞լ գործողությունը',
        text: 'Դուք ցանկանում եք ' + action + ' կազմակերպությունը',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Հաստատել',
        cancelButtonText: 'Մերժել',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
          .then(result => {
            if (result.value) {
              item.status = status
              this.$store.dispatch(this.COMPANIES_STORE_MODULE_NAME + '/editCompany', item)
                  .then(() => {
                    this.$swal({
                      icon: 'success',
                      title: '',
                      text: 'Փոփոխությունները պահպանվեցին',
                      customClass: {
                        confirmButton: 'btn btn-success',
                      },
                    })
                  })
                  .catch((error) => {
                    this.$root.$emit('getError', error)
                  })
            }
          })
    },
    confirmForm() {
      if (this.addForm) {
        let regionName = ''
        let countyName = ''
        const regionIndex = this.regions.findIndex((r) => r.id === this.locationData.region)
        if (regionIndex >= 0) {
          regionName = this.regions[regionIndex].label
        }
        const countyIndex = this.counties.findIndex((c) => c.id === this.locationData.county)
        if (countyIndex >= 0) {
          countyName = this.counties[countyIndex].label
        }
        this.form.region = regionName
        this.form.county = countyName
        this.form.street = this.locationData.street
        this.form.address = this.locationData.address

        this.form.latitude = this.companyCoords[0]
        this.form.longitude = this.companyCoords[1]

        this.$store.dispatch(this.COMPANIES_STORE_MODULE_NAME + '/addCompany', this.form)
            .then(() => {
              this.locationData = {
                region: null,
                county: null,
                street: '',
                address: '',
              }
            })
            .catch((error) => {
              this.$root.$emit('getError', error)
            })
      } else {
        let regionName = ''
        let countyName = ''
        const regionIndex = this.regions.findIndex((r) => r.id === this.locationData.region)
        if (regionIndex >= 0) {
          regionName = this.regions[regionIndex].label
        }
        const countyIndex = this.counties.findIndex((c) => c.id === this.locationData.county)
        if (countyIndex >= 0) {
          countyName = this.counties[countyIndex].label
        }
        this.form.region = regionName
        this.form.county = countyName
        this.form.street = this.locationData.street
        this.form.address = this.locationData.address

        this.form.latitude = this.companyCoords[0]
        this.form.longitude = this.companyCoords[1]

        this.$store.dispatch(this.COMPANIES_STORE_MODULE_NAME + '/editCompany', this.form)
            .then(() => {
              this.locationData = {
                region: null,
                county: null,
                street: '',
                address: '',
              }
            })
            .catch((error) => {
              this.$root.$emit('getError', error)
            })
      }
    },
    changeFormRegion(regionId) {
      this.locationData.county = null
      this.locationData.street = null
      this.form.address = null
      this.$store.commit(this.LOCATIONS_STORE_MODULE_NAME + '/FILTER_COUNTIES_BY_REGION_ID', regionId)
    },
    changeFormCounty(countyId) {
      this.locationData.street = null
      this.form.address = null
      this.$store.dispatch(this.LOCATIONS_STORE_MODULE_NAME + '/getStreets', 'county=' + countyId)
    },
    changeFormStreet(streetId) {
      this.form.address = null
      this.$store.dispatch(this.LOCATIONS_STORE_MODULE_NAME + '/getAddresses', 'street=' + streetId)
    },
    addWarning(item) {
      if (item.warned) {
        this.$swal({
          title: 'Հաստատե՞լ գործողությունը',
          text: 'Դուք ցանկանում եք պասիցացնել զգուշացումը',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Հաստատել',
          cancelButtonText: 'Կասեցնել',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        })
            .then(result => {
              if (result.value) {
                const payload = {
                  _id: item._id,
                  warned: false,
                  warning: '',
                  warmDate: null
                }
                this.$store.dispatch(this.COMPANIES_STORE_MODULE_NAME + '/editCompany', payload)
                    .then(() => {
                      this.warningText = ''
                    })
              }
            })
      } else {
        this.$swal({
          title: 'Հաստատե՞լ գործողությունը',
          text: '',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Հաստատել',
          cancelButtonText: 'Կասեցնել',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        })
            .then(result => {
              if (result.value) {
                const payload = {
                  _id: item._id,
                  warned: true,
                  warning: '',
                  warmDate: new Date()
                }
                this.$store.dispatch(this.COMPANIES_STORE_MODULE_NAME + '/editCompany', payload)
              }
            })
      }
    },
    alertCompany(item) {
      if (item.alert) {
        this.$swal({
          title: 'Հաստատե՞լ գործողությունը',
          text: 'Դուք ցանկանում եք պասիցացնել զգուշացումը',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Հաստատել',
          cancelButtonText: 'Կասեցնել',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        })
            .then(result => {
              if (result.value) {
                const payload = {
                  _id: item._id,
                  alert: false
                }
                this.$store.dispatch(this.COMPANIES_STORE_MODULE_NAME + '/editCompany', payload)
                    .then(() => {
                      this.warningText = ''
                    })
              }
            })
      } else {
        this.$swal({
          title: 'Հաստատե՞լ գործողությունը',
          text: '',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Հաստատել',
          cancelButtonText: 'Կասեցնել',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        })
            .then(result => {
              if (result.value) {
                const payload = {
                  _id: item._id,
                  alert: true,
                }
                this.$store.dispatch(this.COMPANIES_STORE_MODULE_NAME + '/editCompany', payload)
              }
            })
      }
    },
    toggleVazq(item) {
      const payload = {
        _id: item._id,
        vazq: (item.vazq) ? false : true
      }
      this.$store.dispatch(this.COMPANIES_STORE_MODULE_NAME + '/editCompany', payload);
    },
    toggleAttachment(item) {
      const payload = {
        _id: item._id,
        attachment: (item.attachment) ? false : true
      }
      this.$store.dispatch(this.COMPANIES_STORE_MODULE_NAME + '/editCompany', payload)
    },
    toggleCamera(item) {
      const payload = {
        // eslint-disable-next-line no-underscore-dangle
        _id: item._id,
        camera: (!item.camera),
      }
      this.$store.dispatch(`${this.COMPANIES_STORE_MODULE_NAME}/editCompany`, payload)
    },
    blockSearch(item) {
      if (!item.searchBlocked) {
        this.$swal({
          title: 'Հաստատե՞լ գործողությունը',
          text: 'Դուք ցանկանում եք պասիցացնել որոնման հնարավորությունը',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Հաստատել',
          cancelButtonText: 'Կասեցնել',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        })
            .then(result => {
              if (result.value) {
                const payload = {
                  _id: item._id,
                  searchBlocked: true,
                }
                this.$store.dispatch(this.COMPANIES_STORE_MODULE_NAME + '/editCompany', payload)
                    .then(() => {
                      this.warningText = ''
                    })
              }
            })
      } else {
        this.$swal({
          title: 'Հաստատե՞լ գործողությունը',
          text: '',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Հաստատել',
          cancelButtonText: 'Կասեցնել',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        })
            .then(result => {
              if (result.value) {
                const payload = {
                  _id: item._id,
                  searchBlocked: false,
                }
                this.$store.dispatch(this.COMPANIES_STORE_MODULE_NAME + '/editCompany', payload)
              }
            })
      }
    },
    enableContract(item) {
      if (!item.contractStatus) {
        this.$swal({
          title: 'Հաստատե՞լ գործողությունը',
          text: '',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Հաստատել',
          cancelButtonText: 'Կասեցնել',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        })
            .then(result => {
              if (result.value) {
                const payload = {
                  _id: item._id,
                  contractStatus: true,
                }
                this.$store.dispatch(this.COMPANIES_STORE_MODULE_NAME + '/editCompany', payload)
                    .then(() => {
                      this.warningText = ''
                    })
              }
            })
      } else {
        this.$swal({
          title: 'Հաստատե՞լ գործողությունը',
          text: '',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Հաստատել',
          cancelButtonText: 'Կասեցնել',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        })
            .then(result => {
              if (result.value) {
                const payload = {
                  _id: item._id,
                  contractStatus: false,
                }
                this.$store.dispatch(this.COMPANIES_STORE_MODULE_NAME + '/editCompany', payload)
              }
            })
      }
    },
    banSearch(item) {
      if (!item.searchBanned) {
        this.$swal({
          title: 'Հաստատե՞լ գործողությունը',
          text: 'Դուք ցանկանում եք պասիցացնել որոնումը',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Հաստատել',
          cancelButtonText: 'Կասեցնել',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        })
            .then(result => {
              if (result.value) {
                const payload = {
                  _id: item._id,
                  searchBanned: true,
                }
                this.$store.dispatch(this.COMPANIES_STORE_MODULE_NAME + '/editCompany', payload)
                    .then(() => {
                      this.warningText = ''
                    })
              }
            })
      } else {
        this.$swal({
          title: 'Հաստատե՞լ գործողությունը',
          text: '',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Հաստատել',
          cancelButtonText: 'Կասեցնել',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        })
            .then(result => {
              if (result.value) {
                const payload = {
                  _id: item._id,
                  searchBanned: false,
                }
                this.$store.dispatch(this.COMPANIES_STORE_MODULE_NAME + '/editCompany', payload)
              }
            })
      }
    },
    banCompany(item) {
      if (item.blocked) {
        this.$swal({
          title: 'Հաստատե՞լ գործողությունը',
          text: 'Դուք ցանկանում եք պասիցացնել բլոկավորումը',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Հաստատել',
          cancelButtonText: 'Կասեցնել',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        })
            .then(result => {
              if (result.value) {
                const payload = {
                  _id: item._id,
                  blocked: false,
                  blockComment: ''
                }
                this.$store.dispatch(this.COMPANIES_STORE_MODULE_NAME + '/editCompany', payload)
                    .then(() => {
                      this.banText = ''
                    })
              }
            })
      }
    },
    confirmWarning() {
      const payload = {
        _id: this.selectedItem._id,
        warned: true,
        warning: this.warningText,
        warmDate: new Date()
      }
      this.$store.dispatch(this.COMPANIES_STORE_MODULE_NAME + '/editCompany', payload)
          .then(() => {
            this.warningText = ''
            this.selectedItem = null
          })
    },
    confirmBan() {
      const payload = {
        _id: this.selectedItem._id,
        blocked: true,
        blockComment: this.banText
      }
      this.$store.dispatch(this.COMPANIES_STORE_MODULE_NAME + '/editCompany', payload)
          .then(() => {
            this.banText = ''
            this.selectedItem = null
          })
    },

    changeMarkerPosition (e) {
      this.companyCoords = e.get('coords');
    },

    openLicenseFile () {
      window.open(this.form.licenseFile)
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#companies {
  .table-responsive {
    max-height: calc(var(--vh, 1vh) * 100 - 15rem);
    min-height: calc(var(--vh, 1vh) * 100 - 15rem);
  }
}

.column-title {
  font-size: 1rem;
  margin: 8px 0;
  border-bottom: 1px solid #eee;
  font-weight: bold;
  padding-bottom: 3px;
}
#map {
  width: 100%;
  height: 300px;

  .company-map {
    width: 100%;
    height: 100%;
  }
}
.licenseContainer {
  position: relative;
  .licenseIcon {
    position: absolute;
    right: 10px;
    top: 10px;
    color: #4caf50;
    cursor: pointer;
  }
}
</style>
