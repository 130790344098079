<template>
  <div
      class="customizer d-none d-md-block"
      :class="{'open': isCustomizerOpen}"
  >
    <!-- Toggler -->
    <b-link
        class="customizer-toggle d-flex align-items-center justify-content-center"
        @click="isCustomizerOpen = !isCustomizerOpen"
    >
      <feather-icon
          icon="SettingsIcon"
          size="15"
          class="spinner"
      />
    </b-link>
    <!-- /Toggler -->

    <!-- Header -->
    <div class="customizer-section d-flex justify-content-between align-items-center">
      <div>
        <h4 class="text-uppercase mb-0">
          Կարգավորումներ
        </h4>
      </div>
      <feather-icon
          icon="XIcon"
          size="18"
          class="cursor-pointer"
          @click="isCustomizerOpen = !isCustomizerOpen"
      />
    </div>
    <!-- Header -->

    <vue-perfect-scrollbar
        :settings="perfectScrollbarSettings"
        class="ps-customizer-area scroll-area"
    >
      <!-- Skin, RTL, Router Animation -->
      <div class="customizer-section">
        <b-form-group>
          <div class="columns">
            <div v-for="column in columns.ADMIN" class="column">
              <b-form-checkbox @input="changeHeaderStatus(column.id)" v-model="columnStatusesValues[column.id]">{{column.headerName}}</b-form-checkbox>
              <div class="children" v-if="column.children.length > 0">
                <div v-for="child in column.children" class="child">
                  <b-form-checkbox @change="changeChildStatus(child.id)" v-model="columnStatusesValues[child.id]">{{child.headerName}}</b-form-checkbox>
                </div>
              </div>
            </div>
          </div>
        </b-form-group>
      </div>

    </vue-perfect-scrollbar>
  </div>
</template>

<script>
import {
  BLink, BFormRadioGroup, BFormGroup, BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import useAppCustomizer from './useAppCustomizer'

export default {
  components: {
    // BSV
    BLink,
    BFormRadioGroup,
    BFormCheckbox,
    BFormGroup,

    // 3rd party
    vSelect,
    VuePerfectScrollbar,

  },
  setup() {
    const {
      // Vertical Menu
      isVerticalMenuCollapsed,

      // Customizer
      isCustomizerOpen,

      // Skin
      skin,
      skinOptions,

      // Content Width
      contentWidth,
      contentWidthOptions,

      // RTL
      isRTL,

      // routerTransition
      routerTransition,
      routerTransitionOptions,

      // Layout Type
      layoutType,
      layoutTypeOptions,

      // NavMenu Hidden
      isNavMenuHidden,

      // Navbar
      navbarColors,
      navbarTypes,
      navbarBackgroundColor,
      navbarType,

      // Footer
      footerTypes,
      footerType,
    } = useAppCustomizer()

    if (layoutType.value === 'horizontal') {
      // Remove semi-dark skin option in horizontal layout
      const skinSemiDarkIndex = skinOptions.findIndex(s => s.value === 'semi-dark')
      delete skinOptions[skinSemiDarkIndex]

      // Remove menu hidden radio in horizontal layout => As we already have switch for it
      const menuHiddneIndex = navbarTypes.findIndex(t => t.value === 'hidden')
      delete navbarTypes[menuHiddneIndex]
    }

    // Perfect Scrollbar
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    return {
      // Vertical Menu
      isVerticalMenuCollapsed,

      // Customizer
      isCustomizerOpen,

      // Skin
      skin,
      skinOptions,

      // Content Width
      contentWidth,
      contentWidthOptions,

      // RTL
      isRTL,

      // routerTransition
      routerTransition,
      routerTransitionOptions,

      // Layout Type
      layoutType,
      layoutTypeOptions,

      // NavMenu Hidden
      isNavMenuHidden,

      // Navbar
      navbarColors,
      navbarTypes,
      navbarBackgroundColor,
      navbarType,

      // Footer
      footerTypes,
      footerType,

      // Perfect Scrollbar
      perfectScrollbarSettings,
    }
  },

  computed: {
    user () {
      return this.$store.state.user
    },
    userType () {
      return atob(this.$store.state.user_type)
    },
    columnStatuses () {
      return this.$store.state.columnStatuses
    },
    columnStatusesValues () {
      const data = []
      this.columnStatuses.map(item => {
        data[item.id] = !item.hide
      })
      return data
    }
  },
  data () {
    return {
      columns: {
        ADMIN: [
          {
            id: '00',
            headerName: 'Տեխզննում',
            children: [
              {
                id: '001',
                headerName: 'Կարգավիճակ',
                hide: false
              },
              {
                id: '002',
                headerName: 'Ստեղծվել է',
                hide: false
              },
              {
                id: '003',
                headerName: 'Մեքենա',
                hide: false
              },
              {
                id: '004',
                headerName: 'Համարանիշ',
                hide: false
              },
              {
                id: '005',
                headerName: 'Փաստաթուղթ',
                hide: false
              },
              {
                id: '006',
                headerName: 'Կտրոնի №',
                hide: false
              },
              {
                id: '007',
                headerName: 'Թափքի տեսակ',
                hide: false
              },
              // {
              //   id: '008',
              //   headerName: 'Գույն',
              //   hide: false
              // },
              {
                id: '009',
                headerName: 'Հաջորդ Տ․Զ․',
                hide: false
              },
            ],
            hide: false
          },
          {
            id: '01',
            headerName: 'Տեխնիկական զննության կայան',
            children: [
              {
                id: '011',
                headerName: 'Օպերատոր',
                hide: false
              },
              {
                id: '012',
                headerName: 'Անվանում',
                hide: false
              },
              {
                id: '013',
                headerName: 'Տ․Զ․Կ հասցե',
                hide: false
              },
            ],
            hide: false,
          },
          {
            id: '02',
            headerName: 'Տրանսպորտային միջոցի տնօրինող',
            children: [
              {
                id: '021',
                headerName: 'Տնօրինող',
                hide: false
              },
              {
                id: '022',
                headerName: 'ՀՎՀՀ',
                hide: false
              },
              // {
              //   id: '023',
              //   headerName: 'Հեռախոս',
              //   hide: false
              // },
              {
                id: '024',
                headerName: 'Մարզ',
                hide: false
              },
              {
                id: '025',
                headerName: 'Քաղաք',
                hide: false
              },
              {
                id: '026',
                headerName: 'Փողոց',
                hide: false
              },
              {
                id: '027',
                headerName: 'Հասցե',
                hide: false
              },
              {
                id: '028',
                headerName: 'Գրանցման փաստաթուղթ',
                hide: false
              },
            ],
            hide: false
          },
          {
            id: '03',
            headerName: 'Տրանսպորտային միջոց',
            children: [
              {
                id: '031',
                headerName: 'Խումբ',
                hide: false
              },
              {
                id: '032',
                headerName: 'Տեսակ',
                hide: false
              },
              {
                id: '033',
                headerName: 'Մեքենա',
                hide: false
              },
              {
                id: '034',
                headerName: 'Մակնիշ',
                hide: false
              },
              {
                id: '035',
                headerName: 'Մոդել',
                hide: false
              },
              {
                id: '036',
                headerName: 'Թողարկման տարեթիվ',
                hide: false
              },
              {
                id: '037',
                headerName: 'Գրանցման համարանիշ',
                hide: false
              },
              {
                id: '038',
                headerName: 'Վառելիքի տեսակ',
                hide: false
              },
              // {
              //   id: '039',
              //   headerName: 'Թափքի տեսակ',
              //   hide: false
              // },
              // {
              //   id: '0391',
              //   headerName: 'Գույն',
              //   hide: false
              // },
              {
                id: '0392',
                headerName: 'Նույնականացման VIN կոդ',
                hide: false
              },
              // {
              //   id: '0393',
              //   headerName: 'Շարժիչի համար',
              //   hide: false
              // },
              // {
              //   id: '0394',
              //   headerName: 'Հենասարքի համար',
              //   hide: false
              // },
              // {
              //   id: '0395',
              //   headerName: 'Թափքի համար',
              //   hide: false
              // },
              // {
              //   id: '0396',
              //   headerName: 'Գազ․ վեր․ ակտ N',
              //   hide: false
              // },
              {
                id: '0397',
                headerName: 'Տրված է',
                hide: false
              },
            ],
            hide: false
          },
          {
            id: '04',
            headerName: 'Կառուցվածքային փոփոխություն',
            children: [
              {
                id: '041',
                headerName: 'Առկա է',
                hide: false
              },
              {
                id: '042',
                headerName: 'Փոփոխություն',
                hide: false
              },
            ],
            hide: false
          },
          {
            id: '05',
            headerName: 'Տեխնիկական զննություն',
            children: [
              {
                id: '051',
                headerName: 'Կտրոնի համար',
                hide: false
              },
              {
                id: '052',
                headerName: 'Նշումներ',
                hide: false
              },
              {
                id: '053',
                headerName: 'ՏԶ կտրոնի տրման ամսաթիվ',
                hide: false
              },
              {
                id: '054',
                headerName: 'Եզրակացություն',
                hide: false
              },
              {
                id: '055',
                headerName: 'Պատճառը',
                hide: false
              },
              {
                id: '056',
                headerName: 'Հաջորդ Տ․Զ․',
                hide: false
              },
              {
                id: '057',
                headerName: 'Կրկնակի Տ․Զ․',
                hide: false
              },
            ],
            hide: false
          },
          {
            id: '06',
            headerName: 'Վճարումներ',
            children: [
              // {
              //   id: '061',
              //   headerName: 'Բն․ անդ․ N',
              //   hide: false
              // },
              {
                id: '062',
                headerName: 'Գումար',
                hide: false
              },
              {
                id: '063',
                headerName: 'Տ․Զ․ վճար',
                hide: false
              }
            ],
            hide: false
          }
        ],
        VIEWER: [
          {
            id: '00',
            headerName: 'Տեխզննում',
            children: [
              {
                id: '001',
                headerName: 'Կարգավիճակ',
                hide: false
              },
              {
                id: '002',
                headerName: 'Ստեղծվել է',
                hide: false
              },
              {
                id: '003',
                headerName: 'Մեքենա',
                hide: false
              },
              {
                id: '004',
                headerName: 'Համարանիշ',
                hide: false
              },
              {
                id: '005',
                headerName: 'Փաստաթուղթ',
                hide: false
              },
              {
                id: '006',
                headerName: 'Կտրոնի №',
                hide: false
              },
              {
                id: '007',
                headerName: 'Թափքի տեսակ',
                hide: false
              },
              // {
              //   id: '008',
              //   headerName: 'Գույն',
              //   hide: false
              // },
              {
                id: '009',
                headerName: 'Հաջորդ Տ․Զ․',
                hide: false
              },
            ],
            hide: false
          },
          {
            id: '01',
            headerName: 'Տեխնիկական զննության կայան',
            children: [
              {
                id: '011',
                headerName: 'Օպերատոր',
                hide: false
              },
              {
                id: '012',
                headerName: 'Անվանում',
                hide: false
              },
              {
                id: '013',
                headerName: 'Տ․Զ․Կ հասցե',
                hide: false
              },
            ],
            hide: false,
          },
          {
            id: '02',
            headerName: 'Տրանսպորտային միջոցի տնօրինող',
            children: [
              {
                id: '021',
                headerName: 'Տնօրինող',
                hide: false
              },
              {
                id: '022',
                headerName: 'ՀՎՀՀ',
                hide: false
              },
              // {
              //   id: '023',
              //   headerName: 'Հեռախոս',
              //   hide: false
              // },
              {
                id: '024',
                headerName: 'Մարզ',
                hide: false
              },
              {
                id: '025',
                headerName: 'Քաղաք',
                hide: false
              },
              {
                id: '026',
                headerName: 'Փողոց',
                hide: false
              },
              {
                id: '027',
                headerName: 'Հասցե',
                hide: false
              },
              {
                id: '028',
                headerName: 'Գրանցման փաստաթուղթ',
                hide: false
              },
            ],
            hide: false
          },
          {
            id: '03',
            headerName: 'Տրանսպորտային միջոց',
            children: [
              {
                id: '031',
                headerName: 'Խումբ',
                hide: false
              },
              {
                id: '032',
                headerName: 'Տեսակ',
                hide: false
              },
              {
                id: '033',
                headerName: 'Մեքենա',
                hide: false
              },
              {
                id: '034',
                headerName: 'Մակնիշ',
                hide: false
              },
              {
                id: '035',
                headerName: 'Մոդել',
                hide: false
              },
              {
                id: '036',
                headerName: 'Թողարկման տարեթիվ',
                hide: false
              },
              {
                id: '037',
                headerName: 'Գրանցման համարանիշ',
                hide: false
              },
              {
                id: '038',
                headerName: 'Վառելիքի տեսակ',
                hide: false
              },
              // {
              //   id: '039',
              //   headerName: 'Թափքի տեսակ',
              //   hide: false
              // },
              // {
              //   id: '0391',
              //   headerName: 'Գույն',
              //   hide: false
              // },
              {
                id: '0392',
                headerName: 'Նույնականացման VIN կոդ',
                hide: false
              },
              // {
              //   id: '0393',
              //   headerName: 'Շարժիչի համար',
              //   hide: false
              // },
              // {
              //   id: '0394',
              //   headerName: 'Հենասարքի համար',
              //   hide: false
              // },
              // {
              //   id: '0395',
              //   headerName: 'Թափքի համար',
              //   hide: false
              // },
              // {
              //   id: '0396',
              //   headerName: 'Գազ․ վեր․ ակտ N',
              //   hide: false
              // },
              {
                id: '0397',
                headerName: 'Տրված է',
                hide: false
              },
            ],
            hide: false
          },
          {
            id: '04',
            headerName: 'Կառուցվածքային փոփոխություն',
            children: [
              {
                id: '041',
                headerName: 'Առկա է',
                hide: false
              },
              {
                id: '042',
                headerName: 'Փոփոխություն',
                hide: false
              },
            ],
            hide: false
          },
          {
            id: '05',
            headerName: 'Տեխնիկական զննություն',
            children: [
              {
                id: '051',
                headerName: 'Կտրոնի համար',
                hide: false
              },
              {
                id: '052',
                headerName: 'Նշումներ',
                hide: false
              },
              {
                id: '053',
                headerName: 'ՏԶ կտրոնի տրման ամսաթիվ',
                hide: false
              },
              {
                id: '054',
                headerName: 'Եզրակացություն',
                hide: false
              },
              {
                id: '055',
                headerName: 'Պատճառը',
                hide: false
              },
              {
                id: '056',
                headerName: 'Հաջորդ Տ․Զ․',
                hide: false
              },
              {
                id: '057',
                headerName: 'Կրկնակի Տ․Զ․',
                hide: false
              },
            ],
            hide: false
          },
          {
            id: '06',
            headerName: 'Վճարումներ',
            children: [
              // {
              //   id: '061',
              //   headerName: 'Բն․ անդ․ N',
              //   hide: false
              // },
              {
                id: '062',
                headerName: 'Գումար',
                hide: false
              },
              {
                id: '063',
                headerName: 'Տ․Զ․ վճար',
                hide: false
              }
            ],
            hide: false
          }
        ],
      }
    }
  },
  created() {
    if (this.userType === 'ADMIN' || this.userType === 'VIEWER') {
      this.$store.dispatch('setTechServiceColumns', this.columns[this.userType])
      if (this.columnStatuses.length === 0) {
        const statuses = []
        this.columns.ADMIN.map(item => {
          let temp = {
            id: item.id,
            hide: item.hide
          }
          statuses.push(temp)
          if (item.children.length > 0) {
            item.children.map(child => {
              let childTemp = {
                id: child.id,
                hide: child.hide
              }
              statuses.push(childTemp)
            })
          }
        })
        this.$store.commit("SET_TECH_SERVICE_COLUMN_STATUSES", statuses)
      }
    }
  },

  methods: {
    async changeHeaderStatus (id) {
      let index = this.columnStatuses.findIndex((c) => c.id == id)
      if (index >= 0) {
        this.columnStatuses[index].hide = !this.columnStatuses[index].hide
        const columnIndex = this.columns.ADMIN.findIndex((a) => a.id === id)
        if (columnIndex >= 0) {
          const children = this.columns.ADMIN[columnIndex].children
          if (children.length > 0) {
            for (let i = 0; i < children.length; i++) {
              let cIndex = this.columnStatuses.findIndex((c) => c.id == children[i].id)
              if (cIndex >= 0) {
                this.columnStatuses[cIndex].hide = this.columnStatuses[index].hide
              }
            }
          }
        }
        this.$store.commit("SET_TECH_SERVICE_COLUMN_STATUSES", this.columnStatuses)
      }
    },

    changeChildStatus (id) {
      let index = this.columnStatuses.findIndex((c) => c.id == id)
      if (index >= 0) {
        this.columnStatuses[index].hide = !this.columnStatuses[index].hide
        this.$store.commit("UPDATE_TECH_SERVICE_COLUMN_STATUS", {id: id, value: this.columnStatuses[index].hide})
      }
    },

  }

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.customizer-section {
  padding: 1.5rem;
  border-bottom: 1px solid #ebe9f1;

  .dark-layout & {
    border-color: $theme-dark-border-color;
  }

  #skin-radio-group ::v-deep {
    .custom-control-inline {
      margin-right: 0.7rem;
    }
  }

  .form-group {
    margin-bottom: 1.5rem;;
    &:last-of-type {
      margin-bottom: 0;
    }
    ::v-deep legend {
      font-weight: 500;
    }
  }
}

.mark-active {
  box-shadow: 0 0 0 0.2rem rgba(38,143,255,.5);
}

.ps-customizer-area {
  height: calc(100% - 83px)
}
.columns {
  .column {
    .children {
      margin: 0.5rem 1.5rem;
    }
  }
}
</style>
