<template>
  <div id="tech-service-form">

    <validation-observer ref="checkupValidation">

      <b-form
        autocomplete="off"
        class="form"
      >

        <fa-icon
          v-b-tooltip.hover.top="'Ընդհատել տեխզննումը'"
          class="closeAddForm"
          icon="times"
          @click="cancelAdding"
        />

        <div class="extra_header">
          <h4 class="title">
            Տրանսպորտային միջոց
          </h4>
          <div class="borderDiv" />
        </div>
        <b-row>

          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="{ errors }"
              rules="required"
            >

              <MazInput
                v-model="form.ownerStatus.Owner"
                type="text"

                name="email"
                placeholder="ՏՄ տնօրինող"
                :dark="isDark"
                autocomplete="new-tm-tnorinox"
                class="w-full mb-1 text-capitalize"
                :error="!!errors.length"
                old-left-icon-name="person"
                @change="ucWordName"
                @keydown="onlyAm($event)"
                @keydown.enter="changeTab('formAddress')"
                @keydown.tab="changeTab('formAddress')"
              >
                <fa-icon
                  slot="icon-right"
                  icon="plus-square"
                  class="rightIcon faRightIcon"
                  @click="openOwnerStatusForm"
                />

              </MazInput>

            </validation-provider>
          </b-col>

          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="{ errors }"
              rules="required"
            >
              <MazInput
                id="formAddress"
                v-model="form.addressName"
                type="text"
                name="email"
                placeholder="ՏՄ Հաշվառման հասցե"
                autocomplete="new-address-has"
                class="w-full mb-1"
                :dark="isDark"
                readonly
                :error="!!errors.length"
                old-left-icon-name="location_on"
                @keydown.enter="changeTab('formPhone')"
                @click="openAddressForm"
              >

                <fa-icon
                  slot="icon-right"
                  icon="plus-square"
                  class="rightIcon faRightIcon"
                  @click="openAddressForm"
                />

              </MazInput>
            </validation-provider>
          </b-col>

          <template v-if="!$device.isMobile">
            <b-col
              v-if="phoneMasked"
              cols="12"
              md="4"
            >
              <validation-provider
                #default="{ errors }"
                rules="required|min:14"
              >

                <MazInput
                  id="formPhone"
                  v-model="form.Phone"
                  :dark="isDark"
                  v-mask="'(###) ##-##-##'"
                  type="text"
                  name="new-phone-phone"
                  :error="!!errors.length"
                  placeholder="Հեռախոսահամար"
                  autocomplete="new-formdocumentnumber-newnew"
                  class="w-full mb-1"
                  old-left-icon-name="phone"
                  @keydown.enter="changeTab('formDocumentNumber')"
                >
                  <!--                <i @click="clearPhoneMask"-->
                  <!--                   slot="icon-right"-->
                  <!--                   class="material-icons backspaceIcon"-->
                  <!--                   v-b-tooltip.hover.top="'Գրել այլ հեռախոսահամար'"-->
                  <!--                >-->
                  <!--                  backspace-->
                  <!--                </i>-->
                </MazInput>
              </validation-provider>
            </b-col>

            <b-col
              v-else
              cols="12"
              md="4"
            >
              <validation-provider
                #default="{ errors }"
                rules="required"
              >

                <MazInput
                  id="formPhone"
                  v-model="form.Phone"
                  type="text"
                  name="email"
                  :dark="isDark"
                  :error="!!errors.length"
                  placeholder="Հեռախոսահամար"
                  autocomplete="new-heraxos-nuber"
                  class="w-full mb-1"
                  old-left-icon-name="phone"
                  @keydown.enter="changeTab('formDocumentNumber')"
                />
              </validation-provider>
            </b-col>
          </template>

          <template v-if="!$device.isMobile">
            <b-col
              v-if="documentNumberMasked"
              cols="12"
              md="4"
            >
              <validation-provider
                #default="{ errors }"
                rules="required|min:8"
              >

                <MazInput
                  id="formDocumentNumber"
                  v-model="form.documentNumber"
                  v-mask="documentNumberMask"

                  type="text"
                  placeholder="Փաստաթղթի համարը"
                  autocomplete="new-pastatxti-hamar"
                  :error="!!errors.length"
                  :dark="isDark"
                  class="w-full mb-1 text-uppercase"
                  old-left-icon-name="location_on"
                  @keydown.enter="changeTab('formCar')"
                >

                  <fa-icon
                    slot="icon-right"
                    icon="backspace"
                    class="rightIcon faRightIcon"
                    @click="clearDocumentNumberMask"
                  />

                </MazInput>

              </validation-provider>

            </b-col>

            <b-col
              v-else
              cols="12"
              md="4"
            >

              <MazInput
                id="formDocumentNumber"
                v-model="form.documentNumber"
                :dark="isDark"
                type="text"
                placeholder="Փաստաթղթի համարը"
                autocomplete="new-pastatxti-hmar"
                class="w-full text-uppercase"
                old-left-icon-name="location_on"
                @keydown.enter="changeTab('formCar')"
              />
            </b-col>
          </template>

          <b-col
            cols="12"
            md="4"
            sm="6"
            class="mpr-sm"
          >

            <validation-provider
              #default="{ errors }"
              rules="required"
            >

              <v-select
                v-if="rediusCheck"
                id="formCar"
                v-model="form.car_id"
                :clearable="false"

                placeholder="Մակնիշը"
                :selectable="option => option.value !== null"
                :reduce="prefix => prefix.value"
                :class="!!errors.length ? 'is-invalid' : ''"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="cars"
                @keydown.enter="changeTab('formModel')"
                @input="changeCarSelect"
              />

              <v-select
                v-else
                id="formCar"
                v-model="form.car_id"
                :clearable="false"
                placeholder="Մակնիշը"
                :selectable="option => option.value !== null"
                :class="!!errors.length ? 'is-invalid' : ''"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="cars"
                @keydown.enter="changeTab('formModel')"
                @input="changeCarSelect"
              />

            </validation-provider>
          </b-col>

          <b-col
            cols="12"
            md="4"
            sm="6"
            class="mpl-sm"
          >

            <validation-provider
              #default="{ errors }"
              rules="required"
            >
              <MazInput
                id="formCarModel"
                v-model="form.car_model"
                :dark="isDark"
                type="text"
                placeholder="Մոդել"

                autocomplete="new-pastatxti-hmar"
                :error="!!errors.length"
                class="w-full text-uppercase"
                @keydown.enter="changeTab('formCarRegNo')"
              />

            </validation-provider>
          </b-col>

          <b-col
            v-if="!$device.isMobile"
            cols="12"
            md="4"
          >

            <validation-provider
              #default="{ errors }"
              rules="required"
            >

              <b-input-group>
                <b-input-group-prepend is-text>
                  <fa-icon icon="passport" />
                </b-input-group-prepend>
                <b-form-select
                  id="formCarType"
                  v-model="form.car_type_id"
                  :state="errors.length > 0 ? false:null"
                  @keydown.enter="changeTab('formCarRegNo')"
                  @change="changeCarType"
                >
                  <template v-for="(group, index) in car_groups">
                    <optgroup :label="group.name">
                      <option
                        v-for="(type, type_index) in car_types.filter((i) => i.group === group.id)"
                        :value="type.id"
                      >{{ type.name }}
                      </option>
                    </optgroup>
                  </template>

                </b-form-select>
              </b-input-group>

            </validation-provider>
          </b-col>

          <template v-if="$device.isMobile">
            <b-col
              v-if="documentNumberMasked"
              cols="12"
              md="4"
              sm="6"
              class="mpr-sm mt-1"
            >
              <validation-provider
                #default="{ errors }"
                rules="required|min:8"
              >

                <MazInput
                  id="formDocumentNumber"
                  v-model="form.documentNumber"
                  v-mask="documentNumberMask"

                  type="text"
                  placeholder="Փաստաթղթի համարը"
                  autocomplete="new-pastatxti-hamar"
                  :error="!!errors.length"
                  :dark="isDark"
                  class="w-full mb-1 text-uppercase"
                  old-left-icon-name="location_on"
                  @keydown.enter="changeTab('formCar')"
                >

                  <fa-icon
                    slot="icon-right"
                    icon="backspace"
                    class="rightIcon faRightIcon"
                    @click="clearDocumentNumberMask"
                  />

                </MazInput>

              </validation-provider>

            </b-col>

            <b-col
              v-else
              cols="12"
              md="4"
              sm="6"
              class="mpr-sm mt-1"
            >

              <MazInput
                id="formDocumentNumber"
                v-model="form.documentNumber"
                :dark="isDark"
                type="text"
                placeholder="Փաստաթղթի համարը"
                autocomplete="new-pastatxti-hmar"
                class="w-full text-uppercase"
                old-left-icon-name="location_on"
                @keydown.enter="changeTab('formCar')"
              />
            </b-col>
          </template>

          <b-col
            v-if="carRegNoMasked"
            cols="12"
            md="4"
            sm="6"
            class="mpl-sm mt-1"
          >

            <validation-provider
              #default="{ errors }"
              :rules="{
                required: true,
                regex:/(^[a-zA-Z]{2}[0-9]{2}[a-zA-Z]{3}$)|(^[0-9]{2}[a-zA-Z][0-9]{3}$)|(^[a-zA-Z]{3}[0-9]{2}[a-zA-Z]{2}$)|(^[a-zA-Z][0-9]{4}$)|(^[0-9]{4}[a-zA-Z]$)|(^[a-zA-Z]{2}[0-9]{4}$)|(^[0-9]{4}[a-zA-Z]{2}$)|(^[0-9]{2}[a-zA-Z]{2}[0-9]{2}$)|(^[0-9]{2}[a-zA-Z]{2}[0-9]{3}$)|(^[0-9]{3}[a-zA-Z]{2}[0-9]{2}$)|([a-zA-Z]{3}[0-9]{4}$)/
              }"
            >

              <MazInput
                id="formCarRegNo"
                v-model="form.CarRegNo"
                :dark="isDark"
                type="text"
                name="email"

                :error="!!errors.length"
                placeholder="Հաշվառման համարանիշը"
                autocomplete="new-hashvar-hamar"
                class="w-full mb-1 text-uppercase"
                old-left-icon-name="timer_10"
                @keydown.enter="changeTab('formBody')"
                @change="changeCarRegNo"
                @keydown="disableSymbol($event)"
              >

                <fa-icon
                  slot="icon-right"
                  v-b-tooltip.hover.top="'Գրել այլ հաշվառման համարանիշ'"
                  icon="plus-square"
                  class="rightIcon faRightIcon"
                  @click="clearCarRegNoMask"
                />

              </MazInput>

            </validation-provider>
          </b-col>
          <b-col
            v-else
            cols="12"
            md="4"
            sm="6"
            class="mpl-sm mt-1"
          >

            <validation-provider
              #default="{ errors }"
              rules="required"
            >

              <MazInput
                id="formCarRegNo"
                :dark="isDark"
                type="text"
                name="email"
                readonly
                :value="`${form.CarRegNo} ${(form.YellowCarRegNo) ? `(${form.YellowCarRegNo})` : ``}`"
                :error="!!errors.length"
                placeholder="Հաշվառման համարանիշը"
                autocomplete="new-hashvar-hamaran"
                class="w-full mb-1 text-uppercase"
                old-left-icon-name="timer_10"
                @keydown.enter="changeTab('formBody')"
                @keydown="onlyEnNumber($event)"
                @click="$bvModal.show('modal-new-car-reg-no')"
                @change="changeCarRegNo"
              >
                <fa-icon
                  slot="icon-right"
                  icon="plus-square"
                  class="rightIcon faRightIcon"
                  @click="$bvModal.show('modal-new-car-reg-no')"
                />
              </MazInput>

            </validation-provider>
          </b-col>

          <!--          <b-col-->
          <!--              cols="12"-->
          <!--              md="4"-->
          <!--          >-->

          <!--            <validation-provider-->
          <!--                #default="{ errors }"-->
          <!--                rules="required"-->
          <!--            >-->

          <!--              <v-select-->
          <!--                  id="formBody"-->
          <!--                  @input="changeBody"-->
          <!--                  placeholder="Թափքի տեսակը"-->
          <!--                  v-model="form.body_id"-->
          <!--                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"-->
          <!--                  :selectable="option => option.value !== null"-->
          <!--                  :options="bodies"-->
          <!--                  :reduce="prefix => prefix.value"-->
          <!--                  :class="(!!errors.length) ? 'is-invalid' : ''"-->
          <!--                  :clearable="false"-->
          <!--              />-->

          <!--            </validation-provider>-->
          <!--          </b-col>-->

          <!--          <b-col-->
          <!--              cols="12"-->
          <!--              md="4"-->
          <!--          >-->

          <!--            <validation-provider-->
          <!--                #default="{ errors }"-->
          <!--                rules="required"-->
          <!--            >-->

          <!--              <v-select-->
          <!--                  id="formColor"-->
          <!--                  @input="changeColor"-->
          <!--                  placeholder="Գույնը"-->
          <!--                  v-model="form.color_id"-->
          <!--                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"-->
          <!--                  :options="colors"-->
          <!--                  :reduce="prefix => prefix.value"-->
          <!--                  :class="(!!errors.length) ? 'is-invalid' : ''"-->
          <!--                  :clearable="false"-->
          <!--              />-->

          <!--            </validation-provider>-->

          <!--          </b-col>-->

          <b-col
            cols="12"
            md="4"
            sm="6"
            class="mpr-sm"
          >

            <validation-provider
              #default="{ errors }"
              rules="required"
            >

              <MazInput
                id="formMadeAt"
                v-model="form.MadeAt"
                :dark="isDark"
                type="text"
                v-mask="'####'"

                name="email"
                :error="!!errors.length"
                placeholder="Թողարկման տարեթիվ"
                autocomplete="new-made-at-new"
                class="w-full mb-1"
                old-left-icon-name="calendar_today"
                @keydown.enter="changeTab('formVIN')"
                @change="changeYear"
              />

            </validation-provider>
          </b-col>

          <b-col
            v-if="$device.isMobile"
            cols="12"
            md="4"
            sm="6"
            class="mpl-sm"
          >

            <validation-provider
              #default="{ errors }"
              rules="required"
            >

              <v-select
                id="formFuel"
                v-model="form.fuel_id"
                placeholder="Վառելիքի տեսակ"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="fuels"
                :reduce="prefix => prefix.value"
                :class="(!!errors.length) ? 'is-invalid' : ''"
                :clearable="false"
                @input="changeFuel"
              />

            </validation-provider>
          </b-col>

          <b-col
            v-if="VINMasked"
            cols="12"
            md="4"
          >

            <validation-provider
              #default="{ errors }"
              rules="required|min:17"
            >

              <MazInput
                id="formVIN"
                v-model="form.VIN"
                :dark="isDark"
                v-mask="'NNNNNNNNNNNNNNNNN'"
                type="text"
                name="email"
                :error="!!errors.length"
                placeholder="Նույնականացման VIN կոդ"
                autocomplete="new-new-vin-code"
                class="w-full mb-1 text-uppercase"
                old-left-icon-name="code"
                @keydown.enter="changeTab('formEngineNo')"
              >

                <fa-icon
                  slot="icon-right"
                  v-b-tooltip.hover.top="'Գրել այլ VIN կոդ'"
                  icon="backspace"
                  class="rightIcon faRightIcon"
                  @click="clearVinMask"
                />
              </MazInput>

            </validation-provider>
          </b-col>

          <b-col
            v-else
            cols="12"
            md="4"
          >

            <validation-provider
              #default="{ errors }"
            >

              <MazInput
                id="formVIN"
                v-model="form.VIN"
                :dark="isDark"
                type="text"
                name="email"
                :error="!!errors.length"
                placeholder="Նույնականացման VIN կոդ888"
                autocomplete="new-new-vin"
                class="w-full mb-1"
                :disabled="isOriginalInfo"
                old-left-icon-name="code"
                @keydown.enter="changeTab('formEngineNo')"
              />

            </validation-provider>
          </b-col>

          <b-col
            v-if="$device.isMobile"
            cols="12"
            md="4"
            class="mb-1"
          >

            <validation-provider
              #default="{ errors }"
              rules="required"
            >

              <b-input-group>
                <b-input-group-prepend
                  v-if="!$device.isMobile"
                  is-text
                >
                  <fa-icon icon="passport" />
                </b-input-group-prepend>
                <b-form-select
                  id="formCarType"
                  v-model="form.car_type_id"
                  :state="errors.length > 0 ? false:null"
                  @keydown.enter="changeTab('formCarRegNo')"
                  @change="changeCarType"
                >
                  <template v-for="(group, index) in car_groups">
                    <optgroup :label="group.name">
                      <option
                        v-for="(type, type_index) in car_types.filter((i) => i.group === group.id)"
                        :value="type.id"
                      >{{ type.name }}
                      </option>
                    </optgroup>
                  </template>

                </b-form-select>
              </b-input-group>

            </validation-provider>
          </b-col>

          <!--          <b-col-->
          <!--              cols="12"-->
          <!--              md="4"-->
          <!--          >-->

          <!--            <MazInput-->
          <!--                @keydown.enter="changeTab('formTrilerNo')"-->
          <!--                id="formEngineNo"-->
          <!--                :dark="isDark"-->
          <!--                type="text"-->
          <!--                name="email"-->
          <!--                v-model="form.EngineNo"-->
          <!--                @keydown="disableSymbol($event)"-->
          <!--                placeholder="Շարժիչի համարը"-->
          <!--                autocomplete="new-email"-->
          <!--                class="w-full mb-1"-->
          <!--                old-left-icon-name="format_list_numbered"-->
          <!--            />-->

          <!--          </b-col>-->

          <!--          <b-col-->
          <!--              cols="12"-->
          <!--              md="4"-->
          <!--          >-->

          <!--            <MazInput-->
          <!--                @keydown.enter="changeTab('formBodyNo')"-->
          <!--                id="formTrilerNo"-->
          <!--                :dark="isDark"-->
          <!--                type="text"-->
          <!--                name="email"-->
          <!--                v-model="form.TrilerNo"-->
          <!--                placeholder="Հենասարքի համարը"-->
          <!--                @keydown="disableSymbol($event)"-->
          <!--                autocomplete="new-email"-->
          <!--                class="w-full mb-1"-->
          <!--                old-left-icon-name="format_list_numbered"-->
          <!--            />-->

          <!--          </b-col>-->

          <!--          <b-col-->
          <!--              cols="12"-->
          <!--              md="4"-->
          <!--          >-->

          <!--            <MazInput-->
          <!--                @keydown.enter="changeTab('formFuel')"-->
          <!--                id="formBodyNo"-->
          <!--                :dark="isDark"-->
          <!--                type="text"-->
          <!--                name="email"-->
          <!--                v-model="form.BodyNo"-->
          <!--                placeholder="Թափքի համարը"-->
          <!--                @keydown="disableSymbol($event)"-->
          <!--                autocomplete="new-email"-->
          <!--                class="w-full mb-1"-->
          <!--                old-left-icon-name="format_list_numbered"-->
          <!--            />-->

          <!--          </b-col>-->

          <b-col
            cols="12"
            md="4"
            sm="6"
            class="mpr-sm"
          >

            <validation-provider
              #default="{ errors }"
              rules="required"
            >

              <div class="select-with-icon-right">
                <MazSelect
                  id="formChanges"
                  v-model="form.Changes"
                  :dark="isDark"
                  placeholder="Կառուծվածքային փոփոխությունները"
                  :error="!!errors.length"
                  class="w-full mb-1"
                  :options="changes"
                  old-left-icon-name="published_with_changes"
                  @input="changeChanges"
                />

                <fa-icon
                  v-if="form.Changes === '1'"
                  class="rightIcon faRightIcon"
                  icon="plus-square"
                  @click="$bvModal.show('modal-form-changes')"
                />
                <fa-icon
                  v-else
                  icon="plus-square"
                  class="rightIcon faRightIcon"
                />

              </div>

            </validation-provider>
          </b-col>

          <b-col
            v-if="$device.isMobile"
            cols="12"
            md="2"
            sm="6"
            class="mpl-sm"
          >

            <MazInput
              :dark="isDark"
              type="text"
              name="email"
              placeholder="Արատորոշման քարտ"
              autocomplete="new-aratorosh"
              class="w-full mb-1"
              readonly
              :success="form.classificators.length > 0"
              old-left-icon-name="checklist"
              @click="openClassificators"
            >

              <fa-icon
                slot="icon-right"
                icon="plus-square"
                class="rightIcon faRightIcon"
                @click="openClassificators"
              />

            </MazInput>

          </b-col>

          <b-col
            v-if="!$device.isMobile"
            cols="12"
            md="4"
          >

            <validation-provider
              #default="{ errors }"
              rules="required"
            >

              <v-select
                id="formFuel"
                v-model="form.fuel_id"
                placeholder="Վառելիքի տեսակ"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="fuels"
                :reduce="prefix => prefix.value"
                :class="(!!errors.length) ? 'is-invalid' : ''"
                :clearable="false"
                @input="changeFuel"
              />

            </validation-provider>
          </b-col>

          <!--          <b-col-->
          <!--              cols="12"-->
          <!--              md="4"-->
          <!--          >-->

          <!--            <div class="input-group-column input-group-70">-->

          <!--              <MazInput-->
          <!--                  @keydown.enter="changeTab('GasDate')"-->
          <!--                  id="formGasNo"-->
          <!--                  :dark="isDark"-->
          <!--                  type="text"-->
          <!--                  name="email"-->
          <!--                  v-model="form.GasNo"-->
          <!--                  placeholder="Գազով վերասարք. ակտի N"-->
          <!--                  autocomplete="new-email"-->
          <!--                  class="w-full mb-1 border-right-0"-->
          <!--                  old-left-icon-name="format_list_numbered"-->
          <!--              />-->

          <!--            </div>-->

          <!--            <validation-provider-->
          <!--                v-if="form.GasNo !== ''"-->
          <!--                #default="{ errors }"-->
          <!--                rules="required"-->
          <!--            >-->

          <!--              <b-input-group class="input-group-column input-group-30">-->
          <!--                <b-input-group-prepend is-text>-->
          <!--                  <fa-icon icon="calendar-check"/>-->
          <!--                </b-input-group-prepend>-->
          <!--                <flat-pickr-->
          <!--                    @keydown.enter="changeTab('formCheckNo')"-->
          <!--                    @input="changeTab('formCheckNo')"-->
          <!--                    id="GasDate"-->
          <!--                    v-model="form.GasDate"-->
          <!--                    :class="GasDateClass(errors)"-->
          <!--                />-->
          <!--              </b-input-group>-->

          <!--            </validation-provider>-->
          <!--            <validation-provider-->
          <!--                v-else-->
          <!--            >-->

          <!--              <b-input-group class="input-group-column input-group-30">-->
          <!--                <b-input-group-prepend is-text>-->
          <!--                  <fa-icon icon="calendar-check"/>-->
          <!--                </b-input-group-prepend>-->
          <!--                <flat-pickr-->
          <!--                    v-model="form.GasDate"-->
          <!--                    class="form-control"-->
          <!--                />-->
          <!--              </b-input-group>-->

          <!--            </validation-provider>-->

          <!--          </b-col>-->

        </b-row>

        <div class="extra_header">
          <h4 class="title">
            Տեխնիկական զննություն
          </h4>
          <div class="borderDiv" />
        </div>

        <b-row>

          <b-col
            v-if="!$device.isMobile"
            cols="12"
            md="2"
            class="pr-1"
          >

            <MazInput
              :dark="isDark"
              type="text"
              name="email"
              placeholder="Արատորոշման քարտ"
              autocomplete="new-aratorosh"
              class="w-full mb-1"
              readonly
              :success="form.classificators.length > 0"
              old-left-icon-name="checklist"
              @click="openClassificators"
            >

              <fa-icon
                slot="icon-right"
                icon="plus-square"
                class="rightIcon faRightIcon"
                @click="openClassificators"
              />

            </MazInput>

          </b-col>

          <b-col
            cols="12"
            md="2"
            sm="6"
            :class="$device.isMobile ? 'mpr-sm' : 'pl-0'"
          >

            <validation-provider
              #default="{ errors }"
              rules="required"
            >

              <div class="select-with-icon-right">
                <MazSelect
                  id="formOpinion"
                  v-model="form.Opinion"
                  :dark="isDark"
                  placeholder="Եզրակացություն"
                  :error="!!errors.length"
                  class="w-full mb-1"
                  :options="opinions"
                  old-left-icon-name="verified"
                  @input="changeOpinions"
                />

                <fa-icon
                  v-if="form.Opinion === '0'"
                  slot="icon-right"
                  icon="plus-square"
                  class="rightIcon faRightIcon"
                  @click="$bvModal.show('modal-form-opinions')"
                />
                <fa-icon
                  v-else
                  slot="icon-right"
                  icon="plus-square"
                  class="rightIcon faRightIcon"
                />

              </div>

            </validation-provider>
          </b-col>

          <b-col
            cols="12"
            md="4"
            sm="6"
            class="mpl-sm"
          >

            <validation-provider
              #default="{ errors }"
              rules="required"
            >

              <MazSelect
                id="formNextCheckup"
                v-model="form.NextCheckup"
                :dark="isDark"
                :disabled="nextCheckupDisabled"
                placeholder="Հաջորդ ՏԶ"
                :error="!!errors.length"
                class="w-full mb-1"
                :options="next_tech_services"
                old-left-icon-name="next_plan"
                @input="calculateEkoSum"
              />

            </validation-provider>
          </b-col>
          <template v-if="company && company.vazq">
            <b-col
              cols="12"
              md="2"
            >
              <validation-provider
                #default="{ errors }"
                rules="required"
              >
                <MazSelect
                  id="formOpinion"
                  v-model="form.vazqType"
                  :dark="isDark"
                  placeholder="Վազքը ըստ"
                  :error="!!errors.length"
                  class="w-full mb-1"
                  :options="vazqTypes"
                  old-left-icon-name="verified"
                  @input="changeOpinions"
                />
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              md="2"
            >
              <validation-provider
                #default="{ errors }"
                rules="required"
              >
                <MazInput
                  id="Vazq"
                  v-model="form.vazq"
                  :dark="isDark"
                  type="text"
                  name="email"
                  placeholder="Վազքը"
                  :error="!!errors.length"
                  autocomplete="new-bn-amount"
                  class="w-full mb-1"
                  old-left-icon-name="attach_money"
                  @keydown="onlyNumber($event)"
                />
              </validation-provider>
            </b-col>
          </template>
          <template v-if="!$device.isMobile && company && company.attachment">
            <b-col
              cols="12"
              md="2"
            >
              <validation-provider
                #default="{ errors }"
                rules="required"
              >
                <MazInput
                  id="attachment"
                  v-model="attachmentFile"
                  type="file"
                  :dark="isDark"
                  :error="!!errors.length"
                  placeholder="✔"
                  class="w-full mb-1 attachment-file"
                  old-left-icon-name="verified"
                >
                  <fa-icon
                    slot="icon-right"
                    icon="cloud-upload-alt"
                    class="rightIcon faRightIcon"
                  />
                </MazInput>
              </validation-provider>
            </b-col>
          </template>
          <template v-if="company && company.camera">
            <b-col
                cols="12"
                md="4"
            >
              <b-button
                  style="position: relative; top: 4px;"
                  variant="primary"
                  @click="openCameraModal"
              >
                <fa-icon class="mr-1" icon="camera" />
                <span>Նկարահանել</span>
              </b-button>
            </b-col>
          </template>

          <b-col
            v-if="isArtsakh"
            cols="12"
            md="4"
          >

            <MazInput
              id="formCheckNo"
              v-model="form.CheckNo"
              v-mask="'#######'"
              :dark="isDark"
              type="text"
              name="email"
              placeholder="Կտրոնի համարը"
              autocomplete="new-email"
              class="w-full widthis90 mb-1"
              old-left-icon-name="format_list_numbered"
              @keydown.enter="changeTab('EkoDate')"
            />
          </b-col>

        </b-row>

        <div class="extra_header">
          <h4 class="title">
            Վճարումներ
          </h4>
          <div class="borderDiv" />
        </div>

        <b-row>
          <!--          <b-col-->
          <!--              cols="12"-->
          <!--              md="2"-->
          <!--          >-->

          <!--            <MazInput-->
          <!--                @keydown.enter="changeTab('EkoSum')"-->
          <!--                id="formEkoNo"-->
          <!--                :dark="isDark"-->
          <!--                type="text"-->
          <!--                name="email"-->
          <!--                v-model="form.EkoNo"-->
          <!--                placeholder="ԲՆ անդորագրի համար"-->
          <!--                @keydown="disableSymbol($event)"-->
          <!--                autocomplete="new-email"-->
          <!--                class="w-full mb-1"-->
          <!--                old-left-icon-name="attach_money"-->
          <!--            />-->

          <!--          </b-col>-->

          <b-col
            v-if="ekoStatus"
            cols="12"
            md="2"
            sm="6"
            class="mpr-sm"
          >
            <validation-provider
              #default="{ errors }"
              rules="required"
            >

              <MazInput
                id="EkoSum"
                v-model="form.EkoSum"
                :dark="isDark"
                type="text"
                name="email"
                placeholder="Բն․ վճար"
                :error="!!errors.length"
                autocomplete="new-bn-amount"
                class="w-full mb-1"
                old-left-icon-name="attach_money"
                @keydown.enter="changeTab('formSum')"
              />
            </validation-provider>

          </b-col>
          <b-col
            v-else
            cols="12"
            md="2"
            sm="6"
            class="mpr-sm"
          >
            <MazInput
              id="EkoSum"
              v-model="form.EkoSum"
              :dark="isDark"
              type="text"
              name="email"
              disabled
              placeholder="Բն․ վճար"
              autocomplete="new-bn-amount"
              class="w-full mb-1"
              old-left-icon-name="attach_money"
              @keydown.enter="changeTab('formSum')"
            />

          </b-col>
          <b-col
            cols="12"
            md="2"
            sm="6"
            class="mpl-sm"
          >

            <validation-provider
              #default="{ errors }"
              rules="required"
            >

              <MazInput
                id="formSum"
                v-model="form.Sum"
                :error="!!errors.length"
                :dark="isDark"
                type="text"
                name="email"
                placeholder="ՏԶ վճար"
                autocomplete="new-tz-amount"
                class="w-full mb-1"
                old-left-icon-name="attach_money"
              />

            </validation-provider>

          </b-col>

          <template v-if="$device.isMobile">
            <b-col
              v-if="phoneMasked"
              cols="12"
              md="4"
            >
              <validation-provider
                #default="{ errors }"
                rules="required|min:14"
              >

                <MazInput
                  id="formPhone"
                  v-model="form.Phone"
                  :dark="isDark"
                  v-mask="'(###) ##-##-##'"
                  type="text"
                  name="new-phone-phone"
                  :error="!!errors.length"
                  placeholder="Հեռախոսահամար"
                  autocomplete="new-formdocumentnumber-newnew"
                  class="w-full mb-1"
                  old-left-icon-name="phone"
                  @keydown.enter="changeTab('formDocumentNumber')"
                >
                  <!--                <i @click="clearPhoneMask"-->
                  <!--                   slot="icon-right"-->
                  <!--                   class="material-icons backspaceIcon"-->
                  <!--                   v-b-tooltip.hover.top="'Գրել այլ հեռախոսահամար'"-->
                  <!--                >-->
                  <!--                  backspace-->
                  <!--                </i>-->
                </MazInput>
              </validation-provider>
            </b-col>

            <b-col
              v-else
              cols="12"
              md="4"
            >
              <validation-provider
                #default="{ errors }"
                rules="required"
              >

                <MazInput
                  id="formPhone"
                  v-model="form.Phone"
                  type="text"
                  name="email"
                  :dark="isDark"
                  :error="!!errors.length"
                  placeholder="Հեռախոսահամար"
                  autocomplete="new-heraxos-nuber"
                  class="w-full mb-1"
                  old-left-icon-name="phone"
                  @keydown.enter="changeTab('formDocumentNumber')"
                />
              </validation-provider>
            </b-col>
          </template>

          <!--          <template v-if="$device.isMobile">-->
          <!--            <b-col-->
          <!--                cols="12"-->
          <!--                md="4"-->
          <!--            >-->
          <!--              <div class="photo-uploads">-->
          <!--                <div class="photo-upload">-->
          <!--                  <fa-icon icon="camera" />-->
          <!--                </div>-->
          <!--                <div class="photo-upload">-->
          <!--                  <fa-icon icon="camera" />-->
          <!--                </div>-->
          <!--              </div>-->
          <!--            </b-col>-->

          <!--          </template>-->

          <b-col
            cols="12"
            md="2"
          />
          <b-col
            v-if="$checkPermission('tech-service-all', 'insert') && askToEdit"
            cols="12"
            md="2"
          >
            <b-form-checkbox
              v-model="sendNotificationToOperator"
              name="check-button"
              class="custom-control-success mt-1"
              switch
              inline
            >
              Ծանուցել օպերատորին
            </b-form-checkbox>

          </b-col>
          <b-col
            v-else
            cols="12"
            md="2"
          />
          <b-col
            cols="12"
            md="4"
            class="text-center"
          >
            <b-button
              v-if="$checkPermission('tech-service', 'insert')"
              variant="primary"
              :style="$device.isMobile ? 'width: 100%;padding: 1.0rem;' : 'width: 60%;padding: 1.0rem;'"
              :disabled="is_form_saving || !can_save"
              @click="confirmForm"
            >
              Պահպանել
            </b-button>

            <b-button
              v-if="$checkPermission('tech-service-view', 'insert')"
              variant="primary"
              :style="$device.isMobile ? 'width: 100%;padding: 1.0rem;' : 'width: 60%;padding: 1.0rem;'"
              @click="editAdminCheckup"
            >
              Պահպանել
            </b-button>
          </b-col>

        </b-row>

      </b-form>

    </validation-observer>

    <!-- modal login-->
    <b-modal
      id="modal-form-owner-status"
      :no-close-on-backdrop="true"
      ok-title="Հաստատել"
      cancel-title="Մերժել"
      centered
      size="sm"
      title="ՏՄ տնօրինող"
      :ok-disabled="ownerStatusValidation"
      @ok="confirmOwnerStatus"
      @cancel="cancelOwnerStatusChanges"
    >
      <b-form>
        <b-form-group>

          <v-select
            v-model="form.ownerStatus.Status"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="title"
            :options="ownerStatuses"
            :reduce="prefix => prefix.id"
            class="mb-1"
            :clearable="false"
            @input="changeOwnerStatus"
          />

          <b-form-input
            id="owner_name"
            v-model="form.ownerStatus.Owner"
            type="text"
            placeholder="ՏՄ տնօրինող"
            class="mb-1"
          />
          <b-form-input
            id="owner_notes"
            v-model="form.ownerStatus.Notes"
            :disabled="form.ownerStatus.Status !== 1"
            type="text"
            placeholder="ՀՎՀՀ"
          />
        </b-form-group>
      </b-form>
    </b-modal>

    <b-modal
      id="modal-form-address"
      :no-close-on-backdrop="true"
      ok-title="Հաստատել"
      cancel-title="Մերժել"
      centered
      size="sm"
      title="ՏՄ հաշվառման հասցե"
      :ok-disabled="addressValidation"
      @ok="confirmAddressForm"
    >
      <b-form>

        <b-form-group>
          <v-select
            id="formRegion"
            v-model="locationData.region"
            :clearable="false"
            placeholder="Մարզ"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="regions"
            @input="changeFormRegion"
          />
        </b-form-group>

        <b-form-group>
          <v-select
            id="formCounty"
            v-model="locationData.county"
            placeholder="Համայնք"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="counties"
            @input="changeFormCounty"
          />
        </b-form-group>

        <b-form-group class="streetFormGroup">
          <v-select
            id="formStreet"
            v-model="locationData.street"
            class="streetSelector"
            placeholder="Փողոց"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="streets"
            taggable
            @input="changeFormStreet"
          />
        </b-form-group>

        <b-form-group v-if="locationData.street !== null && locationData.street !== ''">
          <b-form-input
            id="formModalAddress"
            v-model="locationData.address"
            placeholder="Հասցե"
            @keydown="onlyAmNumber($event)"
            @change="changeLocationAddress"
            @keydown.enter="confirmAddressForm"
            @keydown.tab="confirmAddressForm"
          />
        </b-form-group>

      </b-form>
    </b-modal>

    <b-modal
      id="modal-form-changes"
      :no-close-on-backdrop="true"
      ok-title="Հաստատել"
      cancel-title="Մերժել"
      no-close-on-esc
      hide-header-close
      centered
      size="sm"
      title="Կառուցվածքային փոփոխություններ"
      :ok-disabled="form.ChangesNotes === ''"
      @cancel="form.Changes = '0'"
    >
      <b-form>
        <b-form-group>
          <b-form-textarea
            id="name"
            v-model="form.ChangesNotes"
            type="text"
            placeholder="Կառուցվածքային փոփոխություններ"
          />
        </b-form-group>
      </b-form>
    </b-modal>

    <b-modal
      id="modal-new-car-reg-no"
      :no-close-on-backdrop="true"
      ok-title="Հաստատել"
      cancel-title="Մերժել"
      no-close-on-esc
      hide-header-close
      centered
      size="sm"
      title="Հաշվառման համարանիշ"
      @cancel="cancelCarRegNoFormating"
    >
      <b-form>

        <validation-observer ref="CarRegNoValidation">
          <b-form-group>
            <validation-provider
              #default="{ errors }"
              :rules="{
                required: true,
                regex:/(^[a-zA-Z]{2}[0-9]{2}[a-zA-Z]{3}$)|(^[0-9]{2}[a-zA-Z][0-9]{3}$)|(^[a-zA-Z]{3}[0-9]{2}[a-zA-Z]{2}$)|(^[a-zA-Z][0-9]{4}$)|(^[0-9]{4}[a-zA-Z]$)|(^[a-zA-Z]{2}[0-9]{4}$)|(^[0-9]{4}[a-zA-Z]{2}$)|(^[0-9]{2}[a-zA-Z]{2}[0-9]{2}$)|(^[0-9]{2}[a-zA-Z]{2}[0-9]{3}$)|(^[0-9]{3}[a-zA-Z]{2}[0-9]{2}$)|([a-zA-Z]{3}[0-9]{4}$)/
              }"
            >
              <MazInput
                id="formCarRegNo"
                v-model="form.DefaultCarRegNo"
                :dark="isDark"
                type="text"
                name="email"
                :error="!!errors.length"
                placeholder="Հաշվառման համարանիշ"
                autocomplete="new-email"
                class="w-full mb-1 text-uppercase"
                old-left-icon-name="timer_10"
                @keydown="onlyEnNumber($event)"
                @change="changeCarRegNo"
              />
            </validation-provider>
          </b-form-group>
          <b-form-group>
            <validation-provider
              #default="{ errors }"
              rules="required"
            >
              <MazInput
                id="formCarRegNoYellow"
                v-model="form.YellowCarRegNo"
                :dark="isDark"
                :error="!!errors.length"
                type="text"
                name="email"
                placeholder="Դեղին համարանիշ"
                autocomplete="new-email"
                class="w-full mb-1 text-uppercase"
                old-left-icon-name="timer_10"
                @keydown="onlyEnNumber($event)"
              />
            </validation-provider>

          </b-form-group>

        </validation-observer>

        <hr>
        <validation-observer ref="CarRegNoValidationOther">
          <b-form-group>
            <MazInput
              id="formCarRegNo"
              v-model="form.CarRegNo"
              :dark="isDark"
              type="text"
              name="email"
              placeholder="Այլ համարանիշ"
              autocomplete="new-email"
              class="w-full mb-1 text-uppercase"
              old-left-icon-name="timer_10"
              @keydown="onlyEnNumber($event)"
              @change="changeCarRegNo"
            />
          </b-form-group>
        </validation-observer>
      </b-form>

      <template #modal-footer="{ ok, cancel, hide }">

        <template>
          <b-button
            size="sm"
            variant="info"
            @click="cancelCarRegNoFormating"
          >
            Մերժել
          </b-button>
        </template>

        <b-button
          :disabled="isCarRegNoDisabled"
          size="sm"
          variant="success"
          @click="confirmCarRegNoModal"
        >
          Հաստատել
        </b-button>
      </template>

    </b-modal>

    <b-modal
      id="modal-form-opinions"
      :no-close-on-backdrop="true"
      ok-title="Հաստատել"
      cancel-title="Մերժել"
      no-close-on-esc
      hide-header-close
      centered
      size="sm"
      title="Եզրակացություն, նշումներ"
      :is-valid="false"
      :ok-disabled="form.OpinionNotes === ''"
      :cancel="false"
      @cancel="form.Opinion = '1'"
    >
      <b-form>
        <b-form-group>
          <b-form-textarea
            id="opinion-notes"
            v-model="form.OpinionNotes"
            type="text"
            placeholder="նշումներ"
          />
        </b-form-group>
      </b-form>
    </b-modal>

    <b-modal
      id="modal-form-classificators"
      :no-close-on-backdrop="true"
      ok-title="Հաստատել"
      cancel-title="Մերժել"
      centered
      size="xl"
      title="Արատորոշումներ"
      :is-valid="false"
    >
      <b-form>
        <b-form-group>
          <template v-for="(classificator, index) in classificators">
            <b-form-checkbox
              v-if="classificator.code%100 !== 0"
              v-model="form.classificators"
              :disabled="checkboxesDisabled"
              :value="classificator.id"
              class="custom-control-primary classificator-checkbox"
            >
              {{ classificator.title }}
            </b-form-checkbox>
          </template>
        </b-form-group>
      </b-form>
    </b-modal>

    <b-modal
      id="modal-camera"
      :no-close-on-backdrop="true"
      centered
      size="xl"
      title="Տեսանկարահանում"
      :is-valid="false"
      :hide-footer="true"
    >
      <b-form>
        <div class="web-camera-container">
          <div class="camera-button">
            <button
              type="button"
              class="btn is-rounded"
              :class="{ 'btn-primary' : !isCameraOpen, 'btn-danger' : isCameraOpen}"
              @click="toggleCamera"
            >
              <span v-if="!isCameraOpen">Միացնել տեսախցիկը</span>
              <span v-else>Փակել տեսախցիկը</span>
            </button>
          </div>

          <div
            v-show="isCameraOpen && isLoading"
            class="camera-loading"
          >
            <ul class="loader-circle">
              <li />
              <li />
              <li />
            </ul>
          </div>

          <div
            v-if="isCameraOpen"
            v-show="!isLoading"
            class="camera-box"
            :class="{ 'flash' : isShotPhoto }"
          >

            <div
              class="camera-shutter"
              :class="{'flash' : isShotPhoto}"
            />

            <video
              v-show="!isPhotoTaken"
              ref="camera"
              :width="666"
              :height="500"
              autoplay
            />

            <canvas
              v-show="isPhotoTaken"
              id="photoTaken"
              ref="canvas"
              :width="666"
              :height="500"
            />
          </div>

          <div
            v-if="isCameraOpen && !isLoading"
            class="camera-shoot"
          >
            <button
              type="button"
              class="button"
              @click="takePhoto"
            >
              <img src="https://img.icons8.com/material-outlined/50/000000/camera--v2.png">
            </button>
          </div>

          <div
            v-if="isPhotoTaken && isCameraOpen"
            class="camera-download"
          >
            <button
              id="downloadPhoto"
              class="btn btn-primary"
              type="button"
              @click="saveImage"
            >Պահպանել</button>
          </div>
        </div>

      </b-form>
    </b-modal>

  </div>
</template>

<script>

import useAppConfig from '@core/app-config/useAppConfig'
import {
  BAvatar,
  BButton,
  BCard,
  BCardHeader,
  BCardTitle,
  BCol,
  BForm,
  BFormCheckbox,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormTextarea,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
  BMedia,
  BRow,
  BTable,
  VBTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import flatPickr from 'vue-flatpickr-component'
import { length, regex, required } from '@validations'
// Stores
import carsModule from '@/store/cars/cars'
import locationsModule from '@/store/locations/locations'
import techServiceModule from '@/store/tech-service/techServiceModule'
import store from '@/store'
import { computed } from '@vue/composition-api'

const uniqid = require('uniqid')

export default {
  name: 'TechServiceForm',
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormTextarea,
    BFormDatepicker,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    flatPickr,
  },
  data() {
    return {
      camera: null,
      isCameraOpen: false,
      isPhotoTaken: false,
      isShotPhoto: false,
      isLoading: false,
      link: '#',
      attachmentFile: null,
      attachment: null,
      is_form_saving: false,
      ekoStatus: true,
      nextCheckupDisabled: false,
      stopImage: require('@/assets/images/icons/stop.png'),
      rediusCheck: false,
      required,
      regex,
      length,
      sendNotificationToOperator: true,
      CARS_STORE_MODULE_NAME: 'carsModule',
      LOCATIONS_STORE_MODULE_NAME: 'locationsModule',
      TECH_SERVICE_STORE_MODULE_NAME: 'techServiceModule',
      form: {
        region: '',
        region_id: null,
        county: '',
        county_id: null,
        street: '',
        street_id: null,
        address: '',
        addressName: '',
        ownerName: '',
        ownerStatus: {
          Owner: '',
          Status: 0,
          Notes: '',
        },
        Phone: '',
        documentNumber: '',
        car_group: '',
        car_type_id: null,
        car_type: '',
        car_id: null,
        car: '',
        car_model_id: null,
        car_model: '',
        CarRegNo: '',
        YellowCarRegNo: '',
        DefaultCarRegNo: '',
        body: '',
        body_id: null,
        color: '',
        color_id: null,
        fuel: '',
        fuel_id: null,
        MadeAt: '',
        VIN: '',
        EngineNo: '',
        TrilerNo: '',
        GasNo: '',
        GasDate: '',
        Changes: '0',
        ChangesNotes: '',
        Opinion: '1',
        OpinionNotes: '',
        CheckNo: '',
        NextCheckup: 1,
        Sum: '',
        EkoNo: '',
        EkoDate: null,
        EkoSum: '',
        classificators: [],
        engine: null,
        dahk_exists: false,
        reg_num: null,
        vazqType: 1,
        vazq: null,
        attachment: null,
        recording_date: null,
      },
      locationData: {
        region: null,
        county: null,
        street: '',
        address: '',
      },
      optionGroups: {
        First: [
          {
            text: 'One',
            value: 'A',
          },
          {
            text: 'Two',
            value: 'B',
          },
        ],
        Second: [
          {
            text: 'Three',
            value: 'C',
          },
        ],
      },
      ownerStatuses: [
        {
          id: 0,
          title: 'Ֆիզիկական անձ',
        },
        {
          id: 1,
          title: 'Իրավաբանական անձ',
        },
        {
          id: 2,
          title: 'Անհատ ձեռներեց',
        },
      ],
      documentNumberMasked: true,
      phoneMasked: true,
      VINMasked: true,
      carRegNoMasked: true,
      models: [],
      checkboxesDisabled: false,
      checkNoIsRequired: false,
      isOriginalInfo: false,
      steps: [
        {
          target: '#mechanic_selector',
          header: {
            title: 'Քայլ 1',
          },
          content: 'Սեղմեք այստեղ հսկիչին ընտրելու համար',
        },
      ],
      vehicle_type: null,
      special_notes: null,
      weight: null,
      max_weight: null,
      mobile: null,
      vazqType: 1,
    }
  },
  computed: {

    can_save() {
      if (this.company && this.company.camera && !this.camera) {
        return false
      }
      return true
    },

    company() {
      return this.$store.state[this.TECH_SERVICE_STORE_MODULE_NAME].company
    },

    isArtsakh() {
      return (this.user.region === 'Արցախ')
    },

    isCarRegNoDisabled() {
      if (this.form.CarRegNo !== '') {
        return false
      } if (this.form.YellowCarRegNo !== '' && this.form.DefaultCarRegNo !== '') {
        return false
      }
      return true
    },

    temporary_id() {
      return this.$store.state[this.TECH_SERVICE_STORE_MODULE_NAME].temporary_id
    },

    user() {
      return this.$store.state.user
    },

    add() {
      return this.$store.state[this.TECH_SERVICE_STORE_MODULE_NAME].add
    },

    edit() {
      return this.$store.state[this.TECH_SERVICE_STORE_MODULE_NAME].edit
    },

    askToEdit() {
      return this.$store.state[this.TECH_SERVICE_STORE_MODULE_NAME].askToEdit
    },

    copy() {
      return this.$store.state[this.TECH_SERVICE_STORE_MODULE_NAME].copy
    },

    copy_payment() {
      return this.$store.state[this.TECH_SERVICE_STORE_MODULE_NAME].copy_payment
    },

    edit_form() {
      return this.$store.state[this.TECH_SERVICE_STORE_MODULE_NAME].form
    },

    copy_form() {
      return this.$store.state[this.TECH_SERVICE_STORE_MODULE_NAME].copy_form
    },

    copy_payment_form() {
      return this.$store.state[this.TECH_SERVICE_STORE_MODULE_NAME].copy_payment_form
    },

    documentNumberMask() {
      return this.documentNumberMasked ? [/[a-zA-Z]/, /[a-zA-Z]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/] : ''
    },

    carRegNoMask() {
      return [/[0-9]/]
    },

    documentNumberStyle() {
      return this.documentNumberMasked ? 'text-transform: uppercase' : ''
    },

    car_groups() {
      return this.$store.state[this.CARS_STORE_MODULE_NAME].car_groups
    },

    car_types() {
      return this.$store.state[this.CARS_STORE_MODULE_NAME].car_types
    },

    cars() {
      let primaryFinished = false
      const cars = []
      const data = this.$store.state[this.CARS_STORE_MODULE_NAME].cars
      for (const car of data) {
        if (!car.primary && !primaryFinished) {
          primaryFinished = true
          const line = {
            label: '-----------------------------------',
            value: null,
            disabled: true,
          }
          cars.push(line)
        }
        const temp = {
          label: car.name,
          value: car.id,
        }
        cars.push(temp)
      }
      return cars
    },

    car_models() {
      return this.$store.state[this.CARS_STORE_MODULE_NAME].car_models.map(e => ({
        label: e.name,
        value: e.id,
      }))
    },

    bodies() {
      let primaryFinished = false
      const bodies = []
      const data = this.$store.state[this.CARS_STORE_MODULE_NAME].bodies
      for (const body of data) {
        if (!body.primary && !primaryFinished) {
          primaryFinished = true
          const line = {
            label: '-----------------------------------',
            value: null,
            disabled: true,
          }
          bodies.push(line)
        }
        const temp = {
          label: body.name,
          value: body.id,
        }
        bodies.push(temp)
      }
      return bodies
    },

    colors() {
      let primaryFinished = false
      const colors = []
      const data = this.$store.state[this.CARS_STORE_MODULE_NAME].colors
      for (const color of data) {
        if (!color.primary && !primaryFinished) {
          primaryFinished = true
          const line = {
            label: '-----------------------------------',
            value: null,
            disabled: true,
          }
          colors.push(line)
        }
        const temp = {
          label: color.name,
          value: color.id,
        }
        colors.push(temp)
      }
      return colors
    },

    fuels() {
      return this.$store.state[this.CARS_STORE_MODULE_NAME].fuels.map(e => ({
        label: e.name,
        value: e.id,
      }))
    },

    classificators() {
      const data = (this.$store.state.classificatorsStatus) ? this.$store.state[this.CARS_STORE_MODULE_NAME].new_classificators : this.$store.state[this.CARS_STORE_MODULE_NAME].classificators
      return data.filter(e => e.status).map(e => ({ title: `${e.code}-${e.name}`, ...e }))
    },

    regions() {
      return this.$store.state[this.LOCATIONS_STORE_MODULE_NAME].regions.map(e => ({ label: e.name, ...e }))
    },

    counties() {
      return this.$store.state[this.LOCATIONS_STORE_MODULE_NAME].counties.map(e => ({ label: e.name, ...e }))
    },

    streets() {
      return this.$store.state[this.LOCATIONS_STORE_MODULE_NAME].streets.map(e => ({ label: e.name, ...e }))
    },

    GasDateClass() {
      return errors => ((errors.length > 0) ? 'form-control is-invalid' : 'form-control')
    },

    changes() {
      return [
        {
          value: '0',
          label: 'Ոչ',
        },
        {
          value: '1',
          label: 'Այո',
        },
      ]
    },

    opinions() {
      return [
        {
          value: '1',
          label: 'Սարքին է',
        },
        {
          value: '0',
          label: 'Ճանաչվել է անսարք',
        },
      ]
    },

    vazqTypes() {
      return [
        {
          value: 1,
          label: 'կմ',
        },
        {
          value: 2,
          label: 'Մղոն',
        },
      ]
    },

    next_tech_services() {
      return [
        {
          value: '0',
          label: '6 ամսից',
        },
        {
          value: 1,
          label: '1 տարուց',
        },
        {
          value: 2,
          label: '2 տարուց',
        },
      ]
    },

    ownerStatusValidation() {
      return (this.form.ownerStatus.Owner === '') || (this.form.ownerStatus.Status === 1 && this.form.ownerStatus.Notes === '')
    },

    addressValidation() {
      return this.locationData.region === '' || this.locationData.county === '' || this.locationData.street === '' || this.locationData.address === ''
          || this.locationData.region === null || this.locationData.county === null || this.locationData.street === null || this.locationData.address === null
    },

    activeMechanic() {
      return this.$store.state.activeMechanic
    },
  },
  mounted() {
    const action = `CHECK_NEW_CHECKUPS_${this.$store.state.user.company}`
    // this.sockets.subscribe(action, (data) => {
    //   if (this.add) {
    //     // this.$socket.emit('NEW_CHECKUP', {
    //     //   id: this.temporary_id,
    //     //   operator: this.$store.state.user,
    //     //   company: this.$store.state.user.company,
    //     //   CarRegNo: this.form.CarRegNo,
    //     // })
    //   } else if (this.edit) {
    //     // console.log('edit form opened')
    //   }
    //
    // })

    // Mechanic edited classificator
    const classificator_action = `SEND_OPERATOR_EDITED_CLASSIFICATORS_${this.$store.state.user.company}`
    // this.sockets.subscribe(classificator_action, (data) => {
    //   const id = (this.add) ? this.temporary_id : this.form._id
    //   if (data.item.id === id) {
    //     this.checkboxesDisabled = true
    //     this.form.classificators = data.item.classificators
    //     this.$store.commit('SET_ACTIVE_MECHANIC', data.user)
    //   }
    // })
  },

  setup() {
    const { skin } = useAppConfig()

    const isDark = computed(() => skin.value === 'dark')

    return {
      skin,
      isDark,
    }
  },

  async created() {
    console.log(this.company)
    if (!store.hasModule(this.TECH_SERVICE_STORE_MODULE_NAME)) store.registerModule(this.TECH_SERVICE_STORE_MODULE_NAME, techServiceModule)
    if (!store.hasModule(this.CARS_STORE_MODULE_NAME)) store.registerModule(this.CARS_STORE_MODULE_NAME, carsModule)
    if (!store.hasModule(this.LOCATIONS_STORE_MODULE_NAME)) store.registerModule(this.LOCATIONS_STORE_MODULE_NAME, locationsModule)
    this.$store.dispatch('checkServerStatus')
    this.getCarGroups()
    this.getCarTypes()
    this.getCars()
    this.getBodies()
    this.getColors()
    this.getFuels()
    this.getClassificators()
    this.getRegions()
    this.getCounties()
    this.getStreets()
    if (this.edit) {
      this.getEditForm()
    }
    if (this.copy) {
      this.getCopyForm()
    }
    if (this.copy_payment) {
      this.getCopyPaymentForm()
    }
  },

  destroyed() {
    this.form = {
      region: '',
      region_id: null,
      county: '',
      county_id: null,
      street: '',
      street_id: null,
      address: '',
      addressName: '',
      ownerName: '',
      ownerStatus: {
        Owner: '',
        Status: 0,
        Notes: '',
      },
      Phone: '',
      documentNumber: '',
      car_group: '',
      car_type_id: null,
      car_type: '',
      car_id: null,
      car: '',
      car_model_id: null,
      car_model: '',
      CarRegNo: '',
      body: '',
      body_id: null,
      color: '',
      color_id: null,
      fuel: '',
      fuel_id: null,
      MadeAt: '',
      VIN: '',
      EngineNo: '',
      TrilerNo: '',
      GasNo: '',
      GasDate: '',
      Changes: '0',
      ChangesNotes: '',
      Opinion: '1',
      OpinionNotes: '',
      CheckNo: '',
      NextCheckup: 1,
      Sum: '',
      EkoNo: '',
      EkoDate: null,
      EkoSum: '',
      classificators: [],
      inspectionStatus: false,
      vazqType: 1,
      vazq: null,
      insuranceStartDate: null,
      insuranceEndDate: null,
      insuranceCompany: null,
      dahkInfo: null,
    }
  },

  methods: {

    toggleCamera() {
      if (this.isCameraOpen) {
        this.isCameraOpen = false
        this.isPhotoTaken = false
        this.isShotPhoto = false
        this.stopCameraStream()
      } else {
        this.isCameraOpen = true
        this.createCameraElement()
      }
    },

    createCameraElement() {
      this.isLoading = true

      const constraints = (window.constraints = {
        audio: false,
        video: true,
      })

      navigator.mediaDevices
        .getUserMedia(constraints)
        .then(stream => {
          this.isLoading = false
          this.$refs.camera.srcObject = stream
        })
        .catch(error => {
          this.isLoading = false
          alert("May the browser didn't support or there is some errors.")
        })
    },

    stopCameraStream() {
      const tracks = this.$refs.camera.srcObject.getTracks()

      tracks.forEach(track => {
        track.stop()
      })
    },

    takePhoto() {
      if (!this.isPhotoTaken) {
        this.isShotPhoto = true

        const FLASH_TIMEOUT = 50

        setTimeout(() => {
          this.isShotPhoto = false
        }, FLASH_TIMEOUT)
      }

      this.isPhotoTaken = !this.isPhotoTaken

      const context = this.$refs.canvas.getContext('2d')
      context.drawImage(this.$refs.camera, 0, 0, 666, 500)
    },

    saveImage() {
      this.$vs.loading()
      this.toggleCamera()
      const canvas = document.getElementById('photoTaken').toDataURL('image/jpeg')
        .replace('image/jpeg', 'image/octet-stream')
      const blob = this.dataURLToBlob(canvas)
      const timestamp = new Date().getTime();
      const randomString = Math.random().toString(36).substring(2, 8)
      const uniqueName = `${timestamp}-${randomString}.jpg`
      const file = this.blobToFile(blob, uniqueName)
      this.$store.dispatch(`${this.TECH_SERVICE_STORE_MODULE_NAME}/addAttachment`, {
        file,
      })
        .then((response => {
          this.camera = response.file
          this.$bvModal.hide('modal-camera')
          this.$vs.loading.close()
        }))
        .catch(() => {
          this.camera = null
          this.$vs.loading.close()
          this.$bvModal.hide('modal-camera')
        })
    },

    dataURLToBlob(dataURL) {
      const byteString = atob(dataURL.split(',')[1]);
      const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ab], {type: mimeString});
    },

    blobToFile(blob, fileName) {
      return new File([blob], fileName, {type: blob.type, lastModified: Date.now(), quality: 0.8});
    },

    openCameraModal() {
      this.$bvModal.show('modal-camera')
    },

    getVehicleTypesArray() {
      const types = {
        passenger: {
          name: 'Թեթև մարդատար',
        },
        minibus: {
          name: 'Միկրոավտոբուս',
        },
        bus: {
          name: 'Ավտոբուս',
        },
        truck_1: {
          name: 'Բեռնատար(<3.5տ.)',
        },
        truck_2: {
          name: 'Բեռնատար(3.5-12տ.)',
        },
        truck_3: {
          name: 'Բեռնատար(>12տ.)',
        },
        motorcycle: {
          name: 'Մոտոցիկլետ',
        },
        attachment: {
          name: 'Կցորդ',
        },
      }
      const response = []

      for (const id in types) {
        if (types.hasOwnProperty(id)) {
          const type = types[id]
          type.id = id
          response.push(type)
        }
      }

      return response
    },

    getEditForm() {
      const data = this.edit_form
      const car = this.cars.find(c => c.label === data.car)
      if (car) {
        data.car_id = car.value
      }
      this.changeCar(data.car_id)
      this.changeLocationData(data)
      this.rediusCheck = true
      this.form = {
        id: data.id,
        createdAt: data.createdAt,
        _id: data._id,
        region: data.region,
        county: data.county,
        street: data.street,
        address: data.address,
        addressName: `${data.region}, ${data.county}, ${data.street}, ${data.address}`,
        ownerName: data.ownerStatus.Owner,
        ownerStatus: data.ownerStatus,
        Phone: data.Phone,
        documentNumber: data.documentNumber,
        car_group_id: data.car_group_id,
        car_group: data.car_group,
        car_type: data.car_type,
        car_type_id: data.car_type_id,
        car: data.car,
        car_id: data.car_id,
        car_model: data.car_model,
        car_model_id: data.car_model_id,
        CarRegNo: data.CarRegNo,
        YellowCarRegNo: data.YellowCarRegNo,
        body: data.body,
        body_id: data.body_id,
        color: data.color,
        color_id: data.color_id,
        fuel: data.fuel,
        fuel_id: data.fuel_id,
        MadeAt: data.MadeAt,
        VIN: data.VIN,
        EngineNo: data.EngineNo,
        TrilerNo: data.TrilerNo,
        BodyNo: data.BodyNo,
        GasNo: data.GasNo,
        GasDate: data.GasDate,
        Changes: (data.Changes) ? '1' : '0',
        ChangesNotes: data.ChangesNotes,
        Opinion: (data.Opinion) ? '1' : '0',
        OpinionNotes: data.OpinionNotes,
        CheckNo: data.CheckNo,
        NextCheckup: data.NextCheckup,
        Sum: data.Sum,
        EkoNo: data.EkoNo,
        EkoDate: data.EkoDate,
        EkoSum: data.EkoSum,
        classificators: data.classificators,
        vehicle_type: data.vehicle_type,
        special_notes: data.special_notes,
        weight: data.weight,
        max_weight: data.max_weight,
        mobile: data.mobile,
        inspectionStatus: data.inspectionStatus,
        reg_num: data.reg_num,
        vazqType: data.vazqType ? data.vazqType : 1,
        vazq: data.vazq,
        insuranceStartDate: data.insuranceStartDate,
        insuranceEndDate: data.insuranceEndDate,
        insuranceCompany: data.insuranceCompany,
        dahkInfo: data.dahkInfo,
      }
      this.carRegNoMasked = false
      this.VINMasked = false
    },

    async getCopyForm() {
      this.VINMasked = false
      const data = this.copy_form
      if (data.car_id) {
        this.changeCar(data.car_id)
        this.rediusCheck = true
      }

      this.changeLocationData(data)

      if (this.$device.isMobile && data.vehicleType) {
        let car_group_name
        switch (data.vehicleType) {
          case 'passenger':
            car_group_name = 'Թեթև մարդատար'
            break
          case 'minibus':
          case 'bus':
            car_group_name = 'Ավտոբուս'
            break
          case 'truck_1':
          case 'truck_2':
          case 'truck_3':
            car_group_name = 'Բեռնատար կողավոր և մասնագիտացված'
            break
          case 'motorcycle':
            car_group_name = 'Մոտոցիկլ'
            break
          case 'attachment':
            car_group_name = 'Կցորդներ'
            break
        }
        const car_group = this.car_groups.find(group => group.name === car_group_name)

        if (car_group) {
          data.car_group_id = car_group.id
          data.car_group = car_group.name
        }

        const vehicleTypes = this.getVehicleTypesArray()
        const vehicleType = vehicleTypes.find(type => type.id === data.vehicleType)
        const vehicle_type_name = vehicleType.name
        let vehicleTypeName
        switch (vehicle_type_name) {
          case 'Թեթև մարդատար':
            vehicleTypeName = 'Թեթև մարդատար'
            break
          case 'Միկրոավտոբուս':
            vehicleTypeName = 'Միկրոավտոբուս'
            break
          case 'Ավտոբուս':
            vehicleTypeName = 'Ավտոբուս'
            break
          case 'Բեռնատար(<3.5տ.)':
            vehicleTypeName = 'Մինչև 3.5 տ. լրիվ զանգվածով'
            break
          case 'Բեռնատար(3.5-12տ.)':
            vehicleTypeName = '3.5-12տ. լրիվ զանգվածով'
            break
          case 'Բեռնատար(>12տ.)':
            vehicleTypeName = '12տ. -ից ավել լրիվ զանգվածով'
            break
          case 'Մոտոցիկլետ':
            vehicleTypeName = 'Մոտոցիկլետ'
            break
          case 'Կցորդ':
            vehicleTypeName = 'Կցորդներ'
            break
        }
        const car_type = this.car_types.find(type => type.name === vehicleTypeName)
        if (car_type) {
          data.car_type_id = car_type.id
          data.car_type = car_type.name
        }
      }

      this.form = {
        region: data.region,
        county: data.county,
        street: data.street,
        address: data.address,
        addressName: `${data.region}, ${data.county}, ${data.street}, ${data.address}`,
        ownerName: data.ownerStatus.Owner,
        ownerStatus: data.ownerStatus,
        Phone: '',
        documentNumber: data.documentNumber,
        car_group_id: data.car_group_id,
        car_group: data.car_group,
        car_type: data.car_type,
        car_type_id: data.car_type_id,
        car: data.car,
        car_id: data.car_id,
        car_model: data.car_model,
        car_model_id: data.car_model_id,
        CarRegNo: data.CarRegNo,
        YellowCarRegNo: data.YellowCarRegNo,
        body: data.body,
        body_id: data.body_id,
        color: data.color,
        color_id: data.color_id,
        fuel: data.fuel,
        fuel_id: data.fuel_id,
        MadeAt: data.MadeAt,
        VIN: data.VIN,
        EngineNo: data.EngineNo,
        TrilerNo: data.TrilerNo,
        BodyNo: data.BodyNo,
        GasNo: '',
        GasDate: null,
        Changes: '0',
        ChangesNotes: '',
        Opinion: '1',
        OpinionNotes: '',
        NextCheckup: '',
        Sum: data.Sum,
        EkoNo: '',
        EkoDate: null,
        EkoSum: '',
        classificators: [],
        vehicle_type: data.vehicle_type,
        special_notes: data.special_notes,
        weight: data.weight,
        max_weight: data.max_weight,
        fuel_name: data.fuel_name,
        identification_no: data.identification_no,
        psn: data.psn,
        mobile: data.mobile,
        inspectionStatus: data.inspectionStatus,
        engine: data.engine,
        payment_id: (data.payment) ? data.payment.id : null,
        copy: data.copy,
        dahk_exists: data.dahk_exists,
        reg_num: data.reg_num,
        vazqType: (data.vazqType) ? data.vazqType : 1,
        vazq: data.vazq,
        insuranceStartDate: data.insuranceStartDate,
        insuranceEndDate: data.insuranceEndDate,
        insuranceCompany: data.insuranceCompany,
        dahkInfo: data.dahkInfo,
        recording_date: data.recording_date,
        taxDebt: data.payments?.tax?.total ?? 0,
      }
      if (!this.car_id) {
        await this.getOldData(data)
      }
      if (this.form.YellowCarRegNo) {
        this.carRegNoMasked = false
      }
      this.changeYear()
      this.calculateEkoSum()
      this.changeCarType(this.form.car_type_id)
      if (data.isOriginalInfo) {
        this.isOriginalInfo = true
      }
      if (!this.$device.isMobile && data.notification) {
        this.$swal({
          title: 'Ծանուցում',
          text: data.notification,
          icon: 'info',
          customClass: {
            confirmButton: 'btn btn-primary',
            container: 'alertNotification',
          },
          buttonsStyling: false,
        })
      }
    },

    async getCopyPaymentForm() {
      const data = this.copy_payment_form
      this.form = {
        documentNumber: data.request.documentNumber,
        CarRegNo: data.request.car_reg_no,
        MadeAt: data.request.made_at,
        classificators: [],
        ownerName: data.name,
        ownerStatus: {
          Owner: data.name,
          Status: 0,
          Notes: '',
        },
      }
      if (!this.car_id) {
        await this.getOldData(data)
      }
      if (this.form.YellowCarRegNo) {
        this.carRegNoMasked = false
      }
      this.changeYear()
    },

    getOldData(data) {
      // Get Model
      this.$store.dispatch(`${this.CARS_STORE_MODULE_NAME}/getModels`, `name=${data.car_model}`)
        .then(response => {
          if (response.data.data.length > 0) {
            this.form.car_model_id = {
              label: response.data.data[0].name,
              value: response.data.data[0].id,
            }
          }
        })

      // Get Car
      const carIndex = this.cars.findIndex(car => car.label === data.car)
      if (carIndex >= 0) {
        this.form.car_id = {
          label: this.cars[carIndex].label,
          value: this.cars[carIndex].value,
        }
        if (!this.car_model_id) {
          this.$store.dispatch(`${this.CARS_STORE_MODULE_NAME}/getModels`, `car=${this.cars[carIndex].value}`)
        }
      }

      // Get Car Group
      const carGroupIndex = this.car_groups.findIndex(cg => cg.name === data.car_group)
      if (carGroupIndex >= 0) {
        this.form.car_group_id = this.car_groups[carGroupIndex].id
      }

      // Get Car Type
      const carTypeIndex = this.car_types.findIndex(ct => ct.name === data.car_type)
      if (carTypeIndex >= 0) {
        this.form.car_type_id = this.car_types[carTypeIndex].id
      }

      // Get Body
      const bodyIndex = this.bodies.findIndex(b => b.name === data.body)
      if (bodyIndex >= 0) {
        this.form.body_id = this.bodies[bodyIndex].id
      }

      // Get Color
      const colorIndex = this.colors.findIndex(c => c.name === data.color)
      if (colorIndex >= 0) {
        this.form.color_id = this.colors[colorIndex].id
      }

      // Get Fuel
      const fuelIndex = this.fuels.findIndex(f => f.name === data.fuel)
      if (fuelIndex >= 0) {
        this.form.fuel_id = this.fuels[fuelIndex].id
      }
    },

    getCarName(id) {
      const index = this.cars.findIndex(e => e.id === id)
      if (index >= 0) {
        return this.cars[index].name
      }
    },

    getCarModelName(id) {
      const index = this.car_models.findIndex(e => e.id === id)
      if (index >= 0) {
        return this.car_models[index].name
      }
    },

    getCarGroups() {
      this.$store.dispatch(`${this.CARS_STORE_MODULE_NAME}/getGroups`)
    },

    getCarTypes() {
      this.$store.dispatch(`${this.CARS_STORE_MODULE_NAME}/getTypes`)
    },

    getCars() {
      return this.$store.dispatch(`${this.CARS_STORE_MODULE_NAME}/getCars`)
    },

    getCarModels() {
      return this.$store.dispatch(`${this.CARS_STORE_MODULE_NAME}/getModels`)
    },

    getBodies() {
      return this.$store.dispatch(`${this.CARS_STORE_MODULE_NAME}/getBodies`)
    },

    getColors() {
      return this.$store.dispatch(`${this.CARS_STORE_MODULE_NAME}/getColors`)
    },

    getFuels() {
      return this.$store.dispatch(`${this.CARS_STORE_MODULE_NAME}/getFuels`)
    },

    getClassificators() {
      this.$store.dispatch(`${this.CARS_STORE_MODULE_NAME}/getClassificators`)
      this.$store.dispatch(`${this.CARS_STORE_MODULE_NAME}/getNewClassificators`)
    },

    getRegions() {
      this.$store.dispatch(`${this.LOCATIONS_STORE_MODULE_NAME}/getRegions`)
    },

    getCounties() {
      this.$store.dispatch(`${this.LOCATIONS_STORE_MODULE_NAME}/getCounties`)
    },

    getStreets() {
      this.$store.dispatch(`${this.LOCATIONS_STORE_MODULE_NAME}/getStreets`)
    },

    changeChanges(event) {
      if (event === '1') {
        this.$bvModal.show('modal-form-changes')
      } else {
        this.form.ChangesNotes = ''
      }
    },

    changeOpinions(event) {
      if (event === '0') {
        this.$bvModal.show('modal-form-opinions')
      } else {
        this.form.OpinionNotes = ''
        this.changeTab('formNextCheckup')
      }
    },

    openClassificators() {
      this.$bvModal.show('modal-form-classificators')
    },

    openOwnerStatusForm() {
      this.$bvModal.show('modal-form-owner-status')
    },

    openAddressForm() {
      this.$bvModal.show('modal-form-address')
      // if (!this.isOriginalInfo) {
      //   this.$bvModal.show('modal-form-address')
      // }
    },

    confirmOwnerStatus() {
      this.form.ownerName = this.form.ownerStatus.Owner
    },

    changeFormRegion(event) {
      this.locationData.county = null
      this.locationData.street = null
      this.locationData.address = null
      this.form.address = null
      const regionId = event.id
      this.$store.commit(`${this.LOCATIONS_STORE_MODULE_NAME}/FILTER_COUNTIES_BY_REGION_ID`, regionId)
      const input = document.querySelector('#modal-form-address .streetSelector input.vs__search')
      if (input) {
        input.addEventListener('keydown', e => {
          if (!(/^[0-9ա-ֆԱ-Ֆև․.\-\/bB\s\b\cC\cX\cA\cV\cZ]+|[\b]+$/.test(e.key))) {
            e.preventDefault()
          }
        })
      }
    },

    changeFormCounty(event) {
      this.locationData.street = null
      this.locationData.address = null
      this.form.address = null
      const countyId = event.id
      this.$store.dispatch(`${this.LOCATIONS_STORE_MODULE_NAME}/getStreets`, `county=${countyId}&status=${true}`)
    },

    changeFormStreet(event) {
      this.locationData.address = ''
      this.form.address = ''
      if (!event.id) {
        let streetName = (typeof event.label !== 'undefined') ? event.label : event
        let s = streetName
        s = s.toLocaleLowerCase()
        s = s.replace(/([^\s:\-])([^\s:\-]*)/g, ($0, $1, $2) => $1.toUpperCase() + $2.toLowerCase())
        streetName = s
        this.$store.dispatch(`${this.LOCATIONS_STORE_MODULE_NAME}/addStreetForCheckup`, {
          name: streetName,
          county: this.locationData.county.id,
        })
          .then(response => {
            this.form.street_id = response.data.item._id
            this.locationData.street = {
              value: this.form.street_id,
              label: streetName,
            }
          })
      } else {
        this.form.street_id = event.id
      }
      this.changeTab('formModalAddress')
    },

    changeLocationAddress() {
      let s = this.locationData.address
      s = s.toLocaleLowerCase()
      s = s.replace(/([^\s:\-])([^\s:\-]*)/g, ($0, $1, $2) => $1.toUpperCase() + $2.toLowerCase())
      this.locationData.address = s
    },

    changeLocationData(data) {
      this.$store.commit(`${this.LOCATIONS_STORE_MODULE_NAME}/FILTER_COUNTIES_BY_REGION_ID`, data.region_id)
      this.locationData = {
        region: {
          label: data.region,
          value: data.region_id,
        },
        county: {
          label: data.county,
          value: data.county_id,
        },
        street: {
          label: data.street,
          value: data.street_id,
        },
        address: data.address,
      }
    },

    confirmAddressForm() {
      this.form.region_id = this.locationData.region.id
      this.form.region = this.locationData.region.label
      this.form.county_id = this.locationData.county.id
      this.form.county = this.locationData.county.label
      this.form.street = (typeof this.locationData.street.label !== 'undefined') ? this.locationData.street.label : this.locationData.street
      this.form.address = this.locationData.address
      this.form.addressName = `${this.form.region}, ${this.form.county}, ${this.form.street}, ${this.form.address}`
      this.changeTab('formPhone')
      this.$bvModal.hide('modal-form-address')
    },

    confirmForm() {
      return (this.add) ? this.checkBeforeSubmit() : this.editCheckup()
    },

    checkBeforeSubmit() {
      this.$refs.checkupValidation.validate()
        .then(success => {
          if (success) {
            this.$vs.loading()
            this.$store.dispatch(`${this.TECH_SERVICE_STORE_MODULE_NAME}/checkBeforeAdd`, {
              CheckNo: this.form.CheckNo,
              CarRegNo: this.form.CarRegNo.toUpperCase(),
            })
              .then(() => {
                this.$vs.loading.close()
                this.addCheckup()
              })
              .catch(error => {
                this.$vs.loading.close()
                this.$swal({
                  title: '',
                  html: error.response.data.message,
                  imageUrl: this.stopImage,
                  imageWidth: 150,
                  imageHeight: 150,
                  showCancelButton: true,
                  confirmButtonText: 'Այո',
                  cancelButtonText: 'Ոչ',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1',
                  },
                  buttonsStyling: false,
                })
                  .then(result => {
                    if (result.value) {
                      this.form.isDuplicate = true
                      this.addCheckup()
                    }
                  })
              })
          }
        })
    },

    getBase64(file) {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        this.attachment = reader.result
      }
      reader.onerror = function (error) {
        this.attachment = undefined
      }
    },

    addCheckup() {
      this.$refs.checkupValidation.validate()
        .then(async success => {
          this.is_form_saving = true
          if (success) {
            if (this.checkMechanicSelected()) {
              this.$vs.loading()
              const payload = JSON.parse(JSON.stringify(this.form))
              payload.id = this.temporary_id
              payload.mechanic = this.activeMechanic.id
              payload.Changes = (this.form.Changes === '1')
              payload.Opinion = (this.form.Opinion === '1')
              payload.CarRegNo = this.form.CarRegNo.toUpperCase()
              payload.VIN = this.form.VIN.toUpperCase()
              payload.ownerStatus.Owner = this.toProperCase(this.form.ownerStatus.Owner)
              payload.documentNumber = this.form.documentNumber.toUpperCase()
              if (!payload.Opinion) {
                payload.CheckNo = ''
              }
              payload.car_id = this.form.car_id.value
              if (this.company && this.company.camera && this.camera) {
                payload.camera = this.camera
              }
              if (!this.$device.isMobile && this.company && this.company.attachment && this.attachmentFile) {
                const file = document.querySelector('#attachment').files[0]
                await this.$store.dispatch(`${this.TECH_SERVICE_STORE_MODULE_NAME}/addAttachment`, {
                  file,
                })
                  .then((response => {
                    payload.attachment = response.file
                  }))
                  .catch(error => {
                    payload.attachment = null
                  })
              }
              // console.log('payload', payload)
              // payload.car_model_id = this.form.car_model_id.value
              this.$store.dispatch(`${this.TECH_SERVICE_STORE_MODULE_NAME}/addCheckup`, payload)
                .then(response => {
                  // this.$socket.emit('OPERATOR_EDITED_CHECKUP', {
                  //   temporary_id: this.temporary_id,
                  //   item: response.data.item
                  // })
                  this.$store.commit(`${this.TECH_SERVICE_STORE_MODULE_NAME}/CHANGE_ADD_STATE`, false)
                  this.$vs.loading.close()
                  if (!this.$device.isMobile) {
                    this.$store.commit(`${this.TECH_SERVICE_STORE_MODULE_NAME}/SET_SELECTED_CHECKUP`, response.data.item)
                    this.$store.commit(`${this.TECH_SERVICE_STORE_MODULE_NAME}/OPEN_DOC`, 'classificator')
                  }

                  this.$emit('updateTodayCount')
                  setTimeout(() => {
                    this.is_form_saving = false
                  }, 5000)
                })
                .catch(error => {
                  setTimeout(() => {
                    this.is_form_saving = false
                  }, 5000)

                  console.log(error)
                  this.$swal({
                    title: '',
                    text: error.response.data.message,
                    icon: 'error',
                    customClass: {
                      confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                  })
                  this.$vs.loading.close()
                  this.$root.$emit('getError', error)
                })
            } else {
              this.is_form_saving = false
              this.$swal({
                title: 'Հսկիչը մուտք չի գործել!',
                text: 'Խնդրում ենք նշել հսկիչին!',
                icon: 'warning',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
              this.$store.dispatch('setTourSteps', this.steps)
                .then(() => {
                  this.$tours.vuexyTour.start()
                })
            }
          }
        })
    },

    editCheckup() {
      this.$refs.checkupValidation.validate()
        .then(success => {
          if (success) {
            this.$vs.loading()
            const payload = JSON.parse(JSON.stringify(this.form))
            payload.car_id = this.form.car_id.value
            // payload.car_model_id = this.form.car_model_id.value
            if (this.activeMechanic) {
              payload.mechanic = this.activeMechanic.id
            }
            payload.Changes = (this.form.Changes === '1')
            payload.Opinion = (this.form.Opinion === '1')
            payload.CarRegNo = this.form.CarRegNo.toUpperCase()
            payload.documentNumber = this.form.documentNumber.toUpperCase()
            payload.VIN = this.form.VIN.toUpperCase()
            payload.ownerStatus.Owner = this.toProperCase(this.form.ownerStatus.Owner)
            this.$store.dispatch(`${this.TECH_SERVICE_STORE_MODULE_NAME}/editCheckup`, payload)
              .then(response => {
                // this.$socket.emit('OPERATOR_EDITED_CHECKUP', {
                //   temporary_id: this.temporary_id,
                //   item: response.data.item
                // })
                this.$store.commit(`${this.TECH_SERVICE_STORE_MODULE_NAME}/CLOSE_EDIT_FORM`)
                this.$vs.loading.close()
                this.$emit('updateTodayCount')
              })
              .catch(error => {
                this.$swal({
                  title: '',
                  text: error.response.data.message,
                  icon: 'error',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
                console.log(error)
                this.$vs.loading.close()
                this.$root.$emit('getError', error)
              })
          }
        })
    },

    editAdminCheckup() {
      this.$refs.checkupValidation.validate()
        .then(success => {
          if (success) {
            this.$vs.loading()
            if (this.activeMechanic) {
              this.form.mechanic = this.activeMechanic.id
            }
            this.form.Changes = (this.form.Changes === '1')
            this.form.Opinion = (this.form.Opinion === '1')
            this.form.CarRegNo = this.form.CarRegNo.toUpperCase()
            this.form.documentNumber = this.form.documentNumber.toUpperCase()
            this.form.VIN = this.form.VIN.toUpperCase()
            this.form.ownerStatus.Owner = this.toProperCase(this.form.ownerStatus.Owner)
            const payload = JSON.parse(JSON.stringify(this.form))
            payload.car_id = this.form.car_id.value
            // payload.car_model_id = this.form.car_model_id.value
            this.$store.dispatch(`${this.TECH_SERVICE_STORE_MODULE_NAME}/editAdminCheckup`, payload)
              .then(response => {
                this.$store.commit(`${this.TECH_SERVICE_STORE_MODULE_NAME}/CLOSE_EDIT_FORM`)
                this.$vs.loading.close()
                this.$store.dispatch('readNotification', {
                  _id: this.askToEdit._id,
                  status: true,
                })
                  .then(() => {
                    if (this.sendNotificationToOperator) {
                      const payload = {
                        notificationType: 'NOTIFICATION',
                        title: 'Խմբագրման հայտի հաստատում',
                        to: this.askToEdit.from._id,
                        message: `${this.form.CarRegNo}-ի փոփոխությունը կատարված է։`,
                        data: {
                          id: this.askToEdit.data.id,
                          collection: 'checkups',
                        },
                      }
                      this.$store.dispatch('addNotification', payload)
                        .then(response => {
                          response.data.map(notification => {
                            // this.$socket.emit('SEND_NOTIFICATION', notification)
                          })
                        })
                    }
                    this.$store.commit(`${this.TECH_SERVICE_STORE_MODULE_NAME}/SET_ASK_TO_EDIT`, null)
                    this.$swal({
                      title: '',
                      text: 'Տեխզննումը խմբագրվել է!',
                      icon: 'success',
                      customClass: {
                        confirmButton: 'btn btn-primary',
                      },
                      buttonsStyling: false,
                    })
                  })
              })
              .catch(error => {
                this.$vs.loading.close()
                this.$root.$emit('getError', error)
              })
          }
        })
    },

    checkMechanicSelected() {
      return (this.activeMechanic !== null)
    },

    changeCar(event) {
      const carIndex = this.cars.findIndex(c => c.value === event)
      if (carIndex >= 0) {
        this.form.car = this.cars[carIndex].label
        this.form.car_id = {
          value: event,
          label: this.form.car,
        }
      }
      this.$store.dispatch(`${this.CARS_STORE_MODULE_NAME}/getModels`, `car=${event}`)
    },

    changeCarSelect(event) {
      this.rediusCheck = false
      // this.form.car_model_id = null
      // this.form.car_model = ''
      if (event) {
        if (event.value) {
          this.changeCar(event.value)
        }
      }
    },

    changeCarModel(event) {
      const carIndex = this.car_models.findIndex(m => m.value === event)
      if (carIndex >= 0) {
        this.form.car_model = this.car_models[carIndex].label
      }
    },

    changeCarModelSelect(event) {
      if (typeof event !== 'object' && event.match(/^[0-9a-fA-F]{24}$/)) {
        this.changeCarModel(event)
      } else if (event) {
        if (event.value) {
          this.changeCarModel(event.value)
        } else if (this.form.car_id) {
          const modelTitle = (typeof event.label !== 'undefined') ? event.label : event
          const payload = {
            name: modelTitle,
            car: this.form.car_id.value,
          }
          this.$store.dispatch(`${this.CARS_STORE_MODULE_NAME}/addModelForCheckup`, payload)
            .then(response => {
              const item = {
                value: response.data.item._id,
                label: response.data.item.name,
              }
              this.form.car_model_id = item
              this.form.car_model = item.label
            })
        } else {
          this.$swal({
            title: '',
            text: 'Ընտրեք մակնիշը!',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        }
      }
    },

    changeBody(event) {
      const bodyIndex = this.bodies.findIndex(b => b.value === event)
      if (bodyIndex >= 0) {
        this.form.body = this.bodies[bodyIndex].label
      }
    },

    changeFuel(event) {
      this.ekoStatus = true
      const fuelIndex = this.fuels.findIndex(f => f.value === event)
      if (fuelIndex >= 0) {
        this.form.fuel = this.fuels[fuelIndex].label
        if (this.form.fuel === 'Հիբրիդային' || this.form.fuel === 'Էլեկտրական') {
          this.ekoStatus = false
          this.form.EkoSum = null
        }
      }
    },

    changeColor(event) {
      const colorIndex = this.colors.findIndex(c => c.value === event)
      if (colorIndex >= 0) {
        this.form.color = this.colors[colorIndex].label
      }
    },

    cancelAdding() {
      this.$swal({
        title: 'Չեղարկե՞լ գրանցումը',
        text: '',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Այո',
        cancelButtonText: 'Ոչ',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            if (this.add) {
              const data = {
                id: this.temporary_id,
                user: this.user,
                company: this.user.company,
              }
              // this.$socket.emit('CHECKUP_CANCELED', data)
            }
            this.$store.commit(`${this.TECH_SERVICE_STORE_MODULE_NAME}/CHANGE_ADD_STATE`, false)
            this.$store.commit(`${this.TECH_SERVICE_STORE_MODULE_NAME}/CLOSE_EDIT_FORM`)
            this.$store.commit(`${this.TECH_SERVICE_STORE_MODULE_NAME}/CLOSE_COPY_FORM`)
          }
        })
    },

    changeOwnerStatus() {
      if (this.form.ownerStatus.Status !== 1) {
        this.form.ownerStatus.Notes = ''
      }
    },

    cancelOwnerStatusChanges() {
      if (this.form.ownerStatus.Status === 1 && this.form.ownerStatus.Notes === '') {
        this.form.ownerStatus.Status = 0
      }
    },

    clearPhoneMask() {
      this.$swal({
        title: '',
        text: 'Հաստատում ե՞ք, որ ցանկանում եք մուտքագրել այլ հեռախոսահամար:',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Այո',
        cancelButtonText: 'Ոչ',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            this.phoneMasked = false
          }
        })
    },

    clearDocumentNumberMask() {
      if (this.isOriginalInfo) {
        // return false
      }
      this.$swal({
        title: '',
        text: 'Հաստատե՞լ գործողությունը:',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Այո',
        cancelButtonText: 'Ոչ',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            this.documentNumberMasked = false
          }
        })
    },

    disableCheckNoRules() {
      this.$swal({
        title: '',
        text: 'Հաստատում ե՞ք, որ ցանկանում եք պասիվացնել կտրոնի համարը:',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Այո',
        cancelButtonText: 'Ոչ',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            this.checkNoIsRequired = false
          }
        })
    },

    clearCarRegNoMask() {
      this.carRegNoMasked = false
      this.$bvModal.show('modal-new-car-reg-no')
    },

    cancelCarRegNoFormating() {
      this.form.YellowCarRegNo = ''
      this.carRegNoMasked = true
      this.$bvModal.hide('modal-new-car-reg-no')
    },

    confirmCarRegNoModal() {
      if (this.form.DefaultCarRegNo) {
        this.form.CarRegNo = this.form.DefaultCarRegNo
      }
      this.$bvModal.hide('modal-new-car-reg-no')
    },

    clearVinMask() {
      this.$swal({
        title: '',
        text: 'Հաստատե՞լ գործողությունը',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Այո',
        cancelButtonText: 'Ոչ',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            this.VINMasked = false
          }
        })
    },

    changeCarType(id) {
      const index = this.car_types.findIndex(e => e.id === id)
      if (index >= 0) {
        this.form.car_type = this.car_types[index].name
        this.form.Sum = this.car_types[index].amount
        const group_index = this.car_groups.findIndex(g => g.id === this.car_types[index].group)
        if (group_index >= 0) {
          this.form.car_group = this.car_groups[group_index].name
        }
        this.changeYear()
      }
      // this.changeTab('formCarRegNo')
      this.calculateEkoSum()
    },

    changeCarRegNo() {
      const data = {
        id: this.temporary_id,
        createdBy: this.user,
        company: this.user.company,
        CarRegNo: this.form.CarRegNo,
      }
      // this.$socket.emit('CHANGE_CAR_REG_NO', data)
    },

    disableSymbol(e) {
      if (!(/^[a-zA-Z0-9]*$/.test(e.key))) {
        e.preventDefault()
      }
    },

    ucWordName() {
      let s = this.form.ownerStatus.Owner
      s = s.toLocaleLowerCase()
      s = s.replace(/([^\s:\-])([^\s:\-]*)/g, ($0, $1, $2) => $1.toUpperCase() + $2.toLowerCase())
      this.form.ownerStatus.Owner = s
    },

    toProperCase(s) {
      s = s.toLocaleLowerCase()
      return s.replace(/([^\s:\-])([^\s:\-]*)/g, ($0, $1, $2) => $1.toUpperCase() + $2.toLowerCase())
    },

    onlyAm(e) {
      if (!(/^[ա-ֆԱ-ՖևbB\t\s\cC\cX\cA\cV\cZ]+|[\b]+$/.test(e.key))) {
        e.preventDefault()
      }
    },

    onlyAmNumber(e) {
      if (!(/^[0-9ա-ֆԱ-Ֆև․.\-\/bB\t\s\b\cC\cX\cA\cV\cZ]+|[\b]+$/.test(e.key))) {
        e.preventDefault()
      }
    },

    onlyEnNumber(e) {
      if (!(/^[0-9a-zA-ZՈո/bB\b\cC\cX\cA\cV\cZ]+|[\b]+$/.test(e.key))) {
        e.preventDefault()
      }
    },

    onlyNumber(e) {
      if (!(/^[0-9/bB\b\cC\cX\cA\cV\cZ]+|[\b]+$/.test(e.key))) {
        e.preventDefault()
      }
    },

    changeTab(element) {
      const parent = document.getElementById(element)
      if (parent) {
        parent.focus()
        parent.click()
      }
      const child = document.querySelector(`#${element} input`)
      if (child) {
        child.focus()
        child.click()
      }
    },
    changeYear() {
      this.nextCheckupDisabled = false
      const currentYear = new Date().getFullYear()
      const year = parseInt(this.form.MadeAt, 10)
      const difference = currentYear - year
      if (difference < 0) {
        this.form.NextCheckup = 2
      }
      if (difference >= 3 && difference < 10) {
        this.form.NextCheckup = 2
      } else if (difference >= 10) {
        this.form.NextCheckup = 1
      }

      if (this.form.car_type === 'Միկրոավտոբուս' || this.form.car_type === 'Ավտոբուս') {
        if (difference >= 3 && difference < 10) {
          // this.nextCheckupDisabled = true
          this.form.NextCheckup = 1
        } else if (difference >= 10) {
          // this.nextCheckupDisabled = true
          this.form.NextCheckup = '0'
        }
      }
    },

    calculateEkoSum() {
      // return false;
      let number = 1
      if (this.form.NextCheckup === 2) {
        number = 2
      }
      this.ekoStatus = true
      this.form.EkoSum = null
      if (this.form.car_type === 'Թեթև մարդատար' || this.form.car_type === 'Միկրոավտոբուս' || this.form.car_type === 'Ավտոբուս') {
        const year = parseInt(this.form.MadeAt, 10)
        const power = this.form.engine



        const koes = {
          0: {
            0: {
              min: 0,
              max: 50,
              koef: 2.5,
            },
            1: {
              min: 51,
              max: 80,
              koef: 5,
            },
            2: {
              min: 81,
              max: 100,
              koef: 7.5,
            },
            3: {
              min: 101,
              max: 150,
              koef: 10,
            },
            4: {
              min: 151,
              max: 200,
              koef: 12.5,
            },
            5: {
              min: 201,
              max: 250,
              koef: 15,
            },
            6: {
              min: 251,
              max: 300,
              koef: 17.5,
            },
            7: {
              min: 301,
              max: 100000,
              koef: 25,
            },
          },
          1: {
            0: {
              min: 0,
              max: 50,
              koef: 2.5,
            },
            1: {
              min: 51,
              max: 80,
              koef: 5,
            },
            2: {
              min: 81,
              max: 100,
              koef: 7.5,
            },
            3: {
              min: 101,
              max: 150,
              koef: 10,
            },
            4: {
              min: 151,
              max: 200,
              koef: 12.5,
            },
            5: {
              min: 201,
              max: 250,
              koef: 15,
            },
            6: {
              min: 251,
              max: 300,
              koef: 17.5,
            },
            7: {
              min: 301,
              max: 100000,
              koef: 25,
            },
          },
          2: {
            0: {
              min: 0,
              max: 50,
              koef: 2.5,
            },
            1: {
              min: 51,
              max: 80,
              koef: 5,
            },
            2: {
              min: 81,
              max: 100,
              koef: 7.5,
            },
            3: {
              min: 101,
              max: 150,
              koef: 10,
            },
            4: {
              min: 151,
              max: 200,
              koef: 12.5,
            },
            5: {
              min: 201,
              max: 250,
              koef: 15,
            },
            6: {
              min: 251,
              max: 300,
              koef: 17.5,
            },
            7: {
              min: 301,
              max: 100000,
              koef: 25,
            },
          },
          3: {
            0: {
              min: 0,
              max: 50,
              koef: 3,
            },
            1: {
              min: 51,
              max: 80,
              koef: 6,
            },
            2: {
              min: 81,
              max: 100,
              koef: 9,
            },
            3: {
              min: 101,
              max: 150,
              koef: 12,
            },
            4: {
              min: 151,
              max: 200,
              koef: 15,
            },
            5: {
              min: 201,
              max: 250,
              koef: 18,
            },
            6: {
              min: 251,
              max: 300,
              koef: 21,
            },
            7: {
              min: 301,
              max: 100000,
              koef: 30,
            },
          },
          4: {
            0: {
              min: 0,
              max: 50,
              koef: 3.5,
            },
            1: {
              min: 51,
              max: 80,
              koef: 7,
            },
            2: {
              min: 81,
              max: 100,
              koef: 10.5,
            },
            3: {
              min: 101,
              max: 150,
              koef: 14,
            },
            4: {
              min: 151,
              max: 200,
              koef: 17.5,
            },
            5: {
              min: 201,
              max: 250,
              koef: 21,
            },
            6: {
              min: 251,
              max: 300,
              koef: 24.5,
            },
            7: {
              min: 301,
              max: 100000,
              koef: 35,
            },
          },
          5: {
            0: {
              min: 0,
              max: 50,
              koef: 4,
            },
            1: {
              min: 51,
              max: 80,
              koef: 8,
            },
            2: {
              min: 81,
              max: 100,
              koef: 12,
            },
            3: {
              min: 101,
              max: 150,
              koef: 16,
            },
            4: {
              min: 151,
              max: 200,
              koef: 20,
            },
            5: {
              min: 201,
              max: 250,
              koef: 24,
            },
            6: {
              min: 251,
              max: 300,
              koef: 28,
            },
            7: {
              min: 301,
              max: 100000,
              koef: 40,
            },
          },
          6: {
            0: {
              min: 0,
              max: 50,
              koef: 4.5,
            },
            1: {
              min: 51,
              max: 80,
              koef: 9,
            },
            2: {
              min: 81,
              max: 100,
              koef: 13.5,
            },
            3: {
              min: 101,
              max: 150,
              koef: 18,
            },
            4: {
              min: 151,
              max: 200,
              koef: 22.5,
            },
            5: {
              min: 201,
              max: 250,
              koef: 27,
            },
            6: {
              min: 251,
              max: 300,
              koef: 31.5,
            },
            7: {
              min: 301,
              max: 100000,
              koef: 45,
            },
          },
          7: {
            0: {
              min: 0,
              max: 50,
              koef: 5,
            },
            1: {
              min: 51,
              max: 80,
              koef: 10,
            },
            2: {
              min: 81,
              max: 100,
              koef: 15,
            },
            3: {
              min: 101,
              max: 150,
              koef: 20,
            },
            4: {
              min: 151,
              max: 200,
              koef: 25,
            },
            5: {
              min: 201,
              max: 250,
              koef: 30,
            },
            6: {
              min: 251,
              max: 300,
              koef: 35,
            },
            7: {
              min: 301,
              max: 100000,
              koef: 50,
            },
          },
        }
        const currentYear = new Date().getFullYear()
        const year_diff = currentYear - year + 1
        let ranges
        if ((year_diff <= 8)) {
          ranges = koes[year_diff - 1]
        } else {
          ranges = koes[7]
        }
        let koef
        for (const range in ranges) {
          if (power >= ranges[range].min && power <= ranges[range].max) {
            koef = ranges[range].koef
            break
          }
        }
        if (typeof koef === 'undefined') {
          const rangesData = Object.values(ranges)
          if (power > rangesData[rangesData.length - 1].max) {
            koef = rangesData[rangesData.length - 1].koef
          }
        }
        let ekoSum = koef * power
        let ekoSumNew = 0
        if (number == 2) {
          const currentYearNew = new Date().getFullYear() + 1
          const year_diff_new = currentYearNew - year + 1
          let rangesNew
          if ((year_diff_new <= 8)) {
            rangesNew = koes[year_diff_new - 1]
          } else {
            rangesNew = koes[7]
          }
          let koefNew
          for (const range in rangesNew) {
            if (power >= rangesNew[range].min && power <= rangesNew[range].max) {
              koefNew = rangesNew[range].koef
              break
            }
          }
          if (typeof koefNew === 'undefined') {
            const rangesDataNew = Object.values(rangesNew)
            if (power > rangesDataNew[rangesDataNew.length - 1].max) {
              koefNew = rangesDataNew[rangesDataNew.length - 1].koef
            }
          }
          ekoSumNew = koefNew * power
        }
        this.form.EkoSum = ekoSum + ekoSumNew
      } else if (this.form.car_type == 'Մինչև 3.5 տ. լրիվ զանգվածով') {
        this.form.EkoSum = 5000 * number
      } else if (this.form.car_type == '3.5-12տ. լրիվ զանգվածով') {
        this.form.EkoSum = 10000 * number
      } else if (this.form.car_type == '12տ. -ից ավել լրիվ զանգվածով') {
        this.form.EkoSum = 15000 * number
      } else if (this.form.car_type == 'Կիսակցորդներ' || this.form.car_type == 'Կցորդներ') {
        this.form.EkoSum = null
        this.ekoStatus = false
      }
    },

  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

#tech-service-form {

  .v-select {
    .vs__dropdown-toggle {
      height: 3.3rem;
      min-height: 3.3rem;
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
  }

  padding: 1rem;

  .form {
    margin-top: 1rem;
    position: relative;
    border: 1px solid #eee;
    border-radius: 6px;
    padding: 10px 15px;

    .form-footer {
      display: flex;
      border-top: 1px solid #d4d4d4;
      padding: 10px 0;
      width: 100%;
      justify-content: flex-end;
    }

    .closeAddForm {
      position: absolute;
      right: 19px;
      font-weight: bold;
      color: #f44336;
      font-size: 1.7rem;
      cursor: pointer;
      background: #fff;
    }

    .custom-select, .form-control {
      height: 3.3rem;
      min-height: 3.3rem;
    }

    .backspaceIcon {
      font-size: 21px;
    }
  }

  .extra_header {
    display: flex;
    width: 100%;
    justify-content: start;
    margin-bottom: 1rem;

    .title {
      font-size: 1rem;
      font-weight: bold;
      margin: 3px 0;
      cursor: pointer;
      flex: 0 0 auto;
    }

    .borderDiv {
      border-top: 1px solid #ccc;
      margin: 10px 5px;
      box-shadow: 0 0px 8px #d4d4d4;
      width: auto;
      flex: 1 1 auto;
    }
  }

  .input-group-column {
    width: 50%;
    float: left;

    &.input-group-70 {
      width: 70%;

      .form-control, {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right: 0;
      }
    }

    &.input-group-30 {
      width: 30%;

      .input-group-text {

        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }

    }
  }

  .select-with-icon-right {
    position: relative;

    .rightIcon {
      position: absolute;
      top: 11px;
      right: 8px;
      z-index: 9;
      cursor: pointer;
      color: #656565;

      &.faRightIcon {
        font-size: 1.5rem;
      }
    }

    .maz-select__toggle__arrow {
      display: none;
    }
  }

  .input-group-text {
    svg {
      color: #656565;
      font-size: 1.3rem;
    }
  }
}

.classificator-checkbox {
  width: 25%;
  float: left;
}

.input-group-text {
  svg {
    cursor: pointer;
  }
}

.form-control:disabled, .form-control[readonly] {
  background-color: #ffffff;
}

.form-control {
  &.is-invalid {
    border-color: #ff4500 !important;
  }
}

.swal2-html-container {
  color: #000;
  font-weight: bold;
  line-height: 27px;
}

.checkFlex {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  .widthis90 {
    width: 90%
  }

  svg {
    font-size: 17px;
    position: relative;
    top: -9px;
    left: -10px;
    color: #f44336;

    &.active {
      color: green;
    }
  }
}
.swal2-content {
  white-space: pre-line;
}
.alertNotification .swal2-content {
  text-align: left;
}
.attachment-file {
  display: flex;
  justify-content: center;
  align-items: center;
  border-style: dashed;
  border-width: 2px;
  input {
    opacity: 0;
  }
  .maz-input__icon {
    right: initial!important;
  }
}
.photo-uploads {
  display: flex;
  justify-content: space-around;
  margin-bottom: 10px;
  color: var(--primary);
  font-size: 25px;
  .photo-upload {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    width: 80px;
    border: 1px dashed #ccc;
    border-radius: 5px;
  }
}
#modal-camera {
  .modal-content {
    min-height: 600px;
  }
  .web-camera-container {
    margin-top: 2rem;
    margin-bottom: 2rem;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
    min-height: 415px;

    .camera-button {
      margin-bottom: 2rem;
    }

    .camera-box {
      .camera-shutter {
        opacity: 0;
        width: 666px;
        height: 500px;
        background-color: #fff;
        position: absolute;

        &.flash {
          opacity: 1;
        }
      }
    }

    .camera-shoot {
      margin: 1rem 0;

      button {
        height: 60px;
        width: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;

        img {
          height: 35px;
          object-fit: cover;
        }
      }
    }

    .camera-loading {
      overflow: hidden;
      height: 100%;
      position: absolute;
      width: 100%;
      min-height: 150px;
      margin: 3rem 0 0 -1.2rem;

      ul {
        height: 100%;
        position: absolute;
        width: 100%;
        z-index: 999999;
        margin: 0;
      }

      .loader-circle {
        display: block;
        height: 14px;
        margin: 0 auto;
        top: 50%;
        left: 100%;
        transform: translateY(-50%);
        transform: translateX(-50%);
        position: absolute;
        width: 100%;
        padding: 0;

        li {
          display: block;
          float: left;
          width: 10px;
          height: 10px;
          line-height: 10px;
          padding: 0;
          position: relative;
          margin: 0 0 0 4px;
          background: #999;
          animation: preload 1s infinite;
          top: -50%;
          border-radius: 100%;

          &:nth-child(2) {
            animation-delay: .2s;
          }

          &:nth-child(3) {
            animation-delay: .4s;
          }
        }
      }
    }

    @keyframes preload {
      0% {
        opacity: 1
      }
      50% {
        opacity: .4
      }
      100% {
        opacity: 1
      }
    }
  }
}
</style>
